import axios from "./axiosInstance";

const countryList = async () => {
  let res = await axios({
    method: "POST",
    url: "location/countries",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const provinceList = async () => {
  let res = await axios({
    method: "POST",
    url: "location/cities",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const provinceListWithCountry = async (id = '') => {
  let res = await axios({
    method: "POST",
    url: `location/Cities?CountryId=${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const countytList = async (item = '') => {
  let res = await axios({
    method: "POST",
    url: `location/counties?cityId=${item}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const districtstList = async (item = '') => {
  let res = await axios({
    method: "POST",
    url: `location/districts?countyId=${item}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getOfficeList = async (filter) => {
  let res = await axios({
    method: "POST",
    url: "brand/offices",
    data: filter,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getOfficeLocationsList = async () => {
  let res = await axios({
    method: "POST",
    url: "location/EnteredOfficeCities",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getOfficeDetail = async (id) => {
  let res = await axios({
    method: "POST",
    url: `office/detail/${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const teamMemberList = async (filter) => {
  let res = await axios({
    method: "POST",
    url: "brand/users",
    data: filter,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const teamMemberDetail = async (id) => {
  let res = await axios({
    method: "POST",
    url: `user/detail/${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const teamMemberComments = async (id) => {
  let res = await axios({
    method: "POST",
    url: `customercomment/getlist`,
    data:{Id:id}
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const userRoleList = async () => {
  let res = await axios({
    method: "POST",
    url: `lookup/roles`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getUserTitleList = async () => {
  let res = await axios({
    method: "POST",
    url: `brand/UserTitleList`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getPageContents = async () => {
  let res = await axios({
    method: "POST",
    url: `setting/GetAllWidgets`,
    // url: `Setting/GetPageLink`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getContentPages = async () => {
  let res = await axios({
    method: "POST",
    url: `Setting/GetAllPages`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res.data;
};

const getAllPagesbyType = async () => {
  let res = await axios({
    method: "POST",
    url: `Setting/GetAllPagesbyType`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
    
  return res?.data;
};

const getAllCurrencies = async () => {
  let res = await axios({
    method: "POST",
    url: `lookup/GetCurrencies `,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getAllSettings = async () => {
  let res = await axios({
    method: "POST",
    url: `setting/getsetting`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const bindDropdowns = async () => {
  let res = await axios({
    method: "POST",
    url: `appeal/binddropdowns`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const generalServices = {
  countryList,
  provinceList,
  provinceListWithCountry,
  countytList,
  districtstList,
  getOfficeList,
  getOfficeDetail,
  getOfficeLocationsList,
  teamMemberList,
  teamMemberDetail,
  teamMemberComments,
  getPageContents,
  getContentPages,
  getAllPagesbyType,
  getAllCurrencies,
  getAllSettings,
  userRoleList,
  getUserTitleList,
  bindDropdowns,
};

export default generalServices;
