import React, { useEffect } from 'react';
import Navbar from './global-components/Navbar-v2';
import OfficeDetails from './section-components/OfficeDetails';
import CallToAction from './section-components/CallToAction';
import Footer from './global-components/Footer';
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const Office_Details = () => {
    
 const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(''))
    dispatch(generalActions.setTeamFilters(''))
  }, [])

    return <div>
        <Navbar />
        <OfficeDetails />
        <CallToAction />
        <Footer />
    </div>
}

export default Office_Details

