import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";
import { persistStore } from "redux-persist";
import rootReducer from "./reducers/root";



const middleware = [thunk];


if(process.env.NODE_ENV === 'development'){
  middleware.push(logger);
}

export const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export const persistor = persistStore(store);


