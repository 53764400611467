import { generalConstants } from "../constants/general.constants";
import generalServices from "../../services/general.service";
import advertServices from "../../services/advert.service";
import { history } from "../../helpers/history";
import { localeOptions } from "../../helpers/defaultValues";

const generalActions = {
  getProvinceList,
  getTeamMemberList,
  getPageContents,
  getContentPages,
  getShopFilters,
  getCountryList,
  getPageByType,
  genCompanySettings,
  setShopFilters,
  setOfficeFilters,
  setTeamFilters,
  setLang
};

function getCountryList() {
  return (dispatch) => {
    generalServices.countryList().then(
      (res) => {
        let listArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        dispatch(countries(listArr));
      },
      (error) => {
        console.log("GET_COUNTRY_LIST_ERR");
      }
    );
  };

  function countries(countryList) {
    return { type: generalConstants.GET_COUNTRY, countryList };
  }
}

function getProvinceList() {
  return (dispatch) => {
    generalServices.provinceList().then(
      (res) => {
        let listArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        dispatch(provinces(listArr));
      },
      (error) => {
        console.log("GET_PROVINCE_LIST_ERR");
      }
    );
  };

  function provinces(provinceList) {
    return { type: generalConstants.GET_PROVINCE, provinceList };
  }
}

function getTeamMemberList(actPage) {
    console.log('GELEN FİLTRE BU', actPage)
  return (dispatch) => {
    generalServices.teamMemberList(actPage).then(
      (res) => {
        dispatch(teamMembers(res));
      },
      (error) => {
        console.log("GET_TEAM_MEMBER_LIST_ERR");
      }
    );
  };

  function teamMembers(teamMemberList) {
    return { type: generalConstants.GET_TEAM_MEMBERS, teamMemberList };
  }
}

function getPageContents() {
  return (dispatch) => {
    generalServices.getPageContents().then(
      (res) => {
        dispatch(pageContents(res?.Result));
      },
      (error) => {
        console.log("GET_PAGE_CONTENTS_ERR");
      }
    );
  };

  function pageContents(pageContents) {
    return { type: generalConstants.GET_CONTENTS, pageContents };
  }
}

function getContentPages() {
  return (dispatch) => {
    generalServices.getContentPages().then(
      (res) => {
        dispatch(contentPages(res?.Result));
      },
      (error) => {
        console.log("GET_CONTENT_PAGES_ERR");
      }
    );
  };

  function contentPages(contentPages) {
    return { type: generalConstants.GET_CONTENT_PAGES, contentPages };
  }
}

function getPageByType() {
  return (dispatch) => {
    generalServices.getAllPagesbyType().then(
      (res) => {
        dispatch(allPagesbyType(res?.Result));
      },
      (error) => {
        console.log("GET_ALL_PAGES_BY_TYPES_ERR");
      }
    );
  };

  function allPagesbyType(allPagesByType) {
    return { type: generalConstants.GET_PAGE_TYPES, allPagesByType };
  }
}

function genCompanySettings() {
  return (dispatch) => {
    generalServices.getAllSettings().then(
      (res) => {
        dispatch(compSettingsInfo(res?.Result));
      },
      (error) => {
        console.log("GET_COMPANY_SETTINGS_ERR");
      }
    );
  };

  function compSettingsInfo(settings) {
    return { type: generalConstants.GET_COMP_SETT, settings };
  }
}

function getShopFilters() {
  return (dispatch) => {
    advertServices.binddropdowns().then(
      (res) => {
        dispatch(getShopFilters(res));
      },
      (error) => {
        console.log("GET_SHOP_FILTER_ERR");
      }
    );
  };

  function getShopFilters(filters) {
    return { type: generalConstants.GET_FILTERS, filters };
  }
}

function setShopFilters(filterData) {
  return (dispatch) => {
    dispatch(setShopFilters(filterData));
  };

  function setShopFilters(filters) {
    return { type: generalConstants.SET_FILTERS, filters };
  }
}


function setOfficeFilters(filterData) {
  return (dispatch) => {
    dispatch(setOfficeFilters(filterData));
  };

  function setOfficeFilters(filters) {
    return { type: generalConstants.SET_OFFICE_FILTERS, filters };
  }
}


function setTeamFilters(filterData) {
  return (dispatch) => {
    dispatch(setTeamFilters(filterData));
  };

  function setTeamFilters(filters) {
    return { type: generalConstants.SET_TEAM_FILTERS, filters };
  }
}

function setLang(filterData) {
  return (dispatch) => {
    let selLangObj = localeOptions.find((i) => (i.id === filterData));
    dispatch(setLangFilter(selLangObj.value));
  };

  function setLangFilter(filters) {
    return { type: generalConstants.SET_LANG, filters };
  }
}

export default generalActions;
