import React, { useEffect } from "react";
import Navbar from "./global-components/Navbar-v2";
import PageHeader from "./global-components/Page_header";
import SellHomeSlider from "./shop-components/SellHomeSlider";
import SellHomeDetails from "./shop-components/SellHomeDetails";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const Sell_Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Ev Sat" customclass="mb-0" /> */}
      <SellHomeDetails className="mt-20" />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Sell_Home;
