import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Navbar from "./global-components/Navbar-v2";
import PageHeader from "./global-components/Page_header";
import ProductSlider from "./shop-components/ProductSlider";
import ProductDetails from "./shop-components/ShopDetails";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import advertServices from "../services/advert.service";
import { useDispatch } from "react-redux";
  import generalActions from "../redux/actions/general.actions";
  
const Product_Details = () => {
  const params = useParams();
  const [detailInfoRes, setDetailInfoRes] = useState({});

  useEffect(() => {
    advertServices.getAdvertDetails(params?.id).then((res) => {
      res && setDetailInfoRes(res?.Result);
    });
  }, []);

  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);

  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="İlan Detay" customclass="mb-0" /> */}
      {/* <ProductSlider detailInfo={detailInfoRes} /> */}
      <ProductDetails detailInfo={detailInfoRes} />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Product_Details;
