import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import advertServices from "../../services/advert.service";
import Slider from "react-slick";
import Pagination from "../global-components/Pagination";
import { useTranslation } from "react-i18next";

const ProductTeamSlider = ({ params }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumVal, setPageNumVal] = useState("");
  const [pageNumLimit, setPageNumLimit] = useState(9);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  const [advertList, setAdvertList] = useState([]);
  useEffect(() => {
    params &&
      advertServices
        .advertList({
          OfficeId: params.id,
          PageIndex: currentpage,
          PageSize: pageNumLimit,
        })
        .then((res) => {
          res && setAdvertList(res?.Result.Items);
        });
  }, [currentpage]);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-10">
        <div className="container-fluid">
          {advertList.length > 0 && (
            <div className="row">
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_list"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      {advertList?.map((i, key) => {
                        return (
                          <div key={key} className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                              <div className="product-img go-top">
                                <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                  <img
                                    style={{ height: "207px" }}
                                    src={
                                      i.OriginalImage
                                        ? i.OriginalImage
                                        : publicUrl +
                                          "assets/img/product-3/1.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                              <div className="product-info">
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badg">
                                      {i.EstateStatusType
                                        ? i.EstateStatusType
                                        : "-"}
                                    </li>
                                  </ul>
                                </div>
                                <h2 className="product-title go-top">
                                  <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                    {i.Title ? i.Title : "-"}
                                  </Link>
                                </h2>
                                <div className="product-img-location go-top">
                                  <ul>
                                    <li>
                                      <i className="flaticon-pin" />{" "}
                                      {i.District ? i.District : ""}{" "}
                                      {i.County ? i.County : ""},{" "}
                                      {i.City ? i.City : ""}
                                    </li>
                                  </ul>
                                </div>
                                {i.AdType === "Konut" && (
                                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                    <li>
                                     {t('odasayisi')}
                                      <span>
                                        {" "}
                                        {i.RoomCount ? i.RoomCount : "-"}{" "}
                                      </span>
                                    </li>
                                    <li>
                                      {t('brut')}
                                      <span>
                                        {" "}
                                        {i.UsageAreaGross
                                          ? i.UsageAreaGross
                                          : "-"}{" "}
                                        m²{" "}
                                      </span>
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <div className="product-info-bottom">
                                <div className="product-price">
                                  <span>
                                    {i.FormattedPrice ? i.FormattedPrice : "-"}₺
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                paginate={paginate}
                increasePageNum={increasePageNum}
                decreasePageNum={decreasePageNum}
                activepage={currentpage}
                pageCount={totalPageCount}
                maxPageNum={maxPageNumLimit}
                minPageNum={minPageNumLimit}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTeamSlider;
