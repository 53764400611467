import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import generalActions from "../../redux/actions/general.actions";
import generalServices from "../../services/general.service";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const OffiecFilter = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer.provinceList
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const [countyList, setCountyList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [officeName, setOfficeName] = useState("");
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      minWidth: "100%",
      width: "max-content",
    }),
  };


  const getCountyList = (id) => {
    generalServices.countytList(id).then((res) => {
      let distrOpt = res?.map((i) => ({ label: i.Name, value: i.Id }));
      setCountyList(distrOpt);
    });
  };

  const handleClick = () => {
    const officeFilter = {
      OfficeName: officeName ? officeName : "",
      CityId: selectedProvince ? selectedProvince.value : "",
      CountyId: selectedCounty ? selectedCounty.value : "",
      Sorting: 1,
    };
    dispatch(generalActions.setOfficeFilters(officeFilter));
    navigate("/ofis-bul");
  };

  return (
    <div className="col-lg-12 p-0">
      <div className="ltn__location-search mb-20 ">
        <form action="#" className="clearfix">
          <div className="row align-items-center">
            <div className="col-md-2 location-search-header p-0">
              <h3>{t("ofisbul")}</h3>
            </div>
            <div className=" col-md-3 p-0" style={{ marginRight: "2px" }}>
              <input
                className="form-control"
                placeholder="Ofis adı"
                onChange={(e) => setOfficeName(e.target.value)}
                style={{ height: "45px" }}
              />
            </div>
            <div className=" col-md-3 p-0" style={{ marginRight: "2px" }}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                placeholder={t("il")}
                name="province"
                options={
                  fetchProvinceList &&
                  Object.keys(fetchProvinceList).length > 0 &&
                  fetchProvinceList
                }
                value={selectedProvince}
                styles={customStyles}
                onChange={(e) => {
                  setSelectedProvince(e);
                  setSelectedCounty("");
                  if (e) {
                    getCountyList(e.value);
                  } else {
                    setCountyList([]);
                  }
                }}
              />
            </div>
            <div className=" col-md-3 p-0">
              <Select
                className="basic-single"
                classNamePrefix="select"
                isClearable={true}
                isSearchable={true}
                placeholder={t("ilçe")}
                name="county"
                options={countyList}
                value={selectedCounty}
                styles={customStyles}
                onChange={(e) => setSelectedCounty(e)}
              />
            </div>
            <div className="col-md-1 p-0">
              <button
                onClick={() => handleClick()}
                className="btn2 btn-effect-3 btn-white"
                style={{ borderRadius: "10px" }}
                type="button"
              >
                {t("ara")}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OffiecFilter;
