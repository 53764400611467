import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import generalServices from "../../services/general.service";
import generalActions from "../../redux/actions/general.actions";
import TeamFilter2 from "../section-components/TeamFilter2";
import ReCAPTCHA from "react-google-recaptcha";
import ContactForm4 from "../section-components/ContactForm4";
import { useTranslation } from "react-i18next";

const SellHomeDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer.provinceList
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const [provinceList, setProvinceList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [content, setContent] = useState("");
  const [contentImg, setContentImg] = useState("");
  const [kvkkText, setKvkkText] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [officeName, setOfficeName] = useState("");
  const customStyles = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      minWidth: "100%",
      width: "max-content",
    }),
  };

  useEffect(() => {
    if (fetchPageContents && fetchPageContents.length > 0) {
      const tempContent = fetchPageContents.find(
        (i) => i.WidgetKey === "333333333333"
      );
      const imgContent = fetchPageContents.find(
        (i) => i.WidgetKey === "112233"
      );
      const kvkkContent = fetchPageContents.find(
        (i) => i.WidgetKey === "11223344"
      );
      tempContent && setContent(tempContent.WidgetDescription[0].Title);
      imgContent && setContentImg(imgContent.WidgetImageUrl);
      kvkkContent && setKvkkText(kvkkContent.WidgetDescription[0].Title);
    }
  }, [fetchPageContents]);

  const getCountyList = (id) => {
    generalServices.countytList(id).then((res) => {
      let distrOpt = res?.map((i) => ({ label: i.Name, value: i.Id }));
      setCountyList(distrOpt);
    });
  };

  const handleClick = () => {
    const officeFilter = {
      OfficeName: officeName ? officeName : "",
      CityId: selectedProvince ? selectedProvince.value : "",
      CountyId: selectedCounty ? selectedCounty.value : "",
      Sorting: 1,
    };
    dispatch(generalActions.setOfficeFilters(officeFilter));
    navigate("/ofis-bul");
  };

  return (
    <div className="ltn__page-details-area ltn__service-details-area mb-120 mt-50">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            {/* PRODUCT TAB AREA START */}
            <div className="ltn__product-tab-area">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_tab_1_1"
                        >
                          {t("evsat")}{" "}
                          <span>
                            <i className="fas fa-home" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_2">
                          {t("danismanbul")}{" "}
                          <span>
                            <i className="fas fa-user" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_3">
                        {t("ofisbul")}{" "}
                          <span>
                            <i className="fas fa-map-marker-alt" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_4">
                        {t("yabanciyatirimci")}{" "}
                          <span>
                            <i className="fas fa-file-alt" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_5">
                        {t("siziarayalim")}{" "}
                          <span>
                            <i className="fas fa-file-alt" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="liton_tab_1_1"
                      >
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="property-detail-info-list  clearfix mb-60">
                            {content && parse(content)}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_2">
                        <div className="ltn__myaccount-tab-content-inner">
                          <TeamFilter2 />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_3">
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="col-lg-12 p-0">
                            <div className="ltn__location-search mb-20 ">
                              <form action="#" className="clearfix">
                                <div className="row align-items-center">
                                  <div className="col-md-2 location-search-header p-0">
                                    <h3>{t("ofisbul")}</h3>
                                  </div>
                                  <div
                                    className=" col-md-3 p-0"
                                    style={{ marginRight: "2px" }}
                                  >
                                    <input
                                      className="form-control"
                                      placeholder="Ofis adı"
                                      onChange={(e) =>
                                        setOfficeName(e.target.value)
                                      }
                                      style={{ height: "45px" }}
                                    />
                                  </div>
                                  <div
                                    className=" col-md-3 p-0"
                                    style={{ marginRight: "2px" }}
                                  >
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isClearable={true}
                                      isSearchable={true}
                                      placeholder={t("il")}
                                      name="province"
                                      options={
                                        fetchProvinceList &&
                                        Object.keys(fetchProvinceList).length >
                                          0 &&
                                        fetchProvinceList
                                      }
                                      value={selectedProvince}
                                      styles={customStyles}
                                      onChange={(e) => {
                                        setSelectedProvince(e);
                                        setSelectedCounty("");
                                        if (e) {
                                          getCountyList(e.value);
                                        } else {
                                          setCountyList([]);
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className=" col-md-3 p-0">
                                    <Select
                                      className="basic-single"
                                      classNamePrefix="select"
                                      isClearable={true}
                                      isSearchable={true}
                                      placeholder={t("ilçe")}
                                      name="county"
                                      options={countyList}
                                      value={selectedCounty}
                                      styles={customStyles}
                                      onChange={(e) => setSelectedCounty(e)}
                                    />
                                  </div>
                                  <div className="col-md-1 p-0">
                                    <button
                                      onClick={() => handleClick()}
                                      className="btn2 btn-effect-3 btn-white"
                                      style={{ borderRadius: "10px" }}
                                      type="button"
                                    >
                                      {t("ara")}
                                    </button>
                                  </div>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_4">
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="property-detail-info-list  clearfix mb-60">
                            {content && parse(content)}
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_5">
                        <div className="ltn__myaccount-tab-content-inner">
                          <div className="property-details-amenities mb-60">
                            <ContactForm4 kvkk={kvkkText} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 align-self-center sell-home-page-img">
                    <div className="about-us-img-wrap about-img-right">
                      <img
                        src={
                          contentImg
                            ? contentImg
                            : publicUrl + "assets/img/others/9.png"
                        }
                        alt="About Us Image"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* PRODUCT TAB AREA END */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SellHomeDetails;
