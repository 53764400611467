import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import generalActions from "../../redux/actions/general.actions";
import generalServices from "../../services/general.service";
import Pagination from "../global-components/Pagination";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";

const Team = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const fetchTeamList = useSelector(
    (state) => state.generalListsReducer.teamMembersReducer.teamMemberList
  );
  const fetchCountryList = useSelector(
    (state) => state.generalListsReducer.countryReducer.countryList
  );
  const fetchTeamFilterOpt = useSelector(
    (state) => state.generalListsReducer.setTeamFiltersReducer.filters
  );
  const [provinceListOpt, setProvinceListOpt] = useState([]);
  const [countyListOpt, setCountyListOpt] = useState([]);
  const [selCountry, setSelCountry] = useState("");
  const [selProvince, setSelProvince] = useState("");
  const [selCounty, setSelCounty] = useState("");
  const [nameSearch, setNameSearch] = useState("");

  const [teamList, setTeamList] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumLimit] = useState(12);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);

  useEffect(() => {
    dispatch(generalActions.getTeamMemberList(fetchTeamFilterOpt));
  }, [fetchTeamFilterOpt]);

  useEffect(() => {
    const filterVal = {
      PageIndex: currentpage,
      PageSize: pageNumLimit,
      ...fetchTeamFilterOpt,
    };
    dispatch(generalActions.getTeamMemberList(filterVal));
  }, [currentpage, pageNumLimit, fetchTeamFilterOpt]);

  useEffect(() => {
    setTeamList(
      fetchTeamList?.Result?.Items ? fetchTeamList?.Result?.Items : []
    );

    console.log("TEAM LİSTESİ", fetchTeamList);
    setTotalPageCount(
      fetchTeamList?.Result?.TotalPageCount
        ? fetchTeamList?.Result?.TotalPageCount
        : 1
    );
    setCurrentPage(
      fetchTeamList?.Result?.CurrentPageIndex
        ? fetchTeamList?.Result?.CurrentPageIndex
        : 1
    );
  }, [fetchTeamList]);

  useEffect(() => {
    setSelProvince("");
    generalServices.provinceList().then((res) => {
      const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
      tempArr?.length > 0
        ? setProvinceListOpt(tempArr)
        : setProvinceListOpt([]);
    });
  }, []);

  useEffect(() => {
    setSelCounty("");
    selProvince &&
      generalServices.countytList(parseInt(selProvince?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0 ? setCountyListOpt(tempArr) : setCountyListOpt([]);
      });
  }, [selProvince]);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      minWidth: "100%",
      width: "max-content",
    }),
  };

  const handleClick = () => {
    const filterData = {
      PageIndex: currentpage,
      Firstname: nameSearch,
      CityId: selProvince ? selProvince.value : "",
      CountyId: selCounty ? selCounty.value : "",
      PageSize: 12
    };
    dispatch(generalActions.setTeamFilters(filterData));
  };

  return (
    <div className="ltn__team-area pt-20 ">
      <div className="container">
        <div className="row">
          <div className="ltn__location-search mb-20">
            <form action="#" className="clearfix">
              <div className="row align-items-center">
                <div className="col-md-2 location-search-header p-0">
                  <h3>{t('danismanbul')}</h3>
                </div>
                <div className=" col-md-3 p-0" style={{ marginRight: "2px" }}>
                  <input
                    className="form-control"
                    placeholder="Danışman adı"
                    onChange={(e) => setNameSearch(e.target.value)}
                    style={{ height: "45px" }}
                  />
                </div>
                <div className=" col-md-3 p-0" style={{ marginRight: "2px" }}>
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t("il")}
                    name="province"
                    options={provinceListOpt && provinceListOpt}
                    value={selProvince}
                    styles={customStyles}
                    onChange={(e) => {
                      setSelProvince(e);
                    }}
                  />
                </div>
                <div className=" col-md-3 p-0">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isClearable={true}
                    isSearchable={true}
                    placeholder={t("ilçe")}
                    name="county"
                    options={countyListOpt && countyListOpt}
                    value={selCounty}
                    styles={customStyles}
                    onChange={(e) => {
                      setSelCounty(e);
                    }}
                  />
                </div>
                <div className="col-md-1 p-0">
                  <button
                    onClick={() => handleClick()}
                    className="btn2 btn-effect-3 btn-white"
                    style={{ borderRadius: "10px" }}
                    type="button"
                  >
                    {t('ara')}
                  </button>
                </div>
              </div>
            </form>
          </div>
         
        </div>
        <div className="row justify-content-center go-top">
          {teamList.map((i, key) => {
            return (
              <div key={key} className="col-md-3 col-sm-6">
                <div className="ltn__team-item ltn__team-item-3---">
                  <div className="team-img">
                    <Link to={`/danisman-detay/${i.FullName}/${i.UserBaseId}`}>
                      {" "}
                      <img
                        src={
                          i.Photo
                            ? i.Photo
                            : publicUrl + "assets/img/team/4.jpg"
                        }
                        alt="Image"
                      />
                    </Link>
                  </div>
                  <div className="team-info">
                    <h4 style={{ marginBottom: "0.5rem" }}>
                      <Link to={`/danisman-detay/${i.FullName}/${i.UserBaseId}`}>
                        {i.FullName}
                      </Link>
                    </h4>
                    <h6
                      style={{ marginBottom: "0.5rem" }}
                      className="select__indicator"
                    >
                      {i.UserTitle}
                    </h6>
                    <h6
                      style={{ marginBottom: "0.5rem" }}
                      className="page_title"
                    >
                      <Link to={`/ofis-detay/${i.OfficeId}`}>
                        {i.OfficeName}
                      </Link>
                    </h6>
                    <h6
                      style={{ marginBottom: "0.5rem" }}
                      className="page_title"
                    >
                      {i.Phone}
                    </h6>
                  </div>
                </div>
              </div>
            );
          })}

          <Pagination
            paginate={paginate}
            increasePageNum={increasePageNum}
            decreasePageNum={decreasePageNum}
            activepage={currentpage}
            pageCount={totalPageCount}
            maxPageNum={maxPageNumLimit}
            minPageNum={minPageNumLimit}
          />
        </div>
      </div>
    </div>
  );
};

export default Team;
