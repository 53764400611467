import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import ContactForm from "./ContactForm";
import ContactForm2 from "./ContactForm2";
import ContactForm3 from "./ContactForm3";
import { useTranslation } from "react-i18next";

const AboutV4 = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);

  const categoryList = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const fetchSystemLang = useSelector((state) => state.generalListsReducer.systemLanguageReducer.selLang);

  const [categoryPart1, setCategoryPart1] = useState("");
  const [kvkkText, setKvkkText] = useState("");
  const [activeTab, setActiveTab] = useState(1);

  useEffect(() => {
    if (categoryList != null) {
      setCategoryPart1(categoryList.find((i) => i.WidgetKey === "3444"));
    }
  }, [categoryList]);

  useEffect(() => {
    if (fetchPageContents != null) {
      const textInfo = fetchPageContents.find((i) => i.WidgetKey === "11223344")
      console.log('CONTENTüüüüüü', textInfo.WidgetDescription.findIndex(o => o.LanguageType == fetchSystemLang))
      textInfo && textInfo.WidgetDescription[textInfo.WidgetDescription.findIndex(o => o.LanguageType == fetchSystemLang)] && setKvkkText(textInfo.WidgetDescription[textInfo.WidgetDescription.findIndex(o => o.LanguageType == fetchSystemLang)].Title);
    }
  }, [fetchPageContents]);



  return (
    <div className="ltn__page-details-area ltn__service-details-area mb-120 mt-20">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            {/* PRODUCT TAB AREA START */}
            <div className="ltn__product-tab-area">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-2">
                    <div className="ltn__tab-menu-list mb-50">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_tab_1_1"
                        >
                          {t('franchiseol')}{" "}
                          <span>
                            <i className="fas fa-arrow-right" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_2">
                        {t('danismanol')}{" "}
                          <span>
                            <i className="fas fa-arrow-right" />
                          </span>
                        </a>
                        <a data-bs-toggle="tab" href="#liton_tab_1_3">
                        {t('emailaboneligi')}{" "}
                          <span>
                            <i className="fas fa-arrow-right" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="tab-content">
                      <div
                        className="tab-pane fade active show"
                        id="liton_tab_1_1"
                      >
                        <div className="ltn__myaccount-tab-content-inner">
                          <ContactForm2 kvkk={kvkkText} />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_2">
                        <div className="ltn__myaccount-tab-content-inner">
                          <ContactForm kvkk={kvkkText}/>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="liton_tab_1_3">
                        <div className="ltn__myaccount-tab-content-inner">
                          <ContactForm3 kvkk={kvkkText}/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* PRODUCT TAB AREA END */}
          </div>
        </div>
      </div>
    </div>

    // <div className="ltn__about-us-area pt-80--- pb-80 mt--30 go-top">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-6 align-self-center">
    //         <div className="about-us-img-wrap about-img-left">
    //           <img
    //             src={categoryPart1 && categoryPart1.WidgetImageUrl ? categoryPart1.WidgetImageUrl : publicUrl + "assets/img/others/13.png"}
    //             alt="About Us Image"
    //           />
    //           <div className="about-us-img-info about-us-img-info-2 about-us-img-info-3">
    //             <div className="ltn__video-img ltn__animation-pulse1">
    //               <img
    //                 src={categoryPart1 && categoryPart1.WidgetImageUrl ? categoryPart1.WidgetImageUrl : publicUrl + "assets/img/others/8.png"}
    //                 alt="video popup bg image"
    //               />
    //               <a
    //                 className="ltn__video-icon-2 ltn__video-icon-2-border---"
    //                 href={categoryPart1 && categoryPart1.WidgetVideoEmbedCode ? categoryPart1.WidgetVideoEmbedCode : ""}
    //                 data-rel="lightcase:myCollection"
    //               >
    //                 <i className="fa fa-play" />
    //               </a>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-6 align-self-center">
    //         <div className="about-us-info-wrap">
    //        {categoryPart1 && parse(categoryPart1.WidgetDescription[0].Title)}
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default AboutV4;
