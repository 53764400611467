import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import generalServices from "../../services/general.service";
import { Formik, useFormik } from "formik";
import formServices from "../../services/form.service";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";
import TeamOfficeSlider from "./TeamOfficeSlider";
import ProductOfficeSlider from "./ProductOfficeSlider";
import Select, { StylesConfig } from "react-select";
import { useSelector } from "react-redux";
import OfficeTeam from "./OfficeTeam";
import OfficeCommDetails from "./OfficeCommDetails";
import Page_header from "../global-components/Page_header_off";
import { useTranslation } from "react-i18next";

const OfficeDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const params = useParams();
  const { t } = useTranslation(["common"]);
  const [detailInfo, setDetailInfo] = useState({});
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer?.provinceList
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const [kvkkText, setKvkkText] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const roleList = [
    { value: 2, label: t("gayrimenkuldanismani") },
    { value: 4, label: t("ofisyoneticisi") },
    { value: 5, label: t("ofisasistani") },
  ];

  useEffect(() => {
    if (fetchPageContents != null) {
      const textInfo = fetchPageContents.find(
        (i) => i.WidgetKey === "11223344"
      );
      textInfo && setKvkkText(textInfo.WidgetDescription[0].Title);
    }
  }, [fetchPageContents]);

  const [selOfficeCityId, setSelOfficeCityId] = useState("");
  const [selOfficeCountyId, setSelOfficeCountyId] = useState("");
  const [selCityId, setSelCityId] = useState("");
  const [selOfficeId, setSelOfficeId] = useState("");
  const [selRoleId, setSelRoleId] = useState("");
  const [userMessage, setUserMessage] = useState("");

  const [countyListOpt, setCountyListOpt] = useState([]);
  const [officeListOpt, setOfficeListOpt] = useState([]);
  const [roleListOpt, setRoleListOpt] = useState([]);
  const [teamList, setTeamList] = useState([]);
  const [productList, seProductList] = useState([]);

  const [consultantFormRes, setConsultantFormRes] = useState(false);
  const [contactFormRes, setContactFormRes] = useState(false);

  useEffect(() => {
    if (params) {
      generalServices.getOfficeDetail(params?.id).then((res) => {
        setDetailInfo(res?.Result);
        console.log("OFİS BİLGİSİ", res);
      });
      generalServices.getPageContents().then((res) => {});

      generalServices.teamMemberList({ OfficeId: params.id }).then((res) => {
        res && setTeamList(res.Result.Items);
      });
    }

    generalServices.userRoleList().then((res) => {
      if (res) {
        const returnRoleRes = res.map((i, k) => ({
          label: i.Description,
          value: i.Id,
        }));
        setRoleListOpt(returnRoleRes);
      }
    });
  }, []);

  useEffect(() => {
    generalServices.countytList(selCityId.value).then((res) => {
      res && setCountyListOpt(res);
    });
  }, [selCityId]);

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      BirthDate: "",
      OfficeCityId: "",
      OfficeCountyId: "",
      OfficeId: "",
      AppealTypeId: "",
      recaptcha: "",
      message: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      FirstName: Yup.string().required("*Bu alanı doldurmak zorunlu"),
      LastName: Yup.string().required("*Bu alanı doldurmak zorunlu"),
      Email: Yup.string()
        .email("*Geçersiz eposta")
        .required("*Bu alanı doldurmak zorunlu"),
      Phone: Yup.string()
        .matches(phoneRegex, "*Geçersiz telefon formatı")
        .required("*Bu alanı doldurmak zorunlu"),
      // BirthDate: Yup.string().required("*Bu alanı doldurmak zorunlu"),
      OfficeCityId: Yup.object().required("*Bu alanı doldurmak zorunlu"),
      OfficeId: Yup.object().required("*Bu alanı doldurmak zorunlu"),
      AppealTypeId: Yup.object().required("*Bu alanı doldurmak zorunlu"),
      acceptTerms: Yup.bool().oneOf([true], "Lütfen KVKK Metnini okuyunuz"),
      recaptcha: Yup.string().required("*Recaptcha zorunludur."),
    }),
    onSubmit(values) {
      const formData = {
        FirstName: values.FirstName,
        LastName: values.LastName,
        Phone: values.Phone,
        Email: values.Email,
        BirthDate: values.BirthDate,
        OfficeCityId: values.OfficeCityId.value,
        OfficeId: params?.id,
        AppealTypeId: values.AppealTypeId.value,
        Message: values.message,
      };

      formServices.appealConsultant(formData).then((res) => {
        console.log("danışman dönen", res);
        res && res.IsSucceeded && setConsultantFormRes(true);
      });
    },
  });

  const formik2 = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: "",
      recaptcha: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(t("bualanidoldurmakzorunlu")),
      email: Yup.string()
        .email(t("gecersizeposta"))
        .required(t("bualanidoldurmakzorunlu")),
      phone: Yup.string()
        .matches(phoneRegex, t("gecersiztelefonformati"))
        .required(t("bualanidoldurmakzorunlu")),
      acceptTerms: Yup.bool().oneOf([true], t("lutfenkvkkmetniniokuyunuz")),
      recaptcha: Yup.string().required(t("recaptchazorunlu")),
      acceptTerms: Yup.bool().oneOf([true], t("lutfenkvkkmetniniokuyunuz")),
    }),
    onSubmit(values) {
      const formData = {
        Id: params.id,
        FullName: values.fullName,
        Phone: values.phone,
        Email: values.email,
        Message: values.message,
      };

      formServices.officeContact(formData).then((res) => {
        res.IsSucceeded && setContactFormRes(true);
      });
    },
  });

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  return (
    <>
      <Page_header headertitle="Ofis Detay" detailInfo={detailInfo} />

      <div className="ltn__team-details-area mb-120">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3">
              <div className="ltn__team-details-member-info text-center mb-40">
                <div className="team-details-img">
                  <img
                    src={
                      detailInfo && detailInfo.Logo
                        ? detailInfo.Logo
                        : publicUrl + "assets/img/team/4.jpg"
                    }
                    alt="Team Member Image"
                  />
                </div>
                <h2>{detailInfo.OfficeName ? detailInfo.OfficeName : ""}</h2>
                <h6 className="text-uppercase select__indicator">
                  {detailInfo && detailInfo.Phone ? detailInfo.Phone : ""}
                </h6>
                <h6 className="text-uppercase select__indicator">
                  {detailInfo && detailInfo.Email ? detailInfo.Email : ""}
                </h6>
                <div className="ltn__social-media-3">
                  <ul>
                    <li>
                      <a
                        href={
                          detailInfo && detailInfo.SocialUrl
                            ? detailInfo.SocialUrl.FacebookUrl
                            : "#"
                        }
                        title="Facebook"
                      >
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          detailInfo && detailInfo.SocialUrl
                            ? detailInfo.SocialUrl.TwitterUrl
                            : "#"
                        }
                        title="Twitter"
                      >
                        <i className="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        href={
                          detailInfo && detailInfo.SocialUrl
                            ? detailInfo.SocialUrl.LinkedinUrl
                            : "#"
                        }
                        title="Linkedin"
                      >
                        <i className="fab fa-linkedin" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="mt-3">
                  <p className="text-uppercase select__indicator">
                    {detailInfo && detailInfo.Address}
                  </p>
                  <h6 className="text-uppercase select__indicator">
                    {detailInfo && detailInfo.CountyName
                      ? detailInfo.CountyName + "/" + detailInfo.CityName
                      : ""}
                  </h6>
                  <a
                    href={`https://www.google.com.tr/maps/dir//${detailInfo.Latitude},${detailInfo.Longitude}`}
                    className="btn theme-btn-1 btn-effect-1 text-uppercase"
                    target="_blank"
                  >
                    <i className="fas fa-map-marker-alt" /> {t("yoltarifial")}
                  </a>
                </div>
              </div>
              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                {consultantFormRes ? (
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    {t("mesajinizbasariylailetilmistir")}
                  </h4>
                ) : (
                  <>
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      {t("gayrimenkuldanismaniolmakistermisiniz")}
                    </h4>
                    <form id="contact-form" onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            className="form-control d-flex align-items-center mb-0"
                            type="text"
                            name="FirstName"
                            placeholder={t("isim")}
                            style={{ height: "45px" }}
                            onChange={formik.handleChange}
                            value={formik.values.FirstName}
                          />
                        </div>
                        {formik.touched.FirstName && formik.errors.FirstName ? (
                          <span className="text-danger">
                            {formik.errors.FirstName}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            className="form-control d-flex align-items-center mb-0"
                            type="text"
                            name="LastName"
                            placeholder={t("soyisim")}
                            style={{ height: "45px" }}
                            onChange={formik.handleChange}
                            value={formik.values.LastName}
                          />
                        </div>
                        {formik.touched.LastName && formik.errors.LastName ? (
                          <span className="text-danger">
                            {formik.errors.LastName}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            className="form-control d-flex align-items-center mb-0"
                            style={{ height: "45px" }}
                            type="email"
                            name={t("email")}
                            placeholder="Email"
                            onChange={formik.handleChange}
                            value={formik.values.Email}
                          />
                        </div>
                        {formik.touched.Email && formik.errors.Email ? (
                          <span className="text-danger">
                            {formik.errors.Email}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            className="form-control d-flex align-items-center mb-0"
                            style={{ height: "45px" }}
                            type="text"
                            name={t("telefon")}
                            placeholder="Telefon"
                            onChange={formik.handleChange}
                            value={formik.values.Phone}
                          />
                        </div>
                        {formik.touched.Phone && formik.errors.Phone ? (
                          <span className="text-danger">
                            {formik.errors.Phone}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-date ltn__custom-icon mb-0">
                          <input
                            className="form-control d-flex align-items-center mb-0"
                            style={{ height: "45px" }}
                            type="date"
                            name="BirthDate"
                            placeholder={t("dogumtarihi")}
                            onChange={formik.handleChange}
                            value={formik.values.BirthDate}
                          />
                        </div>
                        {formik.touched.BirthDate && formik.errors.BirthDate ? (
                          <span className="text-danger">
                            {formik.errors.BirthDate}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div
                          className="input-item"
                          style={{ marginBottom: "30px" }}
                        >
                          <Select
                            className=""
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            placeholder={t("il")}
                            name="OfficeCityId"
                            options={fetchProvinceList && fetchProvinceList}
                            value={formik.values.OfficeCityId}
                            onChange={(e) => {
                              setSelCityId(e);
                              formik.setFieldValue("OfficeCityId", e);
                            }}
                            styles={customStyles}
                          />
                          {formik.touched.OfficeCityId &&
                          formik.errors.OfficeCityId ? (
                            <span className="text-danger">
                              {formik.errors.OfficeCityId}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-3">
                        <div
                          className="input-item"
                          style={{ marginBottom: "30px" }}
                        >
                          <Select
                            className=""
                            classNamePrefix="select"
                            isClearable={true}
                            isSearchable={true}
                            placeholder={t("calismakistediginpozisyon")}
                            name="AppealTypeId"
                            options={roleList}
                            value={formik.values.AppealTypeId}
                            onChange={(e) => {
                              formik.setFieldValue("AppealTypeId", e);
                            }}
                            styles={customStyles}
                          />
                          {formik.touched.OfficeCountyId &&
                          formik.errors.OfficeCountyId ? (
                            <span className="text-danger">
                              {formik.errors.OfficeCountyId}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="input-item input-item-textarea ltn__custom-icon">
                        <textarea
                          name="message"
                          placeholder={t("mesaj")}
                          defaultValue={""}
                          value={formik.values.message}
                          onChange={(e) => {
                            formik.setFieldValue("message", e.target.value);
                          }}
                        />
                      </div>
                      <div className="input-item">
                        <p>
                          <label className="input-info-save mb-0">
                            <input type="checkbox" name="acceptTerms" />
                            <Link className="ms-1" to="/kvkk-aciklama-metni">{t("kvkkokudumonayliyorum")}</Link>
                          </label>
                        </p>
                        {formik.touched.acceptTerms &&
                        formik.errors.acceptTerms ? (
                          <span className="text-danger">
                            {formik.errors.acceptTerms}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-20 captcha">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          render="explicit"
                          onChange={(response) => {
                            formik2.setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {}}
                        />
                      </div>
                      <div className="btn-wrapper mt-0">
                        <button
                          className="btn theme-btn-1 btn-effect-1 text-uppercase"
                          type="submit"
                        >
                          {t("gonder")}
                        </button>
                      </div>
                      <p className="form-messege mb-0 mt-20" />
                    </form>
                  </>
                )}
              </div>
              {/* Form Widget */}
              <div className="widget ltn__form-widget">
                {contactFormRes ? (
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    {t("mesajinizbasariylailetilmistir")}
                  </h4>
                ) : (
                  <>
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      {t("iletisim-nav")}
                    </h4>
                    <form onSubmit={formik2.handleSubmit}>
                      <div className="mb-3">
                        <div className="input-item input-item-name ltn__custom-icon">
                          <input
                            type="text"
                            name="fullName"
                            placeholder={`${t("isim")}-${t("soyisim")}*`}
                            className="mb-0"
                            onChange={formik2.handleChange}
                            value={formik2.values.name}
                            style={{ height: "45px" }}
                          />
                        </div>
                        {formik2.touched.fullName && formik2.errors.fullName ? (
                          <span className="text-danger">
                            {formik2.errors.fullName}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-phone ltn__custom-icon">
                          <input
                            type="text"
                            name="phone"
                            placeholder={t("phone")}
                            className="mb-0"
                            onChange={formik2.handleChange}
                            value={formik2.values.phone}
                            style={{ height: "45px" }}
                          />
                        </div>
                        {formik2.touched.phone && formik2.errors.phone ? (
                          <span className="text-danger">
                            {formik2.errors.phone}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <div className="input-item input-item-email ltn__custom-icon">
                          <input
                            type="email"
                            name="email"
                            placeholder={t("email")}
                            className="mb-0"
                            onChange={formik2.handleChange}
                            value={formik2.values.email}
                            style={{ height: "45px" }}
                          />
                        </div>
                        {formik2.touched.email && formik2.errors.email ? (
                          <span className="text-danger">
                            {formik2.errors.email}
                          </span>
                        ) : null}
                      </div>
                      <div className="input-item input-item-textarea ltn__custom-icon">
                        <textarea
                          name="message"
                          placeholder={t("iletmekistedikleriniz")}
                          defaultValue={""}
                          onChange={(e) => {
                            formik2.setFieldValue("message", e.target.value);
                          }}
                          value={formik2.values.message}
                        />
                      </div>                      
                      <div className="input-item">
                        <p>
                          <label className="input-info-save mb-0">
                            <input type="checkbox" name="acceptTerms" />
                            <Link className="ms-1" to="/kvkk-aciklama-metni">{t("kvkkokudumonayliyorum")}</Link>
                          </label>
                        </p>
                        {formik.touched.acceptTerms &&
                        formik.errors.acceptTerms ? (
                          <span className="text-danger">
                            {formik.errors.acceptTerms}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-20 captcha">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          render="explicit"
                          onChange={(response) => {
                            formik2.setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {}}
                        />
                        {formik2.touched.recaptcha &&
                        formik2.errors.recaptcha ? (
                          <span className="text-danger">
                            {formik2.errors.recaptcha}
                          </span>
                        ) : null}
                      </div>
                      <button type="submit" className="btn theme-btn-1">
                        {t("gonder")}
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
            <div className="col-lg-9">
              <div className="ltn__team-details-member-info-details">
                <div className="row">
                  <div className="row">
                    <div className="col-md-3 p-0">
                      <button
                        className={
                          activeTab == 1
                            ? "btn green-2-color w-100 ps-0 pe-0"
                            : "btn w-100 ps-0 pe-0 select__indicator-separator text-white"
                        }
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        onClick={() => setActiveTab(1)}
                      >
                        {t("ekibimiz")}
                      </button>
                    </div>
                    <div className="col-md-3 p-0">
                      <button
                        className={
                          activeTab == 2
                            ? "btn green-2-color w-100 ps-0 pe-0"
                            : "btn w-100 ps-0 pe-0 select__indicator-separator text-white"
                        }
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        onClick={() => setActiveTab(2)}
                      >
                        {t("hakkimizda")}
                      </button>
                    </div>
                    <div className="col-md-3 p-0">
                      <button
                        className={
                          activeTab == 3
                            ? "btn green-2-color w-100 ps-0 pe-0 text-uppercase"
                            : "btn w-100 ps-0 pe-0 select__indicator-separator text-white text-uppercase"
                        }
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        onClick={() => setActiveTab(3)}
                      >
                        {t("portfoylerimiz")}
                      </button>
                    </div>
                    <div className="col-md-3 p-0">
                      <button
                        className={
                          activeTab == 4
                            ? "btn green-2-color w-100 ps-0 pe-0"
                            : "btn w-100 ps-0 pe-0 select__indicator-separator text-white"
                        }
                        style={{ fontSize: "18px", fontWeight: "600" }}
                        onClick={() => setActiveTab(4)}
                      >
                        İLETİŞİM
                      </button>
                    </div>
                  </div>
                  <div className="ltn__team-details-member-info-details">
                    {activeTab == 1 && (
                      <div className="row mt-4">
                        <>
                          <OfficeTeam
                            params={
                              detailInfo ? detailInfo.FranchiseOfficeUsers : ""
                            }
                          />
                        </>
                      </div>
                    )}

                    {activeTab == 2 && (
                      <div className="row mt-4">
                        <>
                          {Object.keys(detailInfo).length > 0 &&
                          detailInfo.OfficeAbout
                            ? parse(detailInfo.OfficeAbout)
                            : "-"}
                        </>
                      </div>
                    )}
                    {activeTab == 3 && (
                      <div className="row mt-4">
                        <>
                          <ProductOfficeSlider params={params} />
                        </>
                      </div>
                    )}
                    {activeTab == 4 && (
                      <div className="row mt-4">
                        <>
                          <OfficeCommDetails
                            params={detailInfo ? detailInfo : ""}
                          />
                        </>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeDetails;
