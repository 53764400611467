import React, { Component, useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

const TeamDetailBanner = ({ detailInfo, userTitleList }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  useEffect(() => {
    if (userTitleList) {
      const targetTitle = userTitleList.find(
        (i) => i.Id == detailInfo.UserTitleId
      );
    }
  }, [userTitleList]);

  return (
    <div className="ltn__slider-area ltn__slider-11  ltn__slider-11-slide-item-count-show--- ltn__slider-11-pagination-count-show---">
      <div className="ltn__slider-11-inner">
        <div className="ltn__slider-11-active">
          {/* slide-item */}
          <div
            className="row align-items-center ltn__slide-item-team ltn__slide-item-2-team ltn__slide-item-3-normal-team ltn__slide-item-3-team ltn__slide-item-11-team p-0"
            style={{ paddingTop: "0 !important" }}
          >
            <div className="ltn__slide-item-inner d-flex justify-content-center  col-sm-12 col-md-6 p-0">
              <div className="slide-item-info">
                <div className="slide-item-info-inner ltn__slide-animation">
                  <h3 className="slide-title animated ">
                    {detailInfo.FullName && detailInfo.FullName}
                  </h3>
                  <h4 className="animated mb-0">
                    {detailInfo.UserRole && detailInfo.UserRole}
                  </h4>
                  <div className="animated">
                    <table>
                      <tbody>
                        {detailInfo.UserPhone && (
                          <tr>
                            <td>
                              <i className="fas fa-phone" />
                            </td>
                            <td style={{ paddingLeft: "5px" }}>
                              {detailInfo.UserPhone}
                            </td>
                          </tr>
                        )}
                        {detailInfo.Email && (
                          <tr>
                            <td>
                              <i class="fas fa-envelope"></i>
                            </td>
                            <td style={{ paddingLeft: "5px" }}>
                              {detailInfo.Email}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  {detailInfo.OfficeName && (
                    <Link
                      to={`/ofis-detay/${detailInfo.OfficeId}`}
                      className="animated mb-0 mt-1"
                    >
                      <h4 className="mb-0">{detailInfo.OfficeName}</h4>
                    </Link>
                  )}
                  <div className="animated">
                    <table>
                      <tbody>
                        {detailInfo.OfficePhone && (
                          <tr>
                            <td>
                              <i className="fas fa-phone" />
                            </td>
                            <td style={{ paddingLeft: "5px" }}>
                              {detailInfo.OfficePhone}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <h4 className="animated mb-0 mt-1">
                    {detailInfo.CertificateNo && detailInfo.CertificateNo}
                  </h4>
                  {detailInfo.EmbedVideo && (
                    <div className="btn-wrapper animated">
                      <a
                        className="ltn__video-play-btn btn-effect-1"
                        href={detailInfo.EmbedVideo}
                        data-rel="lightcase:myCollection"
                      >
                        <i className="icon-play" style={{ color: "white" }} />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex col-sm-12 col-md-6 team-det-head p-0">
              {detailInfo.Photo ? (
                <div
                  className="slide-item-img-team-banner"
                  style={{ backgroundImage: `url(${detailInfo.Photo})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' , backgroundPosition:'center top', height:'100%', width: '100%' }}
                ></div>
              ) : (
                <div className="slide-item-img-team-banner"></div>
              )}
            </div>
          </div>
        </div>
        {/* slider-sticky-icon */}
        <div className="slider-sticky-icon-2">
          <ul>
            {detailInfo.FacebookUrl && (
              <li>
                <a href={detailInfo.FacebookUrl} title="Facebook">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
            )}
            {detailInfo.TwitterUrl && (
              <li>
                <a href={detailInfo.TwitterUrl} title="Twitter">
                  <i className="fab fa-twitter" />
                </a>
              </li>
            )}
            {detailInfo.LinkedinUrl && (
              <li>
                <a href={detailInfo.LinkedinUrl} title="Linkedin">
                  <i className="fab fa-linkedin" />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default TeamDetailBanner;
