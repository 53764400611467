import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import generalActions from "../../redux/actions/general.actions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


import parse from "html-react-parser";
import Social from "../section-components/Social";

const Footer = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imgattr = "Footer logo";
  const { t } = useTranslation(["common"]);

  const dispatch = useDispatch();
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.allPagesByTypeReducer.allPagesByType
  );
  const fetchCompSettings = useSelector(
    (state) => state.generalListsReducer.companySettingsReducer.compSettings
  );
  const token = useSelector((state) => state);
  const fetchSystemLang = useSelector((state) => state.generalListsReducer.systemLanguageReducer.selLang);

  const [footerDescText, setFooterDescText] = useState("");
  const [footerCommList, setFooterCommList] = useState({});
  const [genContentUrlPart1, setGenContentUrlPart1] = useState([]);
  const [genContentUrlPart2, setGenContentUrlPart2] = useState([]);
  const [genContentUrlPart3, setGenContentUrlPart3] = useState([]);

  useEffect(() => {
    const footerLinks = fetchPageContents?.filter((i) => i.PageType == 5);
    if (footerLinks && Object.keys(footerLinks).length > 0) {
      const tempArr1 = [];
      const tempArr2 = [];
      const tempArr3 = [];
      let count = 0;
      const part1 = footerLinks.filter((i) => i.PageOrder == 2);
      const part2 = footerLinks.filter((i) => i.PageOrder == 3);
      const part3 = footerLinks.filter((i) => i.PageOrder == 4);
      console.log('PARTLAR', part1, part2, part3);
      if (part1.length > 0) {
        part1.map((i, k) => {
          tempArr1.push({
            title: i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
            url: i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
          });
        });
      }
      if (part2.length > 0) {
        part2.map((i, k) => {
          tempArr2.push({
            title: i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
            url: i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
          });
        });
      }
      if (part3.length > 0) {
        part3.map((i, k) => {
          tempArr3.push({
            title: i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.Titles[i.Titles.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
            url: i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)] ? i.PageLinks[i.PageLinks.findIndex(o => o.LanguageType == fetchSystemLang)].Title : '',
          });
        });
      }

      setGenContentUrlPart1(tempArr1);
      setGenContentUrlPart2(tempArr2);
      setGenContentUrlPart3(tempArr3);
    }
  }, [fetchPageContents, fetchSystemLang]);

  useEffect(() => {
    token && dispatch(generalActions.getPageByType());
  }, []);

  useEffect(() => {
    fetchCompSettings &&
      Object.keys(fetchCompSettings).length > 0 &&
      setFooterCommList(fetchCompSettings.Locations[0]);
  }, [fetchCompSettings]);

  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
    const minscript = document.createElement("script");
    minscript.async = true;
    minscript.src = publicUrl + "assets/js/main.js";

    document.body.appendChild(minscript);

    $(".go-top")
      .find("a")
      .on("click", function () {
        $(".quarter-overlay").fadeIn(1);

        $(window).scrollTop(0);

        setTimeout(function () {
          $(".quarter-overlay").fadeOut(300);
        }, 800);
      });

    $(document).on("click", ".theme-btn-1 ", function () {
      $("div").removeClass("modal-backdrop");
      $("div").removeClass("show");
      $("div").removeClass("fade");
      $("body").attr("style", "");
    });
  }, []);

  useEffect(() => {}, [fetchPageContents]);

  return (
    <footer className="ltn__footer-area">
      <div
        className="footer-top-area  section-bg-2 plr--5 pt-3"
        style={{ backgroundColor: "#122B08" }}
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-md-3 col-sm-6 col-12">
              <div className="footer-widget footer-about-widget">
                <div className="footer-logo">
                  <div className="site-logo">
                    <Link to={`/`}>
                      <img
                        src={
                          publicUrl +
                          "assets/img/BHGRE_Turkiye_Logolar_beyaz-01.png"
                        }
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
                <p>{footerDescText && footerDescText}</p>
              </div>
            </div>
            <div className="col-xl-3 col-md-3 col-sm-6 col-12 p-0">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">Better Homes and Gardens</h4>
                <div className="footer-menu go-top">
                  <ul>
                    {genContentUrlPart3.length > 0 &&
                      genContentUrlPart3.map((i, k) => (
                        <li key={k}>
                          <Link to={`/${i.url}`}>{i.title}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 col-sm-6 col-12 p-0">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t('guvenlikMerkezi')}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    {genContentUrlPart2.length > 0 &&
                      genContentUrlPart2.map((i, k) => (
                        <li key={k}>
                          <Link to={`/${i.url}`}>{i.title}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 col-sm-6 col-12 p-0">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t('birlikteCalis')}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    {genContentUrlPart1.length > 0 &&
                      genContentUrlPart1.map((i, k) => (
                        <li key={k}>
                          <Link to={`/${i.url}`}>{i.title}</Link>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-2 col-md-2 col-sm-6 col-12 p-0">
              <div className="footer-widget footer-menu-widget clearfix">
                <h4 className="footer-title">{t('iletisim-foot')}</h4>
                <div className="footer-menu go-top">
                  <ul>
                    <li>
                      {" "}
                      <i
                        style={{
                          fontWeight: "bold",
                          color: "white",
                        }}
                        className="fas fa-home"
                      ></i>
                     <span className="ms-2">{fetchCompSettings && fetchCompSettings.Locations[0].Address}</span>
                    </li>
                    <li>
                      {" "}
                      <i
                        style={{
                          fontWeight: "bold",
                          color: "white",
                        }}
                        className="icon-call"
                      ></i>
                     <span className="ms-2">{fetchCompSettings && fetchCompSettings.Locations[0].WorkPhone1}</span>
                    </li>
                    <li>
                      {" "}
                      <i
                        style={{
                          fontWeight: "bold",
                          color: "white",
                        }}
                        className="icon-call"
                      ></i>
                     <span className="ms-2">{fetchCompSettings && fetchCompSettings.Locations[0].GsmPhone}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center text-center ltn__social-media mt-1 mb-1">
            <Social settings={fetchCompSettings} />
          </div>
          <div className="row justify-content-center text-center">
            <p className="mb-0">
              CRM & MLS Servis Sağlayıcısı{" "}
              <span>
                <img
                  src={publicUrl + "assets/img/reos-logo.png"}
                  alt="Reos"
                  style={{
                    width: "55px",
                    marginLeft: "15px",
                    cursor: "pointer",
                  }}
                />
              </span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
