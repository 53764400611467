import React, { Component, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import generalServices from "../../services/general.service";
import homePageServices from "../../services/homepage.service";
import Pagination from "../global-components/Pagination";
import { useTranslation } from "react-i18next";

const BlogGrid = () => {
  const { t } = useTranslation(["common"]);
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumLimit] = useState(5);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const [blogList, setBlogList] = useState([]);


  useEffect(() => {
    homePageServices.getBlogsList().then((res) => {
	  setTotalPageCount(res?.Result.TotalPageCount);
      setCurrentPage(res?.Result.CurrentPageIndex);
      setBlogList(res?.Result.Items);
    });
  }, []);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  return (
    <div className="ltn__blog-area ltn__blog-item-3-normal mb-80 mt-80 go-top ">
      <div className="container">
        <div className="row">
          {/* Blog Item */}
		  {blogList?.length > 0 &&
              blogList.map((i, key) => {
                return (
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div className="ltn__blog-item ltn__blog-item-3">
                      <div
                        className="ltn__blog-img"
                        style={{
                          overflow: "hidden",
                          height: 0,
                          paddingTop: "56.25%",
                          position: "relative",
                        }}
                      >
                        <Link to={`/blog-detay/${i.Id}`}>
                          <img
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            src={
                              i.Image
                                ? i.Image
                                : publicUrl + "assets/img/blog/1.jpg"
                            }
                            alt="#"
                          />
                        </Link>
                      </div>
                      <div className="ltn__blog-brief">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-author">
                              <Link to="#">
                                <i className="far fa-user" />
                                BetterHomes
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <h3
                          className="ltn__blog-title"
                          style={{
                            whiteSpace: "nowrap",
                            width: "calc(100% - 20px + 5px)",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          <Link to={`/blog-detay/${i.Id}`}>{i.Title && i.Title}</Link>
                        </h3>
                        <div className="ltn__blog-meta-btn">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-date">
                                <i className="far fa-calendar-alt" />
                                {i.CreatedDate && i.CreatedDate}
                              </li>
                            </ul>
                          </div>
                          <div className="ltn__blog-btn">
                            <Link to={`/blog-detay/${i.Id}`}>{t("dahafazla")}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          {/*  */}
        </div>
        <Pagination
          paginate={paginate}
          increasePageNum={increasePageNum}
          decreasePageNum={decreasePageNum}
          activepage={currentpage}
          pageCount={totalPageCount}
          maxPageNum={maxPageNumLimit}
          minPageNum={minPageNumLimit}
        />
      </div>
    </div>
  );
};

export default BlogGrid;
