import React, { useEffect } from "react";
import Navbar from "./global-components/Navbar-v2";
import BlogDetail from ".//blog-components/blog-details";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import Page_header from "./global-components/Page_header";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const Blog_Detail = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);
  return (
    <div>
      <Navbar />
      {/* <Page_header headertitle="Blog Detay" /> */}
      <BlogDetail />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Blog_Detail;
