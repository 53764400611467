import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Select from "react-select";
import FilterTypeConst from "./FilterTypeConst";
import generalServices from "../../services/general.service";
import { useTranslation } from "react-i18next";

const FilterType1 = ({ options, AdBaseId, AdTypeId, filterVal }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const fetchCountryList = useSelector(
    (state) => state.generalListsReducer.countryReducer.countryList
  );

  //Process Type Filter Values
  const [processType, setProcessType] = useState([]);
  const [selProcessType, setSelProcessType] = useState("");

  // Land Property Type Values
  const [landPropertyType, setLandPropertyType] = useState([]);
  const [selLandPropertyType, setSelLandPropertyType] = useState("");

  // Land Fullness Status Values
  const [landFullnessStatus, setLandFullnessStatus] = useState([]);
  const [selLandFullnessStatus, setSelLandFullnessStatus] = useState("");

  // Land Register Status Values
  const [landRegisterStatus, setLandRegisterStatus] = useState([]);
  const [selLandRegisterStatus, setSelLandRegisterStatus] = useState("");

  // Land Exchange For Flat Status Values
  const [landExchangeForFlat, setLandExchangeForFlat] = useState([]);
  const [selLandExchangeForFlat, setSelLandExchangeForFlat] = useState("");

  const [provinceListOpt, setProvinceListOpt] = useState([]);
  const [countyListOpt, setCountyListOpt] = useState([]);
  const [districtListOpt, setDistrictListOpt] = useState([]);
  const [selProvince, setSelProvince] = useState("");
  const [selDistrict, setSelDistrict] = useState("");
  const [selCounty, setSelCounty] = useState("");

  useEffect(() => {
    setSelProvince("");
    setSelDistrict("");
    setSelCounty("");
      generalServices
        .provinceList()
        .then((res) => {
          const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
          tempArr?.length > 0
            ? setProvinceListOpt(tempArr)
            : setProvinceListOpt([]);
        });
  }, []);

  useEffect(() => {
    setSelDistrict("");
    setSelCounty("");
    selProvince && generalServices.countytList(parseInt(selProvince?.value)).then((res) => {
      const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
      tempArr?.length > 0 ? setCountyListOpt(tempArr) : setCountyListOpt([]);
    });
  }, [selProvince]);

  useEffect(() => {
    setSelDistrict("");
    selCounty &&
      generalServices.districtstList(parseInt(selCounty?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0
          ? setDistrictListOpt(tempArr)
          : setDistrictListOpt([]);
      });
  }, [selCounty]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      const adProcessTypeObj = options.ProcessType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setProcessType(adProcessTypeObj);

      const adLandPropertyTypeObj = options.LandPropertyType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setLandPropertyType(adLandPropertyTypeObj);

      const adLandFullnessStatusObj = options.LandFullnessStatus.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setLandFullnessStatus(adLandFullnessStatusObj);

      const adLandRegisterStatusObj = options.LandRegisterStatus.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setLandRegisterStatus(adLandRegisterStatusObj);

      const adLandExchangeForFlatObj = options.LandExchangeForFlat.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setLandExchangeForFlat(adLandExchangeForFlatObj);
    }
  }, [options]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "50px",
      height: 50,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  const setFilterValues = () => {
    const filterObj = {
      AdBaseId: AdBaseId,
      AdTypeId: AdTypeId,
      ProcessTypeId: selProcessType ? selProcessType.value : "",
      LandPropertyTypeId: selLandPropertyType ? selLandPropertyType.value : "",
      LandFullnessStatusId: selLandFullnessStatus
        ? selLandFullnessStatus.value
        : "",
      LandRegisterStatusId: selLandRegisterStatus
        ? selLandRegisterStatus.value
        : "",
      LandExchangeForFlat: selLandExchangeForFlat
        ? selLandExchangeForFlat.value
        : "",
      CityId: selProvince ? selProvince.value : '',
      CountyId: selCounty ? selCounty.value : '',
      DistrictId: selDistrict ? selDistrict.value : '',
    };

    filterVal(filterObj);
  };

  return (
    <>
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('islemtipiseciniz')}
        name="type"
        options={processType}
        value={selProcessType}
        styles={customStyles}
        onChange={(e) => {
          setSelProcessType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('il')}
        name="province"
        options={provinceListOpt && provinceListOpt}
        value={selProvince}
        onChange={(e) => {
          setSelProvince(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('ilçe')}
        name="county"
        options={countyListOpt && countyListOpt}
        value={selCounty}
        onChange={(e) => {
          setSelCounty(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('semt/mahalle')}
        name="district"
        options={districtListOpt && districtListOpt}
        value={selDistrict}
        onChange={(e) => {
          setSelDistrict(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t("imardurumunuseciniz")}
        name="type"
        options={landPropertyType}
        value={selLandPropertyType}
        styles={customStyles}
        onChange={(e) => {
          setSelLandPropertyType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t("kullanimdurumunuseciniz")}
        name="type"
        options={landFullnessStatus}
        value={selLandFullnessStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelLandFullnessStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t("tapudurumunuseciniz")}
        name="type"
        options={landRegisterStatus}
        value={selLandRegisterStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelLandRegisterStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t("katkarsiligi")}
        name="type"
        options={landExchangeForFlat}
        value={selLandExchangeForFlat}
        styles={customStyles}
        onChange={(e) => {
          setSelLandExchangeForFlat(e);
        }}
      />
      <div className="btn-wrapper mt-4 row">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="button"
          onClick={() => setFilterValues()}
        >
           {t('gonder')}
        </button>
      </div>
      {/* <FilterTypeConst options={options} /> */}
    </>
  );
};

export default FilterType1;
