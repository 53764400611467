import React, { Component, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import generalServices from "../../services/general.service";
import generalActions from "../../redux/actions/general.actions";
import TeamFilter2 from "./TeamFilter2";
import ReCAPTCHA from "react-google-recaptcha";
import ContactForm4 from "./ContactForm4";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const OfficeCommDetails = ({ params }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer.provinceList
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );

  const fetchCompSettings = useSelector(
    (state) => state.generalListsReducer.companySettingsReducer.compSettings
  );
  const [kvkkText, setKvkkText] = useState("");
  const [compInfo, setCompInfo] = useState({});
  const [center, setCenter] = useState({});
  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    if (params && Object.keys(params).length > 0) {
      const tempObj = {
        lat: Number(params.Latitude),
        lng: Number(params.Longitude),
      };
      setCenter(tempObj);
      setCompInfo(params);
    }

  }, [params]);

  useEffect(() => {
    if (fetchPageContents && fetchPageContents.length > 0) {
      const tempContent = fetchPageContents.find(
        (i) => i.WidgetKey === "333333333333"
      );
      const imgContent = fetchPageContents.find(
        (i) => i.WidgetKey === "112233"
      );
      const kvkkContent = fetchPageContents.find(
        (i) => i.WidgetKey === "11223344"
      );

      kvkkContent && setKvkkText(kvkkContent.WidgetDescription[0].Title);
    }
  }, [fetchPageContents]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfacQ1tAOSlcKRE1bgg-WRobYGyvHrprI",
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [center]
  );

  const onUnmount = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        setMap(null);
      }
      console.log("CENTER", center);
    },
    [center]
  );

  return (
    <div className="ltn__page-details-area ltn__service-details-area">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            {/* PRODUCT TAB AREA START */}
            <div className="ltn__product-tab-area">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-12">
                    {isLoaded && (
                      <div className="ltn__google-map-locations-area">
                        <div className="property-details-google-map mb-60">
                          {Object.keys(center).length > 0 && (
                            <GoogleMap
                              mapContainerStyle={containerStyle}
                              center={center}
                              zoom={9}
                              onLoad={onLoad}
                              onUnmount={onUnmount}
                            >
                              <Marker
                                position={{
                                  lat: center.lat,
                                  lng: center.lng,
                                }}
                                title={compInfo ? compInfo.OfficeName : ''}
                              />
                            </GoogleMap>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* <div className="col-lg-6">
                    <ContactForm4 kvkk={kvkkText} />
                  </div> */}
                </div>
              </div>
            </div>
            {/* PRODUCT TAB AREA END */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/10.png"}
                  alt="Icon Image"
                />
              </div>
              <h3 style={{ marginBottom: "5px" }}>{t('email')}</h3>
              <p>
                {Object.keys(compInfo).length > 0 && compInfo.Email} <br />
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/11.png"}
                  alt="Icon Image"
                />
              </div>
              <h3 style={{ marginBottom: "5px" }}>{t('telefon')}</h3>
              <p>
                {Object.keys(compInfo).length > 0 && compInfo.Phone} <br />
              </p>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="ltn__contact-address-item ltn__contact-address-item-3 box-shadow">
              <div className="ltn__contact-address-icon">
                <img
                  src={publicUrl + "assets/img/icons/12.png"}
                  alt="Icon Image"
                />
              </div>
              <h3 style={{ marginBottom: "5px" }}>{t('adres')}</h3>
              <p>
                {Object.keys(compInfo).length > 0 && compInfo.Address} <br />
                {/* {Object.keys(compInfo).length > 0 && compInfo.DistrictInfo.Name + ',' + compInfo.CountyInfo.Name + ',' + compInfo.CityInfo.Name} */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OfficeCommDetails;
