import React, { useEffect } from "react";
import Navbar from "./global-components/Navbar-v2";
import PageHeader from "./global-components/Page_header";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import NewsGrid from "./news-components/news-grid";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";
import Corporate from "./section-components/Corporate";

const Official = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Kurumsal" customclass="mb-0" /> */}
      <Corporate />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Official;
