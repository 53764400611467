import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import generalActions from "../../redux/actions/general.actions";
import generalServices from "../../services/general.service";
import { Link, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import Select, { StylesConfig } from "react-select";
import advertServices from "../../services/advert.service";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  let navigate = useNavigate();
  const token = useSelector((state) => state);
  const fetchCountryList = useSelector(
    (state) => state.generalListsReducer.countryReducer.countryList
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const fetchShopFilters = useSelector(
    (state) => state.generalListsReducer.shopFiltersReducer.filters
  );


  let publicUrl = process.env.PUBLIC_URL + "/";
  const [videoContent, setVideoContent] = useState("");
  const [provinceListOpt, setProvinceListOpt] = useState([]);
  const [countyListOpt, setCountyListOpt] = useState([]);
  const [districtListOpt, setDistrictListOpt] = useState([]);
  const [currencyListOpt, setCurrencyListOpt] = useState([]);
  const [selCountry, setSelCountry] = useState("");
  const [selProcessType, setSelProcessType] = useState("");
  const [selProvince, setSelProvince] = useState("");
  const [selDistrict, setSelDistrict] = useState("");
  const [selCounty, setSelCounty] = useState("");
  const [selAdType, setSelAdType] = useState("");
  const [selCurrency, setSelCurrency] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [selRoomCount, setSelRoomCount] = useState("");
  const [consultant, setConsultant] = useState("");
  const [officeName, setOfficeName] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [tabMorePart1, setTabMorePart1] = useState(false);
  const [tabMorePart2, setTabMorePart2] = useState(false);
  const [tabMorePart3, setTabMorePart3] = useState(false);
  const [tabMorePart4, setTabMorePart4] = useState(false);
  const [selUnit, setSelUnit] = useState("₺");

  const [processTypeOpt, setProcessTypeOpt] = useState([]);

  useEffect(() => {
    token && dispatch(generalActions.getShopFilters());
    token && dispatch(generalActions.getCountryList());
    token &&
      generalServices.getAllCurrencies().then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr.length > 0
          ? setCurrencyListOpt(tempArr)
          : setCurrencyListOpt([]);
      });
  }, []);

  useEffect(() => {
    const videoContent2 = fetchPageContents?.find(
      (i) => i.WidgetKey === "1234567890"
    );
    videoContent2 && setVideoContent(videoContent2.WidgetVideoFileUrl);

    console.log("burası kaç defa render oldu", videoContent2);
  }, [fetchPageContents]);

  useEffect(() => {
    const filterTempObj = {
      AdTypeId: selAdType ? parseInt(selAdType.value) : "",
    };

    advertServices.binddropdowns(filterTempObj).then((res) => {
      if (res) {
        const processTypeTempObj = res.ProcessType.map((i) => ({
          label: i.Text,
          value: i.Value,
        }));
        setProcessTypeOpt(processTypeTempObj);
      }
    });
  }, [selAdType]);

  useEffect(() => {
    setSelProvince("");
    setSelDistrict("");
    setSelCounty("");
    generalServices.provinceList().then((res) => {
      const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
      tempArr?.length > 0
        ? setProvinceListOpt(tempArr)
        : setProvinceListOpt([]);
    });
  }, []);

  useEffect(() => {
    setSelDistrict("");
    setSelCounty("");
    selProvince &&
      generalServices.countytList(parseInt(selProvince?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0 ? setCountyListOpt(tempArr) : setCountyListOpt([]);
      });
  }, [selProvince]);

  useEffect(() => {
    setSelDistrict("");
    token &&
      selCounty &&
      generalServices.districtstList(parseInt(selCounty?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0
          ? setDistrictListOpt(tempArr)
          : setDistrictListOpt([]);
      });
  }, [selCounty]);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      width: 250,
      margin: 5,
    }),
    option: (provided, state) => ({
      ...provided,
      width: 250,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      width: 250,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  const tabChange = (id) => {
    setSelProvince("");
    setSelDistrict("");
    setSelCounty("");
    setSelAdType("");
    setSelCurrency("");
    setMinPrice("");
    setMaxPrice("");
    setConsultant("");
    setOfficeName("");
    setSelRoomCount("");
    setTabMorePart1(false);
    setTabMorePart2(false);
    setActiveTab(id);
  };

  const tabCollapse = (id) => {
    switch (id) {
      case 1:
        setTabMorePart1(!tabMorePart1);
        break;
      case 2:
        setTabMorePart2(!tabMorePart2);
        break;
      case 3:
        setTabMorePart3(!tabMorePart3);
        break;
      case 4:
        setTabMorePart4(!tabMorePart4);
        break;
      default:
      // code block
    }
  };

  useEffect(() => {
    console.log("BİRİMLER", selCurrency);
    if (selCurrency) {
      switch (selCurrency.value) {
        case 1:
          setSelUnit("₺");
          break;
        case 2:
          setSelUnit("$");
          break;
        case 3:
          setSelUnit("€");
          break;
        case 4:
          setSelUnit("£");
          break;
        case 99:
          setSelUnit("AZN");
          break;

        default:
          break;
      }
    }
  }, [selCurrency]);

  function handleClick() {
    const searcObj = {
      tabNum: activeTab,
      contryId: selCountry ? selCountry.value : "",
      provinceId: selProvince ? selProvince.value : "",
      districtId: selDistrict ? selDistrict.value : "",
      countyId: selCounty ? selCounty.value : "",
      adtype: selAdType ? selAdType.value : "",
      currencyId: selCurrency ? selCurrency.value : "",
      minPrice: minPrice ? minPrice : "",
      maxPrice: maxPrice ? maxPrice : "",
      consultant: consultant,
      officeName: officeName,
    };
    switch (activeTab) {
      case 1:
        const advertFilter = {
          ProcessTypeId: selProcessType ? selProcessType.value : "",
          AdTypeId: selAdType ? selAdType.value : "",
          CountryId: selCountry ? selCountry.value : "",
          CityId: selProvince ? selProvince.value : "",
          CountyId: selCounty ? selCounty.value : "",
          DistrictId: selDistrict ? selDistrict.value : "",
          MinPrice: minPrice ? minPrice : "",
          MaxPrice: maxPrice ? maxPrice : "",
          MaxRoomCount: selRoomCount ? selRoomCount : "",
        };
        token && dispatch(generalActions.setShopFilters(advertFilter));
        navigate("/ev-satin-al");
        break;
      case 2:
        const teamFilter = {
          Firstname: consultant ? consultant : "",
          CityId: selProvince ? selProvince.value : "",
        };
        token && dispatch(generalActions.setTeamFilters(teamFilter));
        navigate("/danisman-bul");
        break;
      case 3:
        const officeFilter = {
          OfficeName: officeName ? officeName : "",
          CityId: selProvince ? selProvince.value : "",
        };
        token && dispatch(generalActions.setOfficeFilters(officeFilter));
        navigate("/ofis-bul");
        break;
      default:
    }
  }

  return (
    <div className="ltn__slider-area ltn__slider-4 position-relative  ltn__primary-bg">
      <div className="ltn__slide-one-active----- slick-slide-arrow-1----- slick-slide-dots-1----- arrow-white----- ltn__slide-animation-active">
        {videoContent && (
          <video autoPlay={true} muted loop id="myVideo">
            <source src={videoContent} type="video/mp4" />
          </video>
        )}

        <div
          className="ltn__slide-item ltn__slide-item-2 ltn__slide-item-7 bg-image--- bg-overlay-theme-black-30"
          data-bs-bg={publicUrl + "assets/img/slider/41.jpg"}
          style={{ position: "relative" }}
        >
          <div className="container ltn__slide-item-inner text-center">
            <div className="container home-search-part">
              <div className="row mt-10">
                <div className="col-lg-12 align-self-center">
                  <div className="slide-item-car-dealer-form">
                    <div className="ltn__car-dealer-form-tab home-search-form-1">
                      <div className="ltn__tab-menu-home  text-uppercase text-center">
                        <div
                          className="nav"
                          style={{ justifyContent: "center" }}
                        >
                          <a
                            onClick={() => tabChange(1)}
                            className="active show"
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_1"
                          >
                            {t('portfoylerimiz')}
                          </a>
                          <a
                            onClick={() => tabChange(2)}
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_2"
                            className
                          >
                            {t('danismanlarimiz-nav')}
                          </a>
                          <a
                            onClick={() => tabChange(3)}
                            data-bs-toggle="tab"
                            href="#ltn__form_tab_1_3"
                            className
                          >
                            {t('ofislerimiz-nav')}
                          </a>
                        </div>
                      </div>
                      <div className="tab-content p-4">
                        <div
                          className="tab-pane fade active"
                          id="ltn__form_tab_1_1"
                        >
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row align-item-center"
                            >
                              <div className="car-dealer-form-inner">
                                <form
                                  action="#"
                                  className="ltn__car-dealer-form-box row align-item-center"
                                >
                                  <div className="row">
                                    <div className="ltn__car-dealer-form-item pl-1 pr-1 d-flex justify-content-center align-items-center">
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t('portfoytipi')}
                                        name="advertType"
                                        options={fetchShopFilters?.AdType.map(
                                          (i) => ({
                                            label: i.Text,
                                            value: i.Value,
                                          })
                                        )}
                                        value={selAdType}
                                        onChange={(e) => {
                                          setSelAdType(e);
                                        }}
                                        styles={customStyles}
                                      />
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t('islemtipi')}
                                        name="country"
                                        options={
                                          processTypeOpt && processTypeOpt
                                        }
                                        value={selProcessType}
                                        onChange={(e) => {
                                          setSelProcessType(e);
                                        }}
                                        styles={customStyles}
                                      />
                                      <div className="btn-wrapper text-center mt-0 go-top">
                                        <a
                                          onClick={() => handleClick()}
                                          className="btn2 theme-btn-1 btn-effect-1 text-uppercase"
                                          style={{ borderRadius: "10px" }}
                                        >
                                          {t('ara')}
                                        </a>
                                      </div>
                                      <div
                                        className="btn-wrapper text-center mt-0 go-top"
                                        style={{ marginLeft: "5px" }}
                                      >
                                        <button
                                          type="button"
                                          onClick={() => tabCollapse(1)}
                                          className="home-search-more-btn btn2 p-2 theme-btn-1 text-uppercase"
                                        >
                                         {t('dahaFazla')}
                                          <i
                                            className={
                                              tabMorePart1
                                                ? "fas fa-arrow-up"
                                                : "fas fa-arrow-down"
                                            }
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>

                                  <div
                                    className={
                                      tabMorePart1
                                        ? "row mt-2"
                                        : "d-none row mt-2"
                                    }
                                    tyle={{ transition: "all 1s" }}
                                  >
                                    <div className=" ltn__car-dealer-form-item pl-1 pr-1 d-flex justify-content-center align-items-center">
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("il")}
                                        name="province"
                                        options={
                                          provinceListOpt && provinceListOpt
                                        }
                                        value={selProvince}
                                        onChange={(e) => {
                                          setSelProvince(e);
                                        }}
                                        styles={customStyles}
                                      />
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("ilçe")}
                                        name="county"
                                        options={countyListOpt && countyListOpt}
                                        value={selCounty}
                                        onChange={(e) => {
                                          setSelCounty(e);
                                        }}
                                        styles={customStyles}
                                      />
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("semt/mahalle")}
                                        name="district"
                                        options={
                                          districtListOpt && districtListOpt
                                        }
                                        value={selDistrict}
                                        onChange={(e) => {
                                          setSelDistrict(e);
                                        }}
                                        styles={customStyles}
                                      />
                                    </div>

                                    <div className="d-flex justify-content-center align-items-center">
                                      <div
                                        className=" ltn__car-dealer-form-item pl-1 pr-1"
                                        style={{ margin: "5px" }}
                                      >
                                        <input
                                          className="form-control"
                                          placeholder="min"
                                          onChange={(e) =>
                                            setMinPrice(e.target.value)
                                          }
                                          style={{ height: "45px" }}
                                          type="number"
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            right: "30px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                          }}
                                        >
                                          {selUnit}
                                        </span>
                                      </div>
                                      <div
                                        className=" ltn__car-dealer-form-item pl-1 pr-1"
                                        style={{ margin: "5px" }}
                                      >
                                        <input
                                          className="form-control"
                                          placeholder="max"
                                          onChange={(e) =>
                                            setMaxPrice(e.target.value)
                                          }
                                          style={{ height: "45px" }}
                                          type="number"
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            right: "30px",
                                            top: "50%",
                                            transform: "translateY(-50%)",
                                          }}
                                        >
                                          {selUnit}
                                        </span>
                                      </div>
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("parabirimi")}
                                        name="moneyUnit"
                                        options={
                                          currencyListOpt && currencyListOpt
                                        }
                                        value={selCurrency}
                                        onChange={(e) => {
                                          setSelCurrency(e);
                                        }}
                                        styles={customStyles}
                                      />
                                      <Select
                                        className=""
                                        classNamePrefix="select"
                                        isClearable={true}
                                        isSearchable={true}
                                        placeholder={t("odasayisi")}
                                        name="rooumCount"
                                        options={
                                          fetchShopFilters &&
                                          fetchShopFilters.HouseRoomCount.map(
                                            (i, k) => ({
                                              label: i.Text,
                                              value: i.Value,
                                            })
                                          )
                                        }
                                        value={selRoomCount}
                                        onChange={(e) => {
                                          setSelRoomCount(e);
                                        }}
                                        styles={customStyles}
                                      />
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <div className="row justify-content-start mt-1">
                                <div className="col-md-4 d-flex"></div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_2">
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item pl-1 pr-1 d-flex justify-content-center align-items-center">
                                <div
                                  className=" ltn__car-dealer-form-item pl-1 pr-1"
                                  style={{ margin: "5px" }}
                                >
                                  <input
                                    className="form-control"
                                    placeholder="Danışman Adı"
                                    onChange={(e) =>
                                      setConsultant(e.target.value)
                                    }
                                    style={{ height: "45px" }}
                                  />
                                </div>
                                <Select
                                  className=""
                                  classNamePrefix="select"
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={"Şehir"}
                                  name="province"
                                  options={provinceListOpt && provinceListOpt}
                                  value={selProvince}
                                  onChange={(e) => {
                                    setSelProvince(e);
                                  }}
                                  styles={customStyles}
                                />
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  {/* <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                                  <a
                                    onClick={() => handleClick()}
                                    className="btn2 theme-btn-1 btn-effect-1 text-uppercase"
                                  >
                                    Ara
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="tab-pane fade" id="ltn__form_tab_1_3">
                          <div className="car-dealer-form-inner">
                            <form
                              action="#"
                              className="ltn__car-dealer-form-box row"
                            >
                              <div className="ltn__car-dealer-form-item pl-1 pr-1 d-flex justify-content-center align-items-center">
                                <div
                                  className=" ltn__car-dealer-form-item pl-1 pr-1"
                                  style={{ margin: "5px" }}
                                >
                                  <input
                                    className="form-control"
                                    placeholder="Ofis Adı"
                                    onChange={(e) =>
                                      setOfficeName(e.target.value)
                                    }
                                    style={{ height: "45px" }}
                                  />
                                </div>
                                <Select
                                  className=""
                                  classNamePrefix="select"
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={"Şehir"}
                                  name="province"
                                  options={provinceListOpt && provinceListOpt}
                                  value={selProvince}
                                  onChange={(e) => {
                                    setSelProvince(e);
                                  }}
                                  styles={customStyles}
                                />
                                <div className="btn-wrapper text-center mt-0 go-top">
                                  {/* <button type="submit" className="btn theme-btn-1 btn-effect-1 text-uppercase">Search Inventory</button> */}
                                  <a
                                    onClick={() => handleClick()}
                                    className="btn2 theme-btn-1 btn-effect-1 text-uppercase"
                                  >
                                    Ara
                                  </a>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
