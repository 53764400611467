
import {userConstants} from '../constants/user.constants'

let token = JSON.parse(localStorage.getItem("token"));
const initialState = token ? { loggedIn: true, token } : {};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        token: action.token,
      };
    case userConstants.LOGIN_FAILURE:
      return {};
    default:
      return state;
  }
};

export default authReducer;
