import React, { useEffect, useMemo, useState } from "react";
import { Link, useParams } from "react-router-dom";
import parse from "html-react-parser";
import advertServices from "../../services/advert.service";
import generalServices from "../../services/general.service";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import Pagination from "../global-components/Pagination";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const ShopDetails = ({ detailInfo }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const params = useParams();
  const { t } = useTranslation(["common"]);
  const [detailInfoData, setDetailInfoData] = useState({});
  const [propDetailList, setPropDetailList] = useState([]);
  const [userDetail, setUserDetail] = useState({});
  const [photosArr, setPhotoArr] = useState([]);
  const [activeImage, setActiveImage] = useState("");
  const [imagesList, setImagesList] = useState([]);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesArr, setImagesArr] = useState([]);
  const [imageSlide1, setImageSlide1] = useState([]);
  const [imageSlide2, setImageSlide2] = useState([]);
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (
      <a className="slick-prev">
        <i className="fa fa-angle-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="slick-next">
        <i className="fa fa-angle-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: false,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumVal, setPageNumVal] = useState("");
  const [pageNumLimit, setPageNumLimit] = useState(1);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(1);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  const zip = (...arrays) =>
    [...new Array(Math.max(...arrays.map((array) => array.length)))].map(
      (_, index) => arrays.map((array) => array[index])
    );
  const chunk = (array, items) =>
    zip(...[...new Array(items)].map((_, index) => array.slice(index))).filter(
      (_, index) => index % items === 0
    );

  useEffect(() => {
    if (detailInfo && detailInfo.AdPropertySection) {
      console.log("SHOPDETAY", detailInfo);
      if (detailInfo.Latitude && detailInfo.Longitude) {
        let tempLocaObj = {
          lat: Number(detailInfo.Latitude),
          lng: Number(detailInfo.Longitude),
        };
        setCenter(tempLocaObj);
      }
      setDetailInfoData(detailInfo);
      const tempPropArr = [];

      Object.keys(detailInfo.AdPropertySection).map((i, key) => {
        const arr = [];
        const deneme = [...detailInfo.AdPropertySection[i]];
        const filtered = deneme.filter((i) => i.Item2 == true);
        let spliceAmount = filtered.length;
        if (filtered.length >= 3) {
          spliceAmount = Math.round(filtered.length / 3);
        }
        const filteredVal = [...filtered];
        while (Object.keys(filteredVal).length > 0) {
          arr.push(filteredVal.splice(0, spliceAmount));
        }
        const tempObj = {};
        if (detailInfo.AdPropertySection[i].length > 0) {
          tempObj[i] = arr;
          tempPropArr.push(tempObj);
        }
      });
      if (tempPropArr.length > 0) {
        setPropDetailList(tempPropArr);
      }
    }
    detailInfo &&
      Object.keys(detailInfo).length > 0 &&
      generalServices
        .teamMemberDetail(detailInfo?.FranchiseAdDetailUser.UserBaseId)
        .then((res) => {
          setUserDetail(res?.Result);
        });

    detailInfo && detailInfo.Photos && setPhotoArr(detailInfo.Photos);
    detailInfo &&
      detailInfo.Photos &&
      setImagesList(detailInfo.Photos.map((i) => i.Text));
    if (detailInfo.Photos) {
      const tempImages1 = chunk(detailInfo.Photos, 5);
      const tempImages2 = chunk(tempImages1, 2);
      setTotalPageCount(tempImages2.length - 1);
      setImagesArr(tempImages2);
      const selectedImage = detailInfo.Photos.find((i) => i.Selected == true);
      selectedImage
        ? setActiveImage(selectedImage.Text)
        : setActiveImage(detailInfo.Photos[0].Text);
    }
  }, [detailInfo]);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentpage - 1) * totalPageCount;
    const lastPageIndex = firstPageIndex + totalPageCount;
    return imagesArr.slice(firstPageIndex, lastPageIndex);
  }, [currentpage]);

  useEffect(() => {
    if (imagesArr.length > 0) {
      const tempArr1 = [];
      const tempArr2 = [];
      console.log('sdasşdösşdşsö', imagesArr, currentpage)
      imagesArr[currentpage - 1] && imagesArr[currentpage - 1][0] &&
        imagesArr[currentpage - 1][0].map((i) => {
          i != undefined && tempArr1.push(i);
        });
        imagesArr[currentpage] && imagesArr[currentpage][1] &&
        imagesArr[currentpage][1].map((i) => {
          i != undefined && tempArr2.push(i);
        });
      setImageSlide1(tempArr1);
      setImageSlide2(tempArr2);
    }
  }, [imagesArr, currentpage]);

  const imageClick = (item) => {
    setActiveImage(item);
  };

  const mainImageClick = () => {
    if (detailInfo && detailInfo.Photos) {
      let indexNum = 0;
      const actImg = detailInfo.Photos.map((i, k) => {
        if (i.Text === activeImage) indexNum = k;
      });
      let activePageIndex = Math.floor(indexNum / 10) + 1;
      if (activePageIndex - currentpage > 0) {
        increasePageNum();
      } else if (activePageIndex - currentpage < 0) {
        decreasePageNum();
      }
      if (indexNum == detailInfo.Photos.length - 1) {
        setActiveImage(detailInfo.Photos[0].Text);
      } else {
        setActiveImage(detailInfo.Photos[indexNum + 1].Text);
      }
    }
  };

  const containerStyle = {
    width: "100%",
    height: "100%",
  };
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfacQ1tAOSlcKRE1bgg-WRobYGyvHrprI",
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);
  const [center, setCenter] = useState({});
  const onLoad = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [center]
  );

  const onUnmount = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        setMap(null);
      }
      console.log("CENTER", center);
    },
    [center]
  );

  return (
    <div className="ltn__shop-details-area pb-10 mt-20">
      <div className="container p-0">
        <div className="row mb-10">
          <h1>{detailInfoData.Title && detailInfoData.Title}</h1>
        </div>
        <div className="row mb-20">
          <div className="col-lg-6 col-md-12">
            <div className="ltn__neighbour-tab-wrap">
              <div className="tab-content">
                <div className="tab-pane fade active show">
                  <div className="ltn__neighbour-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-12 p-0">
                        <div
                          onClick={() => mainImageClick()}
                          className="neighbour-apartments-img"
                        >
                          <img src={activeImage} alt="#" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-md-4 p-0">
                  <button
                    className="product-detail-img-btn"
                    type="button"
                    onClick={() => setIsOpen(true)}
                  >
                    <i className="icon-search for-search-show" />{" "}
                    <span>{t('buyukfotograf')}</span>
                  </button>
                </div>
                <div className="col-md-4 p-0">
                  {!detailInfoData.EmbedVideo ||
                  detailInfoData.EmbedVideo == null ? (
                    <a
                      className="product-detail-img-btn"
                      href={
                        detailInfoData.EmbedVideo && detailInfoData.EmbedVideo
                      }
                      data-rel="lightcase:myCollection"
                      style={{ pointerEvents: "none", cursor: "default" }}
                    >
                      <i className="icon-play" /> <span>Video</span>
                    </a>
                  ) : (
                    <a
                      className="product-detail-img-btn"
                      href={
                        detailInfoData.EmbedVideo && detailInfoData.EmbedVideo
                      }
                      data-rel="lightcase:myCollection"
                    >
                      <i className="icon-play" /> <span>Video</span>
                    </a>
                  )}
                </div>
                <div className="col-md-4 p-0">
                  {!detailInfoData.EmbedUrlFor360Preview ||
                  detailInfoData.EmbedUrlFor360Preview == null ? (
                    <a
                      className="product-detail-img-btn"
                      href={
                        detailInfoData.EmbedUrlFor360Preview &&
                        detailInfoData.EmbedUrlFor360Preview
                      }
                      data-rel="lightcase:myCollection"
                      style={{ pointerEvents: "none", cursor: "default" }}
                    >
                      <i className="fas fa-video" /> <span>360°</span>
                    </a>
                  ) : (
                    <a
                      className="product-detail-img-btn"
                      href={
                        detailInfoData.EmbedUrlFor360Preview &&
                        detailInfoData.EmbedUrlFor360Preview
                      }
                      // data-rel="lightcase:myCollection"
                    >
                      <i className="fas fa-video" /> <span>360°</span>
                    </a>
                  )}
                </div>
                {isOpen && (
                  <Lightbox
                    mainSrc={imagesList[photoIndex]}
                    nextSrc={imagesList[(photoIndex + 1) % imagesList.length]}
                    prevSrc={
                      imagesList[
                        (photoIndex + imagesList.length - 1) % imagesList.length
                      ]
                    }
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                      setPhotoIndex(
                        (photoIndex + imagesList.length - 1) % imagesList.length
                      )
                    }
                    onMoveNextRequest={() =>
                      setPhotoIndex((photoIndex + 1) % imagesList.length)
                    }
                  />
                )}
              </div>
              <div className="row ltn__tab-menu ltn__tab-menu-3--- ltn__tab-menu-4 ltn__tab-menu-top-right-- text-uppercase--- text-center">
                <div className="nav nav-images">
                  <div className="row p-0 mt-1">
                    {imageSlide1.length > 0 &&
                      imageSlide1.map((i, k) => (
                        <div
                          key={k}
                          onClick={() => imageClick(i.Text)}
                          className="product-detail-img ps-1 pe-0"
                        >
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={i.Text}
                            alt="#"
                          />
                        </div>
                      ))}
                  </div>
                  <div className="row mt-4 p-0">
                    {imageSlide2.length > 0 &&
                      imageSlide2.map((i, k) => (
                        <div
                          key={k}
                          onClick={() => imageClick(i.Text)}
                          className="product-detail-img ps-1 pe-0"
                        >
                          <img
                            style={{ height: "100%", width: "100%" }}
                            src={i.Text}
                            alt="#"
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {totalPageCount > 1 && (
                  <Pagination
                    paginate={paginate}
                    increasePageNum={increasePageNum}
                    decreasePageNum={decreasePageNum}
                    activepage={currentpage}
                    pageCount={totalPageCount}
                    maxPageNum={maxPageNumLimit}
                    minPageNum={minPageNumLimit}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-3 pe-0">
            <div
              className="widget ltn__search-widget p-0"
              style={{ border: "none" }}
            >
              <div
                className="ltn__widget-title-border-3 mt-2 ps-0 mb-2"
                style={{ border: "none" }}
              >
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">
                    <h3>
                      {" "}
                      {detailInfoData.Currency === "TL"
                        ? "₺"
                        : detailInfoData.Currency}{" "}
                      {detailInfoData.FormattedPrice
                        ? detailInfoData.FormattedPrice
                        : "-"}{" "}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-12">
                    {detailInfoData.City ? detailInfoData.City : "-"} /{" "}
                    {detailInfoData.County ? detailInfoData.County : "-"} /{" "}
                    {detailInfoData.District ? detailInfoData.District : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('ilanno')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdBaseId ? detailInfoData.AdBaseId : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('odasayisi')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 && detailInfoData.AdBaseId
                      ? detailInfoData.HouseRoomCountText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">m² ({t('brut')}):</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.UsageAreaGross
                      ? detailInfoData.UsageAreaGross
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">m² (Net):</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.UsageAreaClean
                      ? detailInfoData.UsageAreaClean
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('bulunduğukat')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseFloorTypeText
                      ? detailInfoData.HouseFloorTypeText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('katsayisi')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseBuildFloorTypeText
                      ? detailInfoData.HouseBuildFloorTypeText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('banyo')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseBathrommCountText
                      ? detailInfoData.HouseBathrommCountText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('tuvalet')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseWcCountText
                      ? detailInfoData.HouseWcCountText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('insaatyili')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseConstructionYear
                      ? detailInfoData.HouseConstructionYear
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('binayasi')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseBuildAgeText
                      ? detailInfoData.HouseBuildAgeText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('isinmasekli')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseFuelTypeText
                      ? detailInfoData.HouseFuelTypeText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0 ">{t('tip')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HousePropertyShapeText
                      ? detailInfoData.HousePropertyShapeText
                      : "-"}
                  </div>
                </div>
              </div>
              <div className="ltn__widget-title-border-3 mt-2 ps-0">
                <div className="ltn__widget-title mb-1 d-flex row">
                  <div className="col-md-7 pe-0">{t('yapinindurumu')}:</div>
                  <div className="col-md-5 ps-0" style={{ fontWeight: "400" }}>
                    {detailInfoData.AdTypeId == 2 &&
                    detailInfoData.HouseFullnessStatusText
                      ? detailInfoData.HouseFullnessStatusText
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-3 p-0">
            <aside className="sidebar ltn__shop-sidebar ltn__right-sidebar---">
              {/* Author Widget */}
              <div
                className="widget ltn__author-widget mb-0 p-0"
                style={{ border: "none" }}
              >
                <div className="ltn__author-widget-inner text-center">
                  <Link
                    to={
                      userDetail && userDetail.UserBaseId
                        ? `/danisman-detay/${userDetail.UserBaseId}`
                        : "#"
                    }
                  >
                    <img
                      src={
                        Object.keys(userDetail).length > 0 && userDetail.Photo
                          ? userDetail.Photo
                          : publicUrl + "assets/img/team/4.jpg"
                      }
                      alt="Image"
                    />
                    <h5>
                      {detailInfoData.FranchiseAdDetailUser &&
                        detailInfoData.FranchiseAdDetailUser.FullName}
                    </h5>
                  </Link>
                  <div className="ltn__team-details-member-about d-flex justify-content-center">
                    <table>
                      <thead>
                        <tr>
                          <th
                            colSpan={2}
                            style={{ width: "auto", textAlign: "center" }}
                          >
                            {detailInfoData.FranchiseAdDetailUser &&
                              detailInfoData.FranchiseAdDetailUser.UserRole}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {detailInfoData.FranchiseAdDetailUser && (
                          <tr>
                            <td>
                              <i
                                style={{
                                  fontWeight: "bold",
                                }}
                                className="icon-call select__indicator"
                              ></i>
                            </td>
                            <td
                              style={{ paddingLeft: "5px", textAlign: "left" }}
                            >
                              {detailInfoData.FranchiseAdDetailUser.UserPhone}
                            </td>
                          </tr>
                        )}
                        {detailInfoData.FranchiseAdDetailUser && (
                          <tr>
                            <td>
                              <i
                                style={{
                                  fontWeight: "bold",
                                }}
                                className="icon-mail select__indicator"
                              ></i>
                            </td>
                            <td
                              style={{ paddingLeft: "5px", textAlign: "left" }}
                            >
                              {detailInfoData.FranchiseAdDetailUser.Email}
                            </td>
                          </tr>
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="ltn__social-media">
                    <ul>
                      <li>
                        <a
                          href={
                            detailInfoData.FranchiseAdDetailUser &&
                            detailInfoData.FranchiseAdDetailUser.FacebookUrl
                              ? detailInfoData.FranchiseAdDetailUser.FacebookUrl
                              : "#"
                          }
                          title="Facebook"
                        >
                          <i className="fab fa-facebook-f" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            detailInfoData.FranchiseAdDetailUser &&
                            detailInfoData.FranchiseAdDetailUser.TwitterUrl
                              ? detailInfoData.FranchiseAdDetailUser.TwitterUrl
                              : "#"
                          }
                          title="Twitter"
                        >
                          <i className="fab fa-twitter" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            detailInfoData.FranchiseAdDetailUser &&
                            detailInfoData.FranchiseAdDetailUser.LinkedinUrl
                              ? detailInfoData.FranchiseAdDetailUser.LinkedinUrl
                              : "#"
                          }
                          title="Linkedin"
                        >
                          <i className="fab fa-linkedin" />
                        </a>
                      </li>
                      <li>
                        <a
                          href={
                            detailInfoData.FranchiseAdDetailUser &&
                            detailInfoData.FranchiseAdDetailUser.YoutubeUrl
                              ? detailInfoData.FranchiseAdDetailUser.YoutubeUrl
                              : "#"
                          }
                          title="Youtube"
                        >
                          <i className="fab fa-youtube" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Search Widget */}
            </aside>
          </div>
        </div>
        <div className={"ltn__apartments-plan-area pt-120--- "}>
          <div className="row">
            <div className="col-lg-12 p-0">
              <div
                className="ltn__tab-menu ltn__tab-menu-3 ltn__tab-menu-top-right-- text-uppercase--- text-left section-bg-1 p-2 mb-10"
                style={{ borderRadius: "5px" }}
              >
                <div className="nav">
                  <a
                    className="active show"
                    data-bs-toggle="tab"
                    href="#liton_tab_3_1"
                  >
                    {t('ilandetaylari')}
                  </a>
                  <a data-bs-toggle="tab" href="#liton_tab_3_2">
                    {t('konum')}
                  </a>
                  {detailInfoData.Latitude && detailInfoData.Longitude && (
                    <>
                      <a
                        href={`https://www.google.com/maps?cbll=${detailInfoData.Latitude},${detailInfoData.Longitude}&cbp=12,90,0,0,5&layer=c`}
                        target="_blank"
                      >
                        {t('sokakgorunumu')}
                      </a>
                      <a
                        href={`https://www.google.com.tr/maps/dir//${detailInfoData.Latitude},${detailInfoData.Longitude}`}
                        target="_blank"
                      >
                        {t('yoltarifi')}
                      </a>
                    </>
                  )}
                </div>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade active show" id="liton_tab_3_1">
                  <div className="ltn__apartments-tab-content-inner">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="apartments-plan-info ltn__secondary-bg--- text-color-white---">
                          {detailInfoData.DetailedDescription &&
                            parse(`${detailInfoData.DetailedDescription}`)}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade " id="liton_tab_3_2">
                  <div className="property-details-google-map mb-60">
                    {isLoaded && Object.keys(center).length > 0 && (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={9}
                        onLoad={onLoad}
                        onUnmount={onUnmount}
                      >
                        {center && (
                          <Marker
                            position={{
                              lat: center.lat,
                              lng: center.lng,
                            }}
                          />
                        )}
                      </GoogleMap>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          {Object.keys(propDetailList).length > 0 &&
            Object.keys(propDetailList).map((i, key) => {
              return (
                <>
                  <h4 className="title-2 mb-10">
                    {Object.keys(propDetailList[i])[0]}
                  </h4>
                  {Object.values(propDetailList[i]).map((x, k) => {
                    return (
                      <div className="property-details-amenities mb-60">
                        <div className="row">
                          {x.map((t, l) => {
                            return (
                              <div className="col-lg-4 col-md-6">
                                <div className="ltn__menu-widget">
                                  {t.map((n, m) => {
                                    return (
                                      <ul>
                                        <li>
                                          <label className="checkbox-item">
                                            {n.Item1}
                                            <input
                                              type="checkbox"
                                              defaultChecked="checked"
                                              disabled
                                            />
                                            <span className="checkmark" />
                                          </label>
                                        </li>
                                      </ul>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          })}
                          <hr
                            className="mt-4 select__indicator"
                            style={{ height: "3px" }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ShopDetails;
