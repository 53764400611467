import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useSelector } from "react-redux";
import Aos from "aos";
import "aos/dist/aos.css";

const Category = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const token = useSelector((state) => state);
  const categoryList = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.allPagesByTypeReducer.allPagesByType
  );
  const [categoryContentList, setCategoryContentList] = useState("");
  const [categoryContent, setCategoryContent] = useState([]);

  useEffect(() => {
    categoryContentList && Aos.init({ duration: 1000 });
  }, [categoryContentList]);

  useEffect(() => {
    if (fetchPageContents && Object.keys(fetchPageContents).length > 0) {
      console.log("HATADAYIz", fetchPageContents);
      const categoryPartContent1 = fetchPageContents.filter(
        (i) => i.PageOrder == 13
      );
      const categoryPartContent2 = fetchPageContents.filter(
        (i) => i.PageOrder == 14
      );
      const categoryPartContent3 = fetchPageContents.filter(
        (i) => i.PageOrder == 15
      );
      const categoryPartContent4 = fetchPageContents.filter(
        (i) => i.PageOrder == 16
      );
      const tempArr = [];
      if (categoryPartContent1) {
        tempArr.push(categoryPartContent1[0]);
      }
      if (categoryPartContent2) {
        tempArr.push(categoryPartContent2[0]);
      }
      if (categoryPartContent3) {
        tempArr.push(categoryPartContent3[0]);
      }
      if (categoryPartContent4) {
        tempArr.push(categoryPartContent4[0]);
      }
      console.log('dadsad', tempArr)
      setCategoryContent(tempArr);
    }
  }, [fetchPageContents]);

  useEffect(() => {
    categoryContent.length > 0 && setCategoryContentList(categoryContent);
    console.log("UZUNLUK", categoryContent.length);
  }, [categoryContent]);

  return (
    <div className="ltn__banner-area pt-20 pb-0 go-top">
      <div className="container">
        <div className="row">
          {categoryContentList &&
            categoryContentList.map((i, k) => (
              <div
                key={k}
                data-aos={k % 2 == 0 ? "fade-right" : "fade-left"}
                className="col-md-6 pb-4"
              >
                <Link to={`/${i.PageLinks[0].Title}`}>
                  <img src={i.ImageUrl}></img>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
