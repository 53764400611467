import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import homePageServices from "../../services/homepage.service";
import Slider from "react-slick";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const BlogSlider = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  let customClass = props.customClass ? props.customClass : "";
  let sectionClass = props.sectionClass ? props.sectionClass : "";
  const [blogList, setBlogList] = useState([]);
  const token = useSelector((state) => state);

  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <a className="slick-prev">
        <i className="fa fa-angle-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="slick-next">
        <i className="fa fa-angle-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    token && homePageServices.getBlogsList().then((res) => {
      setBlogList(res?.Result.Items);
    });
  }, []);

  return (
    <div className={"ltn__blog-area pb-0 go-top " + sectionClass}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12 p-0">
            <div
              className="section-title-area ltn__section-title-2--- text-center home-posts__header d-flex justify-content-center mb-0"
              style={{
                backgroundImage: `url(${
                  publicUrl + "assets/img/logo-green.png"
                })`,
              }}
            >
              <img
                className="home-posts__bhgre-logo"
                src={publicUrl + "assets/img/brand-logo.png"}
                alt="THE BHGRE life"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="container pt-20">
        <div className="row slick-arrow-1 ltn__blog-item-3-normal">
          <Slider {...settings}>
            {blogList?.length > 0 &&
              blogList.map((i, key) => {
                return (
                  <div className="col-lg-12">
                    <div className="ltn__blog-item ltn__blog-item-3 mb-3">
                      <div
                        className="ltn__blog-img"
                        style={{
                          overflow: "hidden",
                          height: 0,
                          paddingTop: "56.25%",
                          position: "relative",
                        }}
                      >
                        <Link to={`/blog-detay/${i.Id}`}>
                          <img
                            style={{
                              position: "absolute",
                              top: 0,
                              left: 0,
                              width: "100%",
                              height: "100%",
                            }}
                            src={
                              i.Image
                                ? i.Image
                                : publicUrl + "assets/img/blog/1.jpg"
                            }
                            alt="#"
                          />
                        </Link>
                      </div>
                      <div className="ltn__blog-brief">
                        <div className="ltn__blog-meta">
                          <ul>
                            <li className="ltn__blog-author">
                              <Link to="#">
                                <i className="far fa-user" />
                                BetterHomes
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div style={{ height:'100px', overflow: "hidden", textOverflow: "ellipsis",}}>
                          <h3
                            className="ltn__blog-title"
                            // style={{
                            //   whiteSpace: "nowrap",
                            //   width: "calc(100% - 20px + 5px)",
                            //   overflow: "hidden",
                            //   textOverflow: "ellipsis",
                            // }}
                          >
                            <Link to={`/blog-detay/${i.Id}`}>
                              {i.Title && i.Title}
                            </Link>
                          </h3>
                        </div>

                        <div className="ltn__blog-meta-btn">
                          <div className="ltn__blog-meta">
                            <ul>
                              <li className="ltn__blog-date">
                                <i className="far fa-calendar-alt" />
                                {i.CreatedDate && i.CreatedDate}
                              </li>
                            </ul>
                          </div>
                          <div className="ltn__blog-btn">
                            <Link to={`/blog-detay/${i.Id}`}>{t("dahaFazla")}</Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </Slider>
          {/* Blog Item */}
          {/*  */}
        </div>
      </div>
      <div className="container mb-3">
        <div className="row">
          <div className="col-lg-12 d-flex justify-content-center">
            <Link
              className="btn theme-btn-1 btn-effect-1 text-uppercase "
              to="/bloglar"
            >
              {t("digerbloklarimizicintiklayiniz")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogSlider;
