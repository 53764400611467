import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import advertServices from "../../services/advert.service";
import Slider from "react-slick";
import Pagination from "../global-components/Pagination";
import { useTranslation } from "react-i18next";

const ProductTeamSlider = ({ params }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumVal, setPageNumVal] = useState("");
  const [pageNumLimit, setPageNumLimit] = useState(9);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  const [advertList, setAdvertList] = useState([]);
  useEffect(() => {
    params &&
      advertServices
        .advertList({
          UserId: params.id,
          PageIndex: currentpage,
          PageSize: pageNumLimit,
        })
        .then((res) => {
          res && setAdvertList(res?.Result.Items);
        });
  }, [currentpage]);

  return (
    <div>
      <div className="ltn__product-area ltn__product-gutter mb-10">
        <div className="container">
          {advertList.length > 0 && (
            <div className="row">
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {advertList?.map((i, key) => {
                        return (
                          <div key={key} className="col-xl-4 col-sm-4 col-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                              <div
                                className="product-img go-top"
                                style={{
                                  overflow: "hidden",
                                  height: 0,
                                  paddingTop: "56.25%",
                                  position: "relative",
                                }}
                              >
                                <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                  <img
                                    className=""
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={
                                      i.OriginalImage
                                        ? i.OriginalImage
                                        : publicUrl +
                                          "assets/img/product-3/1.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badge bg-green">
                                      {i.EstateStatusType}
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-img-location-gallery">
                                  <div className="product-img-location">
                                    <ul>
                                      <li>
                                        <i className="flaticon-pin" />{" "}
                                        {i.County + "," + i.City}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="product-img-gallery go-top">
                                    <ul>
                                      <li>
                                        <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                          <i className="fas fa-camera" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                          <i className="fas fa-film" />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="product-info">
                                <div className="product-price">
                                  <span>
                                    {i.Currency === "TL" ? "₺" : i.Currency}{" "}
                                    {i.FormattedPrice}
                                  </span>
                                </div>
                                <h2
                                  className="product-title go-top"
                                  style={{
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 20px + 5px)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                    {i.Title ? i.Title : "-"}
                                  </Link>
                                </h2>
                                {i.AdType == 2 ? (
                                  <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                                    <li>
                                      <span>
                                        3 <i className="flaticon-bed" />
                                      </span>
                                      {t('yatakodasi')}
                                    </li>
                                    <li>
                                      <span>
                                        {i.HouseBathroomCount
                                          ? i.HouseBathroomCount
                                          : "-"}{" "}
                                        <i className="flaticon-clean" />
                                      </span>
                                      {t('banyo')}
                                    </li>
                                    <li>
                                      <span>
                                        {i.UsageAreaGross
                                          ? i.UsageAreaGross
                                          : "-"}{" "}
                                        <i className="flaticon-square-shape-design-interface-tool-symbol" />
                                      </span>
                                      m² {t('brut')}
                                    </li>
                                  </ul>
                                ) : (
                                  <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                                    {i.UsageAreaGross && (
                                      <li className="mt-0">
                                        <span>
                                          {i.UsageAreaGross} m² {t('brut')}
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </div>
                              <div className="product-info-bottom">
                                <div className="real-estate-agent">
                                  <div className="agent-img go-top">
                                    <Link
                                      to={`/danisman-detay/${i.ConsultantId}`}
                                    >
                                      <img
                                        src={
                                          i.ConsultantPhoto
                                            ? i.ConsultantPhoto
                                            : publicUrl +
                                              "assets/img/blog/author.jpg"
                                        }
                                        alt="#"
                                      />
                                    </Link>
                                  </div>
                                  <div className="agent-brief go-top">
                                    <h6>
                                      <Link
                                        to={`/danisman-detay/${i.ConsultantId}`}
                                      >
                                        {i.ConsultantFullName}
                                      </Link>
                                    </h6>
                                    <small>{i.ConsultantTitle}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              {totalPageCount > 1 && (
                <Pagination
                  paginate={paginate}
                  increasePageNum={increasePageNum}
                  decreasePageNum={decreasePageNum}
                  activepage={currentpage}
                  pageCount={totalPageCount}
                  maxPageNum={maxPageNumLimit}
                  minPageNum={minPageNumLimit}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductTeamSlider;
