import React, { useEffect, useState } from "react";
import PageHeader from "./global-components/Page_header";
import parse from "html-react-parser";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";
import NavbarV2 from "./global-components/Navbar-v2";

const Gen_Content = () => {
  let location = useLocation();
  const genPageContents = useSelector(
    (state) => state.generalListsReducer.allPagesByTypeReducer.allPagesByType
  );
  const [actUrlObj, setActUrlObj] = useState({});
  useEffect(() => {
    if (Object.keys(genPageContents).length > 0) {
      const selectedContentObj = genPageContents.find(
        (el) => `/${el.PageLinks[0].Title}` == location.pathname
      );
      Object.keys(selectedContentObj).length > 0 &&
        setActUrlObj(selectedContentObj);
    }
  }, [location]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);

  return (
    <div>
      <NavbarV2 />
      {/* <PageHeader
        headertitle={
          Object.keys(actUrlObj).length > 0 && actUrlObj.Titles[0].Title
        }
        customclass="mb-0"
      /> */}
      <div className="ltn__team-details-area mb-120 mt-20">
        <div className="container">
          <div className="row">
            {Object.keys(actUrlObj).length > 0 &&
              actUrlObj.Bodies[0].Title &&
              parse(actUrlObj.Bodies[0].Title)}
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Gen_Content;
