export const generalConstants = {
  GET_COUNTRY: "ALL_COUNTRY_LIST",
  GET_PROVINCE: "ALL_PROVINCE_LIST",
  GET_DISTRICT: "ALL_DISTRICT_LIST",

  GET_TEAM_MEMBERS: "GET_TEAM_MEMBERS",

  GET_CONTENTS: "GET_PAGE_CONTENTS",
  GET_CONTENT_PAGES: "GET_CONTENT_PAGES",
  GET_PAGE_TYPES: "GET_PAGE_CONTENTS_BY_TYPE",

  GET_COMP_SETT: 'GET_COMPANY_SETTINGS',

  GET_FILTERS: "GET_SHOP_FILTER_VALUES",

  SET_FILTERS: "SET_SELECTED_SHOP_FILTER_VALUES",

  SET_OFFICE_FILTERS: "SET_SELECTED_OFFICE_FILTER_VALUES",

  SET_TEAM_FILTERS: "SET_SELECTED_TEAM_FILTER_VALUES",

  SET_LANG: "SET_SYSTEM_SELECTED_LANGUAGE",
};
