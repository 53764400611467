import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "../global-components/Pagination";

const OfficeTeam = ({ params }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  const [brokerList, setBrokerList] = useState([]);
  const [managerList, setManagerList] = useState([]);
  const [consultantList, setConsultantList] = useState([]);
  const [assistantList, setAssistantList] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumLimit] = useState(12);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);

  useEffect(() => {
    if (params && params.length > 0) {
      console.log("Team geldi", params);
      const brokerTempArr = [];
      const managerTempArr = [];
      const consultantTempArr = [];
      const assistantTempArr = [];
      params.map((i) => {
        switch (i.RoleId) {
          case 543:
            brokerTempArr.push(i);
            break;
          case 544:
            managerTempArr.push(i);
            break;
          case 545:
            consultantTempArr.push(i);
            break;
          case 546:
            assistantTempArr.push(i);
            break;
          default:
            consultantTempArr.push(i);
            break;
        }
      });

      
      (brokerTempArr.length > 0) && brokerTempArr.sort((a,b)=> (a.FullName > b.FullName ? 1 : -1));
      (managerTempArr.length > 0) && managerTempArr.sort((a,b)=> (a.FullName > b.FullName ? 1 : -1));
      (consultantTempArr.length > 0) && consultantTempArr.sort((a,b)=> (a.FullName > b.FullName ? 1 : -1));
      (assistantTempArr.length > 0) && assistantTempArr.sort((a,b)=> (a.FullName > b.FullName ? 1 : -1));
      console.log('sdasdsd', brokerTempArr)
      setBrokerList(brokerTempArr);
      setManagerList(managerTempArr);
      setConsultantList(consultantTempArr);
      setAssistantList(assistantTempArr);
    }
  }, [params]);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  return (
    <div className="ltn__team-area pt-20---">
      <div className="container">
        <div className="row justify-content-start go-top">
          {brokerList.length > 0 &&
            brokerList.map((i, key) => {
              return (
                <div key={key} className="col-md-3 col-sm-6">
                  <div className="ltn__team-item ltn__team-item-3---">
                    <div className="team-img">
                      <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                        {" "}
                        <img
                          src={
                            i.Photo
                              ? i.Photo
                              : publicUrl + "assets/img/team/4.jpg"
                          }
                          alt="Image"
                        />
                      </Link>
                    </div>
                    <div className="team-info">
                      <h4 style={{ marginBottom: "0.5rem" }}>
                        <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                          {i.FullName}
                        </Link>
                      </h4>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="select__indicator"
                      >
                        {i.UserTitle}
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        <Link to={`/ofis-detay/${i.OfficeId}`}>
                          {i.OfficeName}
                        </Link>
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        {i.Phone}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row justify-content-start go-top">
          {managerList.length > 0 &&
            managerList.map((i, key) => {
              return (
                <div key={key} className="col-md-3 col-sm-6">
                  <div className="ltn__team-item ltn__team-item-3---">
                    <div className="team-img">
                      <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                        {" "}
                        <img
                          src={
                            i.Photo
                              ? i.Photo
                              : publicUrl + "assets/img/team/4.jpg"
                          }
                          alt="Image"
                        />
                      </Link>
                    </div>
                    <div className="team-info">
                      <h4 style={{ marginBottom: "0.5rem" }}>
                        <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                          {i.FullName}
                        </Link>
                      </h4>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="select__indicator"
                      >
                        {i.UserTitle}
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        <Link to={`/ofis-detay/${i.OfficeId}`}>
                          {i.OfficeName}
                        </Link>
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        {i.Phone}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row justify-content-start go-top">
          {consultantList.length > 0 &&
            consultantList.map((i, key) => {
              return (
                <div key={key} className="col-md-3 col-sm-6">
                  <div className="ltn__team-item ltn__team-item-3---">
                    <div className="team-img">
                      <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                        {" "}
                        <img
                          src={
                            i.Photo
                              ? i.Photo
                              : publicUrl + "assets/img/team/4.jpg"
                          }
                          alt="Image"
                        />
                      </Link>
                    </div>
                    <div className="team-info">
                      <h4 style={{ marginBottom: "0.5rem" }}>
                        <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                          {i.FullName}
                        </Link>
                      </h4>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="select__indicator"
                      >
                        {i.UserTitle}
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        <Link to={`/ofis-detay/${i.OfficeId}`}>
                          {i.OfficeName}
                        </Link>
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        {i.Phone}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="row justify-content-start go-top">
          {assistantList.length > 0 &&
            assistantList.map((i, key) => {
              return (
                <div key={key} className="col-md-3 col-sm-6">
                  <div className="ltn__team-item ltn__team-item-3---">
                    <div className="team-img">
                      <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                        {" "}
                        <img
                          src={
                            i.Photo
                              ? i.Photo
                              : publicUrl + "assets/img/team/4.jpg"
                          }
                          alt="Image"
                        />
                      </Link>
                    </div>
                    <div className="team-info">
                      <h4 style={{ marginBottom: "0.5rem" }}>
                        <Link to={`/danisman-detay/${i.FullName}/${i.Id}`}>
                          {i.FullName}
                        </Link>
                      </h4>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="select__indicator"
                      >
                        {i.UserTitle}
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        <Link to={`/ofis-detay/${i.OfficeId}`}>
                          {i.OfficeName}
                        </Link>
                      </h6>
                      <h6
                        style={{ marginBottom: "0.5rem" }}
                        className="page_title"
                      >
                        {i.Phone}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
          {totalPageCount > 1 && (
            <Pagination
              paginate={paginate}
              increasePageNum={increasePageNum}
              decreasePageNum={decreasePageNum}
              activepage={currentpage}
              pageCount={totalPageCount}
              maxPageNum={maxPageNumLimit}
              minPageNum={minPageNumLimit}
            />
          )}
      </div>
    </div>
  );
};

export default OfficeTeam;
