import axios from "./axiosInstance";

const userContact = async (data) => {
  let res = await axios({
    method: "POST",
    url: `user/contact`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const appealConsultant = async (data) => {
  let res = await axios({
    method: "POST",
    url: `appeal/consultant`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const appealFranchise = async (data) => {
  let res = await axios({
    method: "POST",
    url: `appeal/franchisecustomer`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const brandContact = async (data) => {
  let res = await axios({
    method: "POST",
    url: `brand/createEmail`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const officeContact = async (data) => {
  let res = await axios({
    method: "POST",
    url: `office/contact`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const addSubscribe = async (data) => {
  let res = await axios({
    method: "POST",
    url: `subscribe/addsubscribe`,
    data: data
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const formServices = {
  userContact,
  appealConsultant,
  brandContact,
  addSubscribe,
  officeContact,
  appealFranchise
};

export default formServices;
