import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ContactForm from "./ContactForm";
import ContactForm2 from "./ContactForm2";
import ContactForm3 from "./ContactForm3";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";

const Aboutus = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const [aboutContent, setAboutContent] = useState({});

  const fetchContentPages = useSelector(
    (state) => state.generalListsReducer.contentPagesReducer.contentPages
  );
  const fetchSystemLang = useSelector(
    (state) => state.generalListsReducer.systemLanguageReducer.selLang
  );

  useEffect(() => {
    const tempContent = fetchContentPages.find(
      (i) => i.PageOrder == 1 && i.PageType == 1
    );

    tempContent &&
      Object.keys(tempContent).length > 0 &&
      setAboutContent(tempContent);
  }, [fetchContentPages]);

  return (
    <div className="ltn__page-details-area ltn__service-details-area mb-120 mt-20">
      <div className="container-fluid">
        <div className="row">
          {Object.keys(aboutContent).length > 0 && (
            <>
              <div className="col-lg-8">
                {aboutContent.Bodies &&
                aboutContent.Bodies.findIndex(
                  (o) => o.LanguageType == fetchSystemLang
                ) &&
                aboutContent.Bodies[
                  aboutContent.Bodies.findIndex(
                    (o) => o.LanguageType == fetchSystemLang
                  )
                ] &&
                aboutContent.Bodies[
                  aboutContent.Bodies.findIndex(
                    (o) => o.LanguageType == fetchSystemLang
                  )
                ].Title != null
                  ? parse(
                      aboutContent.Bodies[
                        aboutContent.Bodies.findIndex(
                          (o) => o.LanguageType == fetchSystemLang
                        )
                      ].Title
                    )
                  : ""}
              </div>
              <div className="col-lg-4 align-self-center sell-home-page-img">
                <div className="about-us-img-wrap about-img-right">
                  {Object.keys(aboutContent).length > 0 &&
                    aboutContent.ImageUrl && (
                      <img src={aboutContent.ImageUrl} alt="About Us Image" />
                    )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Aboutus;
