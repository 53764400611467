import React from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const CallToActon = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";
  const { t } = useTranslation(["common"]);

  return (
    <div className="ltn__call-to-action-area call-to-action-6 before-bg-bottom" >
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="call-to-action-inner call-to-action-inner-6  position-relative text-center--- ps-5 pe-5 pt-3 pb-3" style={{ backgroundColor:'#64A646' }}>
              <div className="coll-to-info text-color-white">
                <h1>{t("ailemizekatilin")}</h1>
                <p>{t('yatiriminizibirliktedahabuyukkazanclaracevirelim')}</p>
              </div>
              <div className="btn-wrapper go-top">
                <Link className="btn btn-effect-3 btn-white" to="/ailemize-katil">
                {t('franchiseolmakistiyorum')}<i className="icon-next" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToActon;
