import { generalConstants } from "../constants/general.constants";
import { combineReducers } from "redux";

const countryReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_COUNTRY:
      return { ...state, countryList: action.countryList };
    default:
      return state;
  }
};

const provinceReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_PROVINCE:
      return { ...state, provinceList: action.provinceList };
    default:
      return state;
  }
};

const teamMembersReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_TEAM_MEMBERS:
      return { ...state, teamMemberList: action.teamMemberList };
    default:
      return state;
  }
};

const pageContentsReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_CONTENTS:
      return { ...state, pageContents: action.pageContents };
    default:
      return state;
  }
};

const contentPagesReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_CONTENT_PAGES:
      return { ...state, contentPages: action.contentPages };
    default:
      return state;
  }
};

const allPagesByTypeReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_PAGE_TYPES:
      return { ...state, allPagesByType: action.allPagesByType };
    default:
      return state;
  }
};

const shopFiltersReducer = (state = [], action) => {
  switch (action.type) {
    case generalConstants.GET_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};

const setShopFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case generalConstants.SET_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};

const setOfficeFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case generalConstants.SET_OFFICE_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};

const setTeamFiltersReducer = (state = {}, action) => {
  switch (action.type) {
    case generalConstants.SET_TEAM_FILTERS:
      return { ...state, filters: action.filters };
    default:
      return state;
  }
};

const companySettingsReducer = (state = {}, action) => {
  switch (action.type) {
    case generalConstants.GET_COMP_SETT:
      return { ...state, compSettings: action.settings };
    default:
      return state;
  }
};

const systemLanguageReducer = (state = 11, action) => {
  switch (action.type) {
    case generalConstants.SET_LANG:
      return { ...state, selLang: action.filters };
    default:
      return state;
  }
};

export const generalListsReducer = combineReducers({
  countryReducer,
  provinceReducer,
  teamMembersReducer,
  pageContentsReducer,
  contentPagesReducer,
  shopFiltersReducer,
  allPagesByTypeReducer,
  setShopFiltersReducer,
  setOfficeFiltersReducer,
  setTeamFiltersReducer,
  companySettingsReducer,
  systemLanguageReducer
});
