import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import advertServices from "../../services/advert.service";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const ProductSlider = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <a className="slick-prev">
        <i className="fa fa-angle-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="slick-next">
        <i className="fa fa-angle-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [proSliderItems, setProSliderItems] = useState([]);
  useEffect(() => {
    advertServices.advertList({ IsOpportunity: true }).then((res) => {
      setProSliderItems(res?.Result.Items);
    });
  }, []);

  return (
    <div>
      <div className="ltn__product-slider-area ltn__product-gutter pt-20 pb-0">
        <div className="container">
          {/* ltn__product-item */}
          <Slider {...settings} className="row slick-arrow-1">
            {proSliderItems?.map((i, key) => {
              return (
                <div key={key} className="col-xl-4 col-sm-6 col-12">
                  <div className="ltn__product-item ltn__product-item-4 text-center--- mb-0">
                    <div
                      className="product-img go-top"
                      style={{
                        overflow: "hidden",
                        height: 0,
                        paddingTop: "56.25%",
                        position: "relative",
                      }}
                    >
                      <Link to={`/ilan-detay/${i.AdBaseId}`}>
                        <img
                          className=""
                          style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                          }}
                          src={
                            i.OriginalImage
                              ? i.OriginalImage
                              : publicUrl + "assets/img/product-3/1.jpg"
                          }
                          alt="#"
                        />
                      </Link>
                      <div className="product-badge">
                        <ul>
                          <li className="sale-badge bg-green">
                            {i.EstateStatusType}
                          </li>
                        </ul>
                      </div>
                      <div className="product-img-location-gallery">
                        <div className="product-img-location">
                          <ul>
                            <li>
                              <i className="flaticon-pin" />{" "}
                              {i.County + "," + i.City}
                            </li>
                          </ul>
                        </div>
                        <div className="product-img-gallery go-top">
                          <ul>
                            <li>
                              <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                <i className="fas fa-camera" />
                              </Link>
                            </li>
                            <li>
                              <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                <i className="fas fa-film" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="product-info">
                      <div className="product-price">
                        <span>
                          {i.Currency === "TL" ? "₺" : i.Currency}{" "}
                          {i.FormattedPrice}
                        </span>
                      </div>
                      <h2
                        className="product-title go-top"
                        style={{
                          whiteSpace: "nowrap",
                          width: "calc(100% - 20px + 5px)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        <Link to={`/ilan-detay/${i.AdBaseId}`}>
                          {i.Title ? i.Title : "-"}
                        </Link>
                      </h2>
                      {i.AdType == 2 ? (
                        <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                          <li>
                            <span>
                              3 <i className="flaticon-bed" />
                            </span>
                            {t('yatakodasi')}
                          </li>
                          <li>
                            <span>
                              {i.HouseBathroomCount
                                ? i.HouseBathroomCount
                                : "-"}{" "}
                              <i className="flaticon-clean" />
                            </span>
                            {t('banyo')}
                          </li>
                          {i.UsageAreaGross && (
                            <li>
                              <span>{i.UsageAreaGross} m² {t('brut')}</span>
                            </li>
                          )}
                        </ul>
                      ) : (
                        <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                          {i.UsageAreaGross && (
                            <li className="mt-0">
                              <span>{i.UsageAreaGross} m² {t('brut')}</span>
                            </li>
                          )}
                        </ul>
                      )}
                    </div>
                    <div className="product-info-bottom">
                      <div className="real-estate-agent">
                        <div className="agent-img go-top">
                          <Link to={`/danisman-detay/${i.ConsultantId}`}>
                            <img
                              src={
                                i.ConsultantPhoto
                                  ? i.ConsultantPhoto
                                  : publicUrl + "assets/img/blog/author.jpg"
                              }
                              alt="#"
                            />
                          </Link>
                        </div>
                        <div className="agent-brief go-top">
                          <h6>
                            <Link to={`/danisman-detay/${i.ConsultantId}`}>
                              {i.ConsultantFullName}
                            </Link>
                          </h6>
                          <small>{i.ConsultantTitle}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

    </div>
  );
};

export default ProductSlider;
