import React, { useEffect, useState } from "react";
import Navbar from "./global-components/Navbar";
import NavbarV2 from "./global-components/Navbar-v2";
import Banner from "./section-components/Banner";
import ProSlider from "./section-components/ProductSlider";
import BlogSlider from "./blog-components/blog-slider";
import NewsSlider from "./news-components/news-slider";
import Gallery from "./section-components/Gallery";

import Testimonial from "./section-components/Testimonial";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import Category from "./Category";
import VideoGallery from "./section-components/VideoGallery ";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";


const Home = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(generalActions.setShopFilters(''))
    dispatch(generalActions.setTeamFilters(''))
    dispatch(generalActions.setOfficeFilters(''))
  }, [])


  return (
    <div>
      {/* <Navbar CustomClass="ltn__header-transparent gradient-color-2" /> */}
      <NavbarV2 />
      <Banner />
      <Category />
      <VideoGallery />
      <ProSlider />
      <BlogSlider customClass="section-subtitle-2" />
      {/* <NewsSlider /> */}
      <Testimonial />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Home;
