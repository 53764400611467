import React, { Component, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import homePageServices from "../../services/homepage.service";
import parse from "html-react-parser";

const BlogDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let imagealt = "image";

  const params = useParams();
  const [blogdetailData, setBlogdetailData] = useState({});
  useEffect(() => {
    homePageServices.getBlogDetail(params?.id).then((res) => {
      res && setBlogdetailData(res.Result);
    });
  }, []);

  return (
    <div className="ltn__page-details-area ltn__blog-details-area mb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="ltn__blog-details-wrap">
              <div className="ltn__page-details-inner ltn__blog-details-inner">
                <h2 className="ltn__blog-title">
                  {blogdetailData && blogdetailData.Title}
                </h2>
                <div className="ltn__blog-meta">
                  <ul>
                    <li className="ltn__blog-author go-top">
                      <Link to="/team-details">
                        <img
                          src={
                            blogdetailData.UserPhoto != null
                              ? blogdetailData.UserPhoto
                              : publicUrl + "assets/img/blog/author.jpg"
                          }
                          alt="#"
                        />
                        By:{" "}
                        {blogdetailData.UserFullname != null
                          ? blogdetailData.UserFullname
                          : "-"}
                      </Link>
                    </li>
                    <li className="ltn__blog-date">
                      <i className="far fa-calendar-alt" />
                      {blogdetailData.CreatedDate != null
                        ? blogdetailData.CreatedDate
                        : "-"}
                    </li>
                    {/* <li>
                      <Link to="#">
                        <i className="far fa-comments" />
                        35 Comments
                      </Link>
                    </li> */}
                  </ul>
                </div>
                {blogdetailData.Text != null ? parse(blogdetailData.Text) : ""}
                <div className="row">
                  <img
                    src={
                      blogdetailData.Image
                        ? blogdetailData.Image
                        : publicUrl + "assets/img/blog/35.jpg"
                    }
                    alt="Image"
                  />
                </div>
              </div>
              <hr />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
