import React, { Component, useEffect, useRef, useState } from "react";
import { Link, useParams } from "react-router-dom";
import generalServices from "../../services/general.service";
import formServices from "../../services/form.service";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import ProductTeamSlider from "./ProductTeamSlider";
import { useSelector } from "react-redux";
import parse from "html-react-parser";
import Pagination from "../global-components/Pagination";
import TeamDetailBanner from "./TeamDetailBanner";
import { useTranslation } from "react-i18next";

const TeamDetails = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const params = useParams();
  const { t } = useTranslation(["common"]);
  const [detailInfo, setDetailInfo] = useState({});
  const [userTitleList, setUserTitleList] = useState({});
  const [userContactMessage, setUserContactMessage] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [kvkkText, setKvkkText] = useState("");
  const [comments, setComments] = useState([]);
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;

  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );

  useEffect(() => {
    generalServices.teamMemberDetail(params?.id).then((res) => {
      res && setDetailInfo(res?.Result);
      console.log("USERRR**********", res.Result);
    });
    generalServices.getUserTitleList().then((res) => {
      res && setUserTitleList(res?.Result);
    });
    generalServices.teamMemberComments(params?.id).then((res) => {
      res.Result.TotalPageCount && setTotalPageCount(res.Result.TotalPageCount);
      if (res && res.Result && res.Result.Items) {
        const tempCom = [];
        res.Result.Items.map((i) => {
          let halfStarCheck = Number.isInteger(i.Star) ? false : true;
          let fullStarAmount = Math.floor(i.Star);
          let tempObj = {
            writer: i.Firstname + i.Lastname,
            date: new Date(i.CreatedDate),
            fullStar: fullStarAmount,
            halfStar: halfStarCheck,
            text: i.Comment,
          };
          tempCom.push(tempObj);
        });
        setComments(tempCom);
      }
    });
  }, []);

  const fullStarHtml = (length) => {
    let starText = "";
    for (let i = 0; i < length; i++) {
      starText += ` <li>
      <a href="#">
        <i className="fas fa-star" />
      </a>
    </li>`;
    }
    return starText;
  };

  useEffect(() => {
    if (fetchPageContents != null) {
      const textInfo = fetchPageContents.find(
        (i) => i.WidgetKey === "11223344"
      );
      textInfo && setKvkkText(textInfo.WidgetDescription[0].Title);
    }
  }, [fetchPageContents]);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      phone: "",
      message: "",
      recaptcha: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(t("bualanidoldurmakzorunlu")),
      email: Yup.string()
        .email(t("gecersizeposta"))
        .required(t("bualanidoldurmakzorunlu")),
      phone: Yup.string()
        .matches(phoneRegex, t("gecersiztelefonformati"))
        .required(t("bualanidoldurmakzorunlu")),
      acceptTerms: Yup.bool().oneOf([true], t("lutfenkvkkmetniniokuyunuz")),
      recaptcha: Yup.string().required(t("recaptchazorunlu")),
    }),
    onSubmit(values) {
      const formData = {
        Id: params.id,
        FullName: values.fullName,
        Phone: values.phone,
        Email: values.email,
        Message: values.message,
      };

      formServices.userContact(formData).then((res) => {
        res.IsSucceeded && setUserContactMessage(true);
      });
    },
  });

  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumVal, setPageNumVal] = useState("");
  const [pageNumLimit, setPageNumLimit] = useState(9);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  return (
    <>
      {Object.keys(detailInfo).length > 0 && (
        <TeamDetailBanner
          detailInfo={detailInfo}
          userTitleList={userTitleList}
        />
      )}
      <div className="ltn__team-details-area mb-120">
        <div className="container-fluid">
          <div className="row select__indicator-separator-black">
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 1
                    ? "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(1)}
              >
                {t("portfoyum")}
              </button>
            </div>
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 2
                    ? "btn  w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(2)}
              >
                {t("hakkimda")}
              </button>
            </div>
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 3
                    ? "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(3)}
              >
                {t("egitimlerim")}
              </button>
            </div>
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 4
                    ? "btn  w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0  pt-4 b-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(4)}
              >
                {t("gecmiscalismavebasarilarim")}
              </button>
            </div>
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 5
                    ? "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0 pt-4 pb-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(5)}
              >
                {t("uzmanlikbolgem")}
              </button>
            </div>
            <div className="col-md-2 p-0">
              <button
                className={
                  activeTab == 6
                    ? "btn  w-100 ps-0 pe-0 pt-4 pb-4 text-white brdrBttm text-uppercase"
                    : "btn w-100 ps-0 pe-0  pt-4 b-4 text-white text-uppercase"
                }
                style={{ fontSize: "18px", fontWeight: "600" }}
                onClick={() => setActiveTab(6)}
              >
                {t("gayrimenkultiplerim")}
              </button>
            </div>
          </div>
          <div className="row">
            <div className="ltn__team-details-member-info-details">
              <div className="row mt-4">
                {activeTab == 1 && (
                  <>
                    <ProductTeamSlider params={params} />
                  </>
                )}
              </div>
              {detailInfo.UserAbout && (
                <div className="row mt-4">
                  {activeTab == 2 && (
                    <div className="container">
                      {detailInfo.UserAbout && parse(detailInfo.UserAbout)}
                    </div>
                  )}
                </div>
              )}

              <div className="row mt-4">
                {activeTab == 4 && (
                  <>
                    <div className="col-md-12 py-2 px-5">
                      <h6 className="">
                        {detailInfo &&
                          detailInfo.WorkHistory &&
                          parse(detailInfo.WorkHistory)}
                      </h6>
                      <span>
                        {detailInfo &&
                          detailInfo.UserXpProperties &&
                          Object.keys(detailInfo.UserXpProperties).length > 0 &&
                          detailInfo.UserXpProperties.map((el, key) => (
                            <h6 className="section-subtitle section-subtitle-2 select__indicator">
                              {key}
                            </h6>
                          ))}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-4">
                {activeTab == 5 && (
                  <>
                    <div className="col-md-12 py-2 px-5">
                      <span>
                        {detailInfo &&
                          detailInfo.UserXpLocations &&
                          Object.keys(detailInfo.UserXpLocations).length > 0 &&
                          detailInfo.UserXpLocations.map((el, key) => (
                            <h6 className="section-subtitle section-subtitle-2 select__indicator">
                              {el}
                            </h6>
                          ))}
                      </span>
                    </div>
                  </>
                )}
              </div>
              <div className="row mt-4">
                {activeTab == 6 && (
                  <div className="container">
                    {detailInfo.UserXpProperties &&
                      Object.keys(detailInfo.UserXpProperties).length > 0 &&
                      Object.keys(detailInfo.UserXpProperties).map((i, key) => {
                        return (
                          <>
                            <h4 className="title-2 mb-10">
                              {Object.keys(detailInfo.UserXpProperties[i])[0]}
                            </h4>
                            {Object.values(detailInfo.UserXpProperties[i]).map(
                              (x, k) => {
                                return (
                                  <div className="property-details-amenities mb-60">
                                    <div className="row">
                                      {x.map((t, l) => {
                                        return (
                                          <div className="col-lg-4 col-md-6">
                                            <div className="ltn__menu-widget">
                                              {t.map((n, m) => {
                                                return (
                                                  <ul>
                                                    <li>
                                                      <label className="checkbox-item">
                                                        {n.Item1}
                                                        <input
                                                          type="checkbox"
                                                          defaultChecked="checked"
                                                          disabled
                                                        />
                                                        <span className="checkmark" />
                                                      </label>
                                                    </li>
                                                  </ul>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <hr
                                        className="mt-4 select__indicator"
                                        style={{ height: "3px" }}
                                      />
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="container">
              <div className="widget ltn__form-widget">
                {userContactMessage ? (
                  <h4 className="ltn__widget-title ltn__widget-title-border-2">
                    {t("mesajinizbasariylailetilmistir")}
                  </h4>
                ) : (
                  <>
                    <h4 className="ltn__widget-title ltn__widget-title-border-2">
                      {t("banayazin")}
                    </h4>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="fullName"
                          placeholder={`${t("isim")}-${t("soyisim")}`}
                          className="mb-0"
                          onChange={formik.handleChange}
                          value={formik.values.fullName}
                          style={{ height: "45px" }}
                        />
                        {formik.touched.fullName && formik.errors.fullName ? (
                          <span className="text-danger">
                            {formik.errors.fullName}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <input
                          type="email"
                          name="email"
                          placeholder={t("email")}
                          className="mb-0"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          style={{ height: "45px" }}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <span className="text-danger">
                            {formik.errors.email}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <input
                          type="text"
                          name="phone"
                          placeholder={t("telefon")}
                          className="mb-0"
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                          style={{ height: "45px" }}
                        />
                        {formik.touched.phone && formik.errors.phone ? (
                          <span className="text-danger">
                            {formik.errors.phone}
                          </span>
                        ) : null}
                      </div>
                      <textarea
                        name="message"
                        placeholder={t("mesaj")}
                        defaultValue={""}
                        onChange={formik.handleChange}
                        value={formik.values.message}
                      />
                      <div className="input-item">
                        <p>
                          <label className="input-info-save mb-0">
                            <input type="checkbox" name="acceptTerms" />
                            <Link className="ms-1" to="/kvkk-aciklama-metni">{t("kvkkokudumonayliyorum")}</Link>
                          </label>
                        </p>
                        {formik.touched.acceptTerms &&
                        formik.errors.acceptTerms ? (
                          <span className="text-danger">
                            {formik.errors.acceptTerms}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-3 captcha">
                        <ReCAPTCHA
                          sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          render="explicit"
                          onChange={(response) => {
                            formik.setFieldValue("recaptcha", response);
                          }}
                          onloadCallback={() => {}}
                        />
                        {formik.touched.recaptcha && formik.errors.recaptcha ? (
                          <span className="text-danger">
                            {formik.errors.recaptcha}
                          </span>
                        ) : null}
                      </div>
                      <button type="submit" className="btn theme-btn-1">
                        {t("gonder")}
                      </button>
                    </form>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamDetails;
