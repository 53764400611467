import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import ReCAPTCHA from "react-google-recaptcha";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import formServices from "../../services/form.service";
import { useTranslation } from "react-i18next";

const ContactForm3 = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let customClass = props.customClass ? props.customClass : "";
  const { t } = useTranslation(["common"]);

  const [subscribed, setSubscribed] = useState(false);

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      Email: "",
      acceptTerms: false,
      recaptcha: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      fullName: Yup.string().required(t("bualanidoldurmakzorunlu")),
      Email: Yup.string()
        .email(t('gecersizeposta'))
        .required(t("bualanidoldurmakzorunlu")),
      recaptcha: Yup.string().required(t("bualanidoldurmakzorunlu")),
      acceptTerms: Yup.bool().oneOf([true], t('lutfenkvkkmetniniokuyunuz')),
    }),
    onSubmit(values) {
      const formData = {
        FullName: values.fullName,
        Email: values.Email,
      };
      formServices.addSubscribe(formData).then((res) => {
        res && res.IsSucceeded && setSubscribed(true);
      });
    },
  });

  return (
    <div className={customClass}>
      <div className="container">
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          <div className="ltn__contact-message-area mb-120 mb--100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ltn__form-box contact-form-box box-shadow white-bg">
                    {subscribed ? (
                      <h4 className="ltn__widget-title ltn__widget-title-border-2">
                        {t("mesajinizbasariylailetilmistir")}
                      </h4>
                    ) : (
                      <>
                        <h4 className="title-2">{t("emailaboneligi")}</h4>
                        <form id="contact-form" onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  className="form-control d-flex align-items-center"
                                  type="text"
                                  name="fullName"
                                  placeholder={`${t("isim")}-${t("soyisim")}`}
                                  style={{ height: "45px" }}
                                  onChange={formik.handleChange}
                                  value={formik.values.fullName}
                                />
                              </div>
                              {formik.touched.fullName &&
                              formik.errors.fullName ? (
                                <span className="text-danger">
                                  {formik.errors.fullName}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-email ltn__custom-icon ">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  type="email"
                                  name="Email"
                                  placeholder={t("email")}
                                  style={{ height: "45px" }}
                                  onChange={formik.handleChange}
                                  value={formik.values.Email}
                                />
                              </div>
                              {formik.touched.Email && formik.errors.Email ? (
                                <span className="text-danger">
                                  {formik.errors.Email}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="input-item">
                            <p>
                              <label className="input-info-save mb-0">
                                <input
                                  type="checkbox"
                                  name="acceptTerms"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "acceptTerms",
                                      e.target.checked
                                    );
                                  }}
                                />{" "}
                                <Link
                                  className="ms-1"
                                  to="/kvkk-aciklama-metni"
                                >
                                  {t("kvkkokudumonayliyorum")}
                                </Link>
                              </label>
                            </p>
                          </div>
                          {formik.touched.acceptTerms &&
                          formik.errors.acceptTerms ? (
                            <span className="text-danger">
                              {formik.errors.acceptTerms}
                            </span>
                          ) : null}
                          <div className="mb-20 captcha">
                            <ReCAPTCHA
                              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                              render="explicit"
                              onChange={(response) => {
                                formik.setFieldValue("recaptcha", response);
                              }}
                              onloadCallback={() => {}}
                            />
                            {formik.touched.recaptcha &&
                            formik.errors.recaptcha ? (
                              <span className="text-danger">
                                {formik.errors.recaptcha}
                              </span>
                            ) : null}
                          </div>
                          <div className="btn-wrapper mt-0">
                            <button
                              className="btn2 theme-btn-1 btn-effect-1 text-uppercase"
                              type="submit"
                            >
                              {t("gonder")}
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm3;
