import React from "react";

function Pagination({
  pageCount,
  paginate,
  activepage,
  increasePageNum,
  decreasePageNum,
  maxPageNum,
  minPageNum,
}) {
  const pageNumbers = [];

  for (let i = 1; i <= pageCount; i++) {
    pageNumbers.push(i);
  }

  const renderPageNumbers = pageNumbers.map((num) => {
    if (num < maxPageNum + 1 && num > minPageNum) {
      return (
        <li className={activepage == num ? "active" : ""} key={num}>
          <a onClick={() => paginate(num)}>
            {num}
          </a>
        </li>
      );
    }
  });

  return (
    <div className="ltn__pagination-area text-center mb-20">
      <div className="ltn__pagination">
        <ul>
          <li>
            <a onClick={() => decreasePageNum()}>
              <i className="fas fa-angle-double-left" />
            </a>
          </li>
          {renderPageNumbers}
          <li>
            <a onClick={() => increasePageNum()}>
              <i className="fas fa-angle-double-right" />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Pagination;
