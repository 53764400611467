import axios from "./axiosInstance";

const showCaseList = async () => {
  let res = await axios({
    method: "POST",
    url: "ShowcaseUser/Favorites",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getNewsList = async () => {
  let res = await axios({
    method: "POST",
    url: "brand/news",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getBlogsList = async () => {
  let res = await axios({
    method: "POST",
    url: "brand/blogs",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getBlogDetail = async (id) => {
  let res = await axios({
    method: "POST",
    url: `brand/blogdetail/${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};
const getNewDetail = async (id) => {
  let res = await axios({
    method: "POST",
    url: `brand/newdetail/${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getSuccessfulUsersList = async () => {
  let res = await axios({
    method: "POST",
    url: "brand/successfullusers",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getCustomerCommentsList = async () => {
  let res = await axios({
    method: "POST",
    url: "customercomment/getlist",
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const homePageServices = {
  showCaseList,
  getNewsList,
  getNewDetail,
  getBlogsList,
  getBlogDetail,
  getSuccessfulUsersList,
  getCustomerCommentsList
};

export default homePageServices;
