import React, { useEffect, useState } from "react";

import Select from "react-select";
import FilterTypeConst from "./FilterTypeConst";
import generalServices from "../../services/general.service";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FilterType2 = ({ options, AdBaseId, AdTypeId, filterVal }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const fetchCountryList = useSelector(
    (state) => state.generalListsReducer.countryReducer.countryList
  );

  //Process Type Filter Values
  const [processType, setProcessType] = useState([]);
  const [selProcessType, setSelProcessType] = useState("");

  //House Shape Filter Values
  const [housePropertyShape, setHousePropertyShape] = useState([]);
  const [selHousePropertyShape, setSelHousePropertyShape] = useState("");

  //House Fullness Status Filter Values
  const [houseFullnessStatus, setHouseFullnessStatus] = useState([]);
  const [selHouseFullnessStatus, setSelHouseFullnessStatus] = useState("");

  //House Register Status Filter Values
  const [houseRegisterStatus, setHouseRegisterStatus] = useState([]);
  const [selHouseRegisterStatus, setSelHouseRegisterStatus] = useState("");

  //House Heating Status Filter Values
  const [houseHeatingType, setHouseHeatingType] = useState([]);
  const [selHouseHeatingType, setSelHouseHeatingType] = useState("");

  //House Heating Fuel Type Filter Values
  const [houseFuelType, setHouseFuelType] = useState([]);
  const [selHouseFuelType, setSelHouseFuelType] = useState("");

  //House Floor Type Filter Values
  const [houseFloorType, setHouseFloorType] = useState([]);
  const [selHouseFloorType, setSelHouseFloorType] = useState("");

  //House Buld Floor Type Filter Values
  const [houseBuildFloorType, setHouseBuildFloorType] = useState([]);
  const [selHouseBuildFloorType, setSelHouseBuildFloorType] = useState("");

  //House Floor Type Filter Values
  const [houseRoomCount, setHouseRoomCount] = useState([]);
  const [selHouseRoomCount, setSelHouseRoomCount] = useState("");

  //House Build Type Filter Values
  const [houseBuildType, setHouseBuildType] = useState([]);
  const [selHouseBuildType, setSelHouseBuildType] = useState("");

  //House Build Type Filter Values
  const [houseRealEstateCurrentStatus, setHouseRealEstateCurrentStatus] =
    useState([]);
  const [selHouseRealEstateCurrentStatus, setSelHouseRealEstateCurrentStatus] =
    useState("");

  //House Build Age Filter Values
  const [houseBuildAge, setHouseBuildAge] = useState([]);
  const [selHouseBuildAge, setSelHouseBuildAge] = useState("");

  //House WC Count Filter Values
  const [houseWcCount, setHouseWcCount] = useState([]);
  const [selHouseWcCount, setSelHouseWcCount] = useState("");

  //House Bathroom Count Filter Values
  const [houseBathroomCount, setHouseBathroomCount] = useState([]);
  const [selHouseBathroomCount, setSelHouseBathroomCount] = useState("");

  //House Balcoon Count Filter Values
  const [houseBalcoonCount, setHouseBalcoonCount] = useState([]);
  const [selHouseBalcoonCount, setSelHouseBalcoonCount] = useState("");

  const [provinceListOpt, setProvinceListOpt] = useState([]);
  const [countyListOpt, setCountyListOpt] = useState([]);
  const [districtListOpt, setDistrictListOpt] = useState([]);
  const [selProvince, setSelProvince] = useState("");
  const [selDistrict, setSelDistrict] = useState("");
  const [selCounty, setSelCounty] = useState("");

  useEffect(() => {
    setSelProvince("");
    setSelDistrict("");
    setSelCounty("");
      generalServices
        .provinceList()
        .then((res) => {
          const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
          tempArr?.length > 0
            ? setProvinceListOpt(tempArr)
            : setProvinceListOpt([]);
        });
  }, []);

  useEffect(() => {
    setSelDistrict("");
    setSelCounty("");
    selProvince && generalServices.countytList(parseInt(selProvince?.value)).then((res) => {
      const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
      tempArr?.length > 0 ? setCountyListOpt(tempArr) : setCountyListOpt([]);
    });
  }, [selProvince]);

  useEffect(() => {
    setSelDistrict("");
    selCounty &&
      generalServices.districtstList(parseInt(selCounty?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0
          ? setDistrictListOpt(tempArr)
          : setDistrictListOpt([]);
      });
  }, [selCounty]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      const adProcessTypeObj = options.ProcessType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setProcessType(adProcessTypeObj);

      const adHousePropertyShapeObj = options.HousePropertyShape.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHousePropertyShape(adHousePropertyShapeObj);

      const adHouseFullnessStatusObj = options.HouseFullnessStatus.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseFullnessStatus(adHouseFullnessStatusObj);

      const adHouseRegisterStatusObj = options.HouseRegisterStatus.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseRegisterStatus(adHouseRegisterStatusObj);

      const adHouseHeatingTypeObj = options.HouseHeatingType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseHeatingType(adHouseHeatingTypeObj);

      const adHouseFuelTypeObj = options.HouseFuelType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseFuelType(adHouseFuelTypeObj);

      const adHouseFloorTypeObj = options.HouseFloorType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseFloorType(adHouseFloorTypeObj);

      const adHouseBuildFloorTypeObj = options.HouseBuildFloorType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseBuildFloorType(adHouseBuildFloorTypeObj);

      const adHouseRoomCountObj = options.HouseRoomCount.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseRoomCount(adHouseRoomCountObj);

      const adHouseBuildTypeObj = options.HouseBuildType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseBuildType(adHouseBuildTypeObj);

      const adHouseRealEstateCurrentStatusObj =
        options.HouseRealEstateCurrentStatus.map((i) => ({
          label: i.Text,
          value: i.Value,
        }));
      setHouseRealEstateCurrentStatus(adHouseRealEstateCurrentStatusObj);

      const adHouseBuildAgeObj = options.HouseBuildAge.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseBuildAge(adHouseBuildAgeObj);

      const adHouseWcCountObj = options.HouseWcCount.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseWcCount(adHouseWcCountObj);

      const adHouseBathroomCountObj = options.HouseBathroomCount.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseBathroomCount(adHouseBathroomCountObj);

      const adHouseBalcoonCountObj = options.HouseBalcoonCount.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setHouseBalcoonCount(adHouseBalcoonCountObj);
    }
  }, [options]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "50px",
      height: 50,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  const setFilterValues = () => {
    const filterObj = {
      AdBaseId: AdBaseId,
      AdTypeId: AdTypeId,
      ProcessTypeId: selProcessType ? selProcessType.value : "",
      CityId: selProvince ? selProvince.value : '',
      CountyId: selCounty ? selCounty.value : '',
      DistrictId: selDistrict ? selDistrict.value : '',
      HousePropertyShapeId: selHousePropertyShape ? selHousePropertyShape.value : '', 
      HouseFullnessStatusId: selHouseFullnessStatus ? selHouseFullnessStatus.value : '', 
      HouseRegisterStatusId: selHouseRegisterStatus ? selHouseRegisterStatus.value : '', 
      HouseHeatingTypeId: selHouseHeatingType ? selHouseHeatingType.value : '', 
      HouseFuelTypeId: selHouseFuelType ? selHouseFuelType.value : '',
      HouseFloorTypeId: selHouseFloorType ? selHouseFloorType.value : '',
      HouseBuildFloorTypeId : selHouseBuildFloorType ? selHouseBuildFloorType.value : '',
      HouseRoomCountId: selHouseRoomCount ? selHouseRoomCount.value : '',
      HouseBuildTypeId: selHouseBuildType ? selHouseBuildType.value : '',
      HouseRealEstateCurrentStatusId: selHouseRealEstateCurrentStatus ? selHouseRealEstateCurrentStatus.value : '',
      HouseBuildAgeId: selHouseBuildAge ? selHouseBuildAge.value : '',
      HouseWcCountId: selHouseWcCount ? selHouseWcCount.value : '',
      HouseBathroomCountId: selHouseBathroomCount ? selHouseBathroomCount.value : '',
      HouseBalcoonCountId: selHouseBalcoonCount ? selHouseBalcoonCount.value : '',
    };

    filterVal(filterObj);
  };

  return (
    <>
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('islemtipiseciniz')}
        name="type"
        options={processType}
        value={selProcessType}
        styles={customStyles}
        onChange={(e) => {
          setSelProcessType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('il')}
        name="province"
        options={provinceListOpt && provinceListOpt}
        value={selProvince}
        onChange={(e) => {
          setSelProvince(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('ilçe')}
        name="county"
        options={countyListOpt && countyListOpt}
        value={selCounty}
        onChange={(e) => {
          setSelCounty(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('semt/mahalle')}
        name="district"
        options={districtListOpt && districtListOpt}
        value={selDistrict}
        onChange={(e) => {
          setSelDistrict(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('konutsekliseciniz')}
        name="type"
        options={housePropertyShape}
        value={selHousePropertyShape}
        styles={customStyles}
        onChange={(e) => {
          setSelHousePropertyShape(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('kullanimdurumunuseciniz')}
        name="type"
        options={houseFullnessStatus}
        value={selHouseFullnessStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseFullnessStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('tapudurumunuseciniz')}
        name="type"
        options={houseRegisterStatus}
        value={selHouseRegisterStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseRegisterStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('isinmatipiniseciniz')}
        name="type"
        options={houseHeatingType}
        value={selHouseHeatingType}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseHeatingType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('yakittipiniseciniz')}
        name="type"
        options={houseFuelType}
        value={selHouseFuelType}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseFuelType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('bulundugukatiseciniz')}
        name="type"
        options={houseFloorType}
        value={selHouseFloorType}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseFloorType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('binakatsayisiniseciniz')}
        name="type"
        options={houseBuildFloorType}
        value={selHouseBuildFloorType}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseBuildFloorType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('odasayisiniseciniz')}
        name="type"
        options={houseRoomCount}
        value={selHouseRoomCount}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseRoomCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('binayapitipiniseciniz')}
        name="type"
        options={houseBuildType}
        value={selHouseBuildType}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseBuildType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('emlagindurumunuseciniz')}
        name="type"
        options={houseRealEstateCurrentStatus}
        value={selHouseRealEstateCurrentStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseRealEstateCurrentStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('binayasiniseciniz')}
        name="type"
        options={houseBuildAge}
        value={selHouseBuildAge}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseBuildAge(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('tuvaletsayisiniseciniz')}
        name="type"
        options={houseWcCount}
        value={selHouseWcCount}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseWcCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('banyosayisiniseciniz')}
        name="type"
        options={houseBathroomCount}
        value={selHouseBathroomCount}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseBathroomCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('balkonsayisiniseciniz')}
        name="type"
        options={houseBalcoonCount}
        value={selHouseBalcoonCount}
        styles={customStyles}
        onChange={(e) => {
          setSelHouseBalcoonCount(e);
        }}
      />
       <div className="btn-wrapper mt-4 row">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="button"
          onClick={() => setFilterValues()}
        >
          {t('gonder')}
        </button>
      </div>

      {/* <FilterTypeConst options={options} /> */}
    </>
  );
};

export default FilterType2;
