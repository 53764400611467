import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import parse from "html-react-parser";
import generalActions from "../../redux/actions/general.actions";
import generalServices from "../../services/general.service";
import Pagination from "../global-components/Pagination";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

const Location = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer.provinceList
  );
  const fetchOfficeFilterOpt = useSelector(
    (state) => state.generalListsReducer.setOfficeFiltersReducer.filters
  );

  const fetchCompSettings = useSelector(
    (state) => state.generalListsReducer.companySettingsReducer.compSettings
  );

  const fetchSystemLang = useSelector((state) => state.generalListsReducer.systemLanguageReducer.selLang);

  const [provinceList, setProvinceList] = useState([]);
  const [countyList, setCountyList] = useState([]);
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCounty, setSelectedCounty] = useState("");
  const [officeCityList, setOfficeCityList] = useState([]);
  const [officeList, setOfficeList] = useState([]);
  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumLimit] = useState(5);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const [center, setCenter] = useState({});
  const getCountyList = (id) => {
    generalServices.countytList(id).then((res) => {
      let distrOpt = res?.map((i) => ({ label: i.Name, value: i.Id }));
      setCountyList(distrOpt);
    });
  };
  const [officeName, setOfficeName] = useState("");

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({
      ...base,
      zIndex: 9999,
      minWidth: "100%",
      width: "max-content",
    }),
  };

  useEffect(() => {
    console.log('redux çalışıyor', fetchSystemLang)
  }, [fetchSystemLang])

  useEffect(() => {
    generalServices.getOfficeList(fetchOfficeFilterOpt).then((res) => {
      setTotalPageCount(res.Result.TotalPageCount);
      let officeCityArr = [];
      let officeListArr = [];
      if (res.Result.Items) {
        res.Result.Items.map((i) => {
          if (!officeCityArr.includes(i.City)) {
            officeCityArr.push(i.City);
          }
        });

        officeCityArr.map((i) => {
          let items = res.Result.Items.filter((j) => j.City === i);
          officeListArr.push(items);
        });
        setOfficeCityList(officeCityArr);
        console.log("OFİS LİSTESİ", officeListArr[0]);
        setOfficeList(officeListArr[0]);
      }
    });
  }, [fetchOfficeFilterOpt, pageNumLimit, currentpage]);

  useEffect(() => {
    dispatch(generalActions.getProvinceList());
  }, []);

  useEffect(() => {
    const $ = window.$;

    let publicUrl = process.env.PUBLIC_URL + "/";
  }, []);

  useEffect(() => {
    setProvinceList(fetchProvinceList);
  }, [fetchProvinceList]);

  const containerStyle = {
    width: "100%",
    height: "100%",
  };

  useEffect(() => {
    if (fetchCompSettings && Object.keys(fetchCompSettings).length > 0) {
      const tempObj = {
        lat: Number(fetchCompSettings.Locations[0].Latitude),
        lng: Number(fetchCompSettings.Locations[0].Longitude),
      };
      setCenter(tempObj);
    }
  }, [fetchCompSettings]);

  //Change Page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  const handleClick = () => {
    const officeFilter = {
      OfficeName: officeName ? officeName : "",
      CityId: selectedProvince ? selectedProvince.value : "",
      CountyId: selectedCounty ? selectedCounty.value : "",
      Sorting: 1,
      PageIndex: currentpage,
    };
    dispatch(generalActions.setOfficeFilters(officeFilter));
  };

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfacQ1tAOSlcKRE1bgg-WRobYGyvHrprI",
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [center]
  );

  const onUnmount = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        setMap(null);
      }
      console.log("CENTER", center);
    },
    [center]
  );

  return (
    <div className="mt-20">
      <div className="ltn__google-map-locations-list-area mt-20">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mb-20">
              <div className="ltn__location-search">
                <form action="#" className="clearfix">
                  <div className="row align-items-center">
                    <div className="col-md-2 location-search-header p-0">
                      <h3>{t('ofisara')}</h3>
                    </div>
                    <div
                      className=" col-md-3 p-0"
                      style={{ marginRight: "2px" }}
                    >
                      <input
                        className="form-control"
                        placeholder="Ofis Adı"
                        onChange={(e) => setOfficeName(e.target.value)}
                        style={{ height: "45px" }}
                      />
                    </div>
                    <div
                      className=" col-md-3 p-0"
                      style={{ marginRight: "2px" }}
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        placeholder={t('il')}
                        name="province"
                        options={provinceList}
                        value={selectedProvince}
                        styles={customStyles}
                        onChange={(e) => {
                          setSelectedProvince(e);
                          setSelectedCounty("");
                          if (e) {
                            getCountyList(e.value);
                          } else {
                            setCountyList([]);
                          }
                        }}
                      />
                    </div>
                    <div className=" col-md-3 p-0">
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        placeholder={t('ilçe')}
                        name="county"
                        options={countyList}
                        value={selectedCounty}
                        styles={customStyles}
                        onChange={(e) => setSelectedCounty(e)}
                      />
                    </div>
                    <div className="col-md-1 p-0">
                      <button
                        onClick={() => handleClick()}
                        className="btn2 btn-effect-3 btn-white"
                        style={{ borderRadius: "10px" }}
                        type="button"
                      >
                        {t('ara')}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="ltn__product-tab-content-inner ltn__product-list-view">
              <div className="row">
                {officeList?.map((i, key) => {
                  return (
                    <div key={key} className="col-lg-12">
                      <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5 mb-20">
                        <div className="row">
                          <div className="product-img go-top col-md-3 col-12 p-0">
                            <Link to={`/ofis-detay/${i.Title}/${i.OfficeId}`}>
                              <img
                                style={{ height: "207px" }}
                                src={
                                  i.Image
                                    ? i.Image
                                    : publicUrl + "assets/img/product-3/1.jpg"
                                }
                                alt="#"
                              />
                            </Link>
                          </div>
                          <div className="product-info col-md-8 col-12">
                            <div
                              className="product-badge"
                              style={{ top: 0, right: 0 }}
                            >
                              <ul>
                                <li className="sale-badg">
                                  {i.Title ? i.Title : "-"}
                                </li>
                              </ul>
                            </div>
                            <div className="product-img-location go-top">
                              <ul>
                                <li>
                                  <i className="flaticon-pin" />{" "}
                                  {i.District ? i.District : ""}{" "}
                                  {i.County ? i.County : ""},{" "}
                                  {i.City ? i.City : ""}
                                </li>
                              </ul>
                            </div>
                            <div className="product-info-bottom mt-2 ps-0">
                              <div className="product-price">
                                <i className="fas fa-phone" />
                                {i.Phone ? i.Phone : "-"}
                              </div>
                            </div>
                          </div>
                          <div className="ltn__social-media-3 col-md-1 col-12">
                            <ul className="officeList-social-media-button">
                              <li>
                                <a
                                  href={i.SocialUrl ? i.FacebookUrl : "#"}
                                  title="Facebook"
                                >
                                  <i className="fab fa-facebook-f" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={i.SocialUrl ? i.TwitterUrl : "#"}
                                  title="Twitter"
                                >
                                  <i className="fab fa-twitter" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={i.SocialUrl ? i.LinkedinUrl : "#"}
                                  title="Linkedin"
                                >
                                  <i className="fab fa-linkedin" />
                                </a>
                              </li>
                              <li>
                                <a
                                  href={i.YoutubeUrl ? i.YoutubeUrl : "#"}
                                  title="Linkedin"
                                >
                                  <i className="fab fa-youtube" />
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>

            <Pagination
              paginate={paginate}
              increasePageNum={increasePageNum}
              decreasePageNum={decreasePageNum}
              activepage={currentpage}
              pageCount={totalPageCount}
              maxPageNum={maxPageNumLimit}
              minPageNum={minPageNumLimit}
            />
          </div>
        </div>
      </div>
      {isLoaded && (
        <div className="ltn__google-map-locations-area">
          <div className="property-details-google-map mb-20">
            {Object.keys(center).length > 0 && (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={9}
                onLoad={onLoad}
                onUnmount={onUnmount}
              >
                {officeList &&
                  officeList?.map((i, key) => (
                    <Marker
                      position={{
                        lat: Number(i.Latitude),
                        lng: Number(i.Longitude),
                      }}
                      key={key}
                      title={i.Title}
                    />
                  ))}

                <></>
              </GoogleMap>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Location;
