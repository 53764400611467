import React, { useEffect, useState } from "react";
import Navbar from "./global-components/Navbar-v2";
import PageHeader from "./global-components/Page_header";
import parse from "html-react-parser";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import { BrowserRouter as Router, Switch, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";
import TeamFilter2 from "./section-components/TeamFilter2";
import ContactForm2 from "./section-components/ContactForm2";
import ContactForm from "./section-components/ContactForm";
import OffiecFilter from "./section-components/OfficeFilter";

const Cat_Content = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  let location = useLocation();
  const genPageContents = useSelector(
    (state) => state.generalListsReducer.allPagesByTypeReducer.allPagesByType
  );
  const genPageContentDetails = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const fetchSystemLang = useSelector(
    (state) => state.generalListsReducer.systemLanguageReducer.selLang
  );
  const [actUrlObj, setActUrlObj] = useState({});
  const [actContentObj, setActContentObj] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (Object.keys(genPageContents).length > 0) {
      const selectedContentObj = genPageContents.find(
        (el) => `/${el.PageLinks[0].Title}` == location.pathname
      );
      if (
        Object.keys(selectedContentObj).length > 0 &&
        Object.keys(genPageContentDetails).length > 0
      ) {
        const pageOrder = selectedContentObj.PageOrder;
        const selectedContentDetailObj = genPageContentDetails.filter((el) =>
          el.WidgetKey.includes(`${pageOrder}-`)
        );
        setActUrlObj(selectedContentObj);
        setActContentObj(selectedContentDetailObj);
        console.log("gen page contents", selectedContentDetailObj);
      }
    }
  }, [location]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);

  return (
    <div>
      <Navbar />
      {/* <PageHeader
        headertitle={
          Object.keys(actUrlObj).length > 0 && actUrlObj.Titles[0].Title
        }
        customclass="mb-0"
      /> */}

      <div className="ltn__team-details-area mb-120 pt-10">
        <div className="container-fluid">
          <div className="row">
            <div className="ltn__page-details-area ltn__service-details-area mb-120 mt-50">
              <div className="container-fluid">
                <div className="row">
                  {/* PRODUCT TAB AREA START */}
                  <div className="ltn__product-tab-area">
                    <div className="container-fluid">
                      <div className="row">
                        <div className="col-lg-2">
                          <div className="ltn__tab-menu-list mb-50">
                            <div className="nav p-0">
                              {actContentObj.length > 0 &&
                                actContentObj.map((i, k) => (
                                  <a
                                    key={k}
                                    className={
                                      k == activeTab ? "active show" : ""
                                    }
                                    data-bs-toggle="tab"
                                    href={`#${i.WidgetKey}`}
                                    onClick={() => setActiveTab(k)}
                                  >
                                    {i.WidgetTitle[
                                      i.WidgetTitle.findIndex(
                                        (o) => o.LanguageType == fetchSystemLang
                                      )
                                    ] &&
                                      i.WidgetTitle[
                                        i.WidgetTitle.findIndex(
                                          (o) =>
                                            o.LanguageType == fetchSystemLang
                                        )
                                      ].Title}
                                  </a>
                                ))}
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-10">
                          <div className="tab-content">
                            {actContentObj.length > 0 &&
                              actContentObj.map((i, k) => (
                                <div
                                  className={
                                    k == activeTab
                                      ? "tab-pane fade active show"
                                      : "tab-pane fade"
                                  }
                                  id={`${i.WidgetKey}`}
                                >
                                  <div className="row">
                                    {i.WidgetKey && i.WidgetKey === "13-2" && (
                                      <TeamFilter2 />
                                    )}
                                    {i.WidgetKey && i.WidgetKey === "13-3" && (
                                      <OffiecFilter />
                                    )}
                                    {i.WidgetKey && i.WidgetKey === "14-2" && (
                                      <ContactForm2 />
                                    )}
                                    {i.WidgetKey && i.WidgetKey === "16-2" && (
                                      <ContactForm />
                                    )}
                                  </div>
                                  <div className="row">
                                    <div
                                      className={
                                        i.WidgetImageUrl && i.WidgetVideoFileUrl
                                          ? "ltn__myaccount-tab-content-inner col-md-4"
                                          : "ltn__myaccount-tab-content-inner col-md-8"
                                      }
                                    >
                                      <div className="property-detail-info-list  clearfix mb-60">
                                        {i.WidgetDescription &&
                                          i.WidgetDescription[
                                            i.WidgetTitle.findIndex(
                                              (o) =>
                                                o.LanguageType ==
                                                fetchSystemLang
                                            )
                                          ].Title &&
                                          parse(
                                            i.WidgetDescription[
                                              i.WidgetTitle.findIndex(
                                                (o) =>
                                                  o.LanguageType ==
                                                  fetchSystemLang
                                              )
                                            ].Title
                                          )}
                                      </div>
                                    </div>
                                    {i.WidgetImageUrl && (
                                      <div className="col-md-4 align-self-center sell-home-page-img">
                                        <div className="about-us-img-wrap about-img-right">
                                          <img
                                            src={
                                              i.WidgetImageUrl
                                                ? i.WidgetImageUrl
                                                : publicUrl +
                                                  "assets/img/others/9.png"
                                            }
                                            alt="About Us Image"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {i.WidgetVideoFileUrl && (
                                      <div className="col-md-4 align-self-center sell-home-page-img">
                                        <div className="about-us-img-wrap about-img-right">
                                          <img
                                            src={
                                              i.WidgetVideoFileUrl
                                                ? i.WidgetVideoFileUrl
                                                : publicUrl +
                                                  "assets/img/others/9.png"
                                            }
                                            alt="About Us Image"
                                          />
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* PRODUCT TAB AREA END */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CallToAction />
      <Footer />
    </div>
  );
};

export default Cat_Content;
