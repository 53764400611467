import axios from "axios";
import { store } from "../redux/store";

const axiosInstance = axios.create({
  baseURL: `http://microsite-api.emlaksistemi.com/api/`,
});

axiosInstance.interceptors.request.use((config) => {
  const state = store.getState();
  const token = state.authReducer.token;
  config.params = config.params || {};

  config.headers = {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
  return config;
});

export default axiosInstance;
