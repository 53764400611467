import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";




const Social = ({ settings }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";

  return (
    <div className="ltn__social-media">
      <ul>
        <li>
          <a
            href={settings && settings.FacebookUrl ? settings.FacebookUrl : "#"}
            title="Facebook"
          >
            <i className="fab fa-facebook-f" />
          </a>
        </li>
        <li>
          <a
            href={settings && settings.TwitterUrl ? settings.TwitterUrl : "#"}
            title="Twitter"
          >
            <i className="fab fa-twitter" />
          </a>
        </li>
        <li>
          <a
            href={
              settings && settings.InstagramUrl ? settings.InstagramUrl : "#"
            }
            title="Instagram"
          >
            <i className="fab fa-instagram" />
          </a>
        </li>
        <li>
          <a
            href={settings && settings.YoutubeUrl ? settings.YoutubeUrl : "#"}
            title="Youtube"
          >
            <i className="fab fa-youtube" />
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Social;
