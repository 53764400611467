import Home from "./components/Home";
import Location from "./components/Location";
import Team from "./components/Team";
import ShopRightSidebarPage from "./components/ShopRightSidebarPage";
import Product_Details from "./components/Product_Details";
import About from "./components/About";
import Team_Details from "./components/Team_Details";
import { Suspense, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import userActions from "./redux/actions/user.actions";
import Office_Details from "./components/Office_Details ";
import Blogs from "./components/Blogs";
import Blog_Detail from "./components/Blog_Details ";
import News from "./components/News";
import New_Detail from "./components/New_Details ";
import ScrollToTop from "./components/ScrollToTop";
import Sell_Home from "./components/Sell_Home";
import Gen_Content from "./components/Gen_Content";
import Cat_Content from "./components/Cat_Content";
import generalActions from "./redux/actions/general.actions";
import Communication from "./components/Communication";
import Official from "./components/Official";
import Mission from "./components/Mission";
import Aboutus from "./components/Aboutus";

function App() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state);
  const [tokenCheck, setTokenCheck] = useState(false);
  const [routeCheck, setRouteCheck] = useState(false);
  const [genContentUrls, setGenContentUrls] = useState([]);
  const [genCategoryUrls, setGenCategoryUrls] = useState([]);
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.allPagesByTypeReducer
  );
  const fetchPageContents2 = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  useEffect(() => {
    dispatch(userActions.login("BetterHomesUser", "BH@2022;!@Reos"));
    // dispatch(userActions.login("StartKseyApiUser", "StartKey#Data!Api2017%!"));
  }, []);

  useEffect(() => {
    setTokenCheck(token?.authReducer.loggedIn);
    if (token && Object.keys(token).length > 0) {
      setRouteCheck(true);
      if (!fetchPageContents2) {
        dispatch(generalActions.getPageContents());
        dispatch(generalActions.getPageByType());
        dispatch(generalActions.getContentPages());
        dispatch(generalActions.getProvinceList());
      }
    }
  }, [token]);

  useEffect(() => {
    if (routeCheck) {
      const pageContentObj =
        token?.generalListsReducer?.allPagesByTypeReducer?.allPagesByType;
      const tempArr = [];
      const tempArr2 = [];
      pageContentObj &&
        pageContentObj.map((i, k) => {
          if (
            i.PageLinks[0].Title != "logo-alti-icerik" &&
            i.PageOrder != 13 &&
            i.PageOrder != 14 &&
            i.PageOrder != 15 &&
            i.PageOrder != 16
          ) {
            tempArr.push(i.PageLinks[0].Title);
          }
          if (
            i.PageOrder == 13 ||
            i.PageOrder == 14 ||
            i.PageOrder == 15 ||
            i.PageOrder == 16
          ) {
            tempArr2.push(i.PageLinks[0].Title);
          }
        });

      dispatch(generalActions.genCompanySettings());
      setGenContentUrls(tempArr);
      setGenCategoryUrls(tempArr2);
    }
  }, [routeCheck, fetchPageContents]);

  return (
    <BrowserRouter>
      {tokenCheck && (
        <ScrollToTop>
          <Suspense fallback={null}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/ofis-bul" element={<Location />} />
              <Route path="/ofis-detay/:name/:id" element={<Office_Details />} />
              <Route path="/danisman-bul" element={<Team />} />
              <Route path="/danisman-detay/:name/:id" element={<Team_Details />} />
              <Route path="/ev-satin-al" element={<ShopRightSidebarPage />} />
              <Route path="/ev-sat" element={<Sell_Home />} />
              <Route path="/ilan-detay/:id" element={<Product_Details />} />
              <Route path="/ailemize-katil" element={<About />} />

              <Route path="/bloglar" element={<Blogs />} />
              <Route path="/blog-detay/:id" element={<Blog_Detail />} />

              <Route path="/haberler" element={<News />} />
              <Route path="/haber-detay/:id" element={<New_Detail />} />
              <Route path="/kurumsal" element={<Official />} />
              <Route path="/hakkimizda" element={<Aboutus />} />
              <Route path="/vizyon-misyon" element={<Mission />} />

              <Route path="/iletisim" element={<Communication />} />

              {routeCheck &&
                genContentUrls.length > 0 &&
                genContentUrls.map((i, k) => (
                  <Route path={`/${i}`} element={<Gen_Content />} />
                ))}
              {routeCheck &&
                genCategoryUrls.length > 0 &&
                genCategoryUrls.map((i, k) => (
                  <Route path={`/${i}`} element={<Cat_Content />} />
                ))}
            </Routes>
          </Suspense>
        </ScrollToTop>
      )}
    </BrowserRouter>
  );
}

export default App;
