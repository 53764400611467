import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import homePageServices from "../../services/homepage.service";
import Slider from "react-slick";
import { useSelector } from "react-redux";

const VideoGallery = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const token = useSelector((state) => state);
  const pageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );
  const settings = {
    dots: true,
    arrows: true,
    infinite: false,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: (
      <a className="slick-prev">
        <i className="fa fa-angle-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="slick-next">
        <i className="fa fa-angle-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: true,
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [videoContentList, setVideoContentList] = useState([]);

  useEffect(() => {
    if (pageContents && Object.keys(pageContents).length > 0) {
      const contentKeys = [
        "124567890",
        "999999999",
        "8888888888",
        "7777777",
        '666666',
      ];
      const tempContentArr = [];
      contentKeys.map((i) => {
        let temObj = pageContents.find((el) => el.WidgetKey === i);
        Object.keys(temObj).length > 0 && tempContentArr.push(temObj);
      });
      setVideoContentList(tempContentArr);
    }
  }, [pageContents]);


  return (
    <div className="ltn__img-slider-area pt-20 pb-0">
      <div className="container-fluid">
        <div className="row slick-arrow-1 slick-arrow-1-inner ltn__no-gutter-all">
          <Slider {...settings}>
            {videoContentList.length > 0 &&
              videoContentList.map((i, k) => (
                <div key={k} className="col-lg-12 p-0">
                  <div className="ltn__gallery-item filter_category_1">
                    <div className="ltn__gallery-item-inner">
                      <div className="ltn__gallery-item-img">
                        <a
                          href={i.WidgetVideoEmbedCode ? i.WidgetVideoEmbedCode : "//www.youtube.com/embed/K3Mwc_kcREE"}
                          data-rel="lightcase:myCollection"
                        >
                          <img
                            src={
                              i.WidgetImageUrl
                                ? i.WidgetImageUrl
                                : publicUrl + "assets/img/gallery/3.jpg"
                            }
                            alt="Image"
                            style={{ width: "100%", height: "316.406px" }}
                          />
                          <span className="ltn__gallery-action-icon">
                            <i className="fab fa-youtube" />
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default VideoGallery;
