import React, { Component, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Select, { StylesConfig } from "react-select";
import generalService from "../../services/general.service";
import formServices from "../../services/form.service";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import parse from "html-react-parser";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ContactForm2 = (props) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  let customClass = props.customClass ? props.customClass : "";
  const fetchProvinceList = useSelector(
    (state) => state.generalListsReducer.provinceReducer?.provinceList
  );
  const phoneRegex =
    /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
  const [selOfficeCityId, setSelOfficeCityId] = useState("");
  const [selOfficeCountyId, setSelOfficeCountyId] = useState("");
  const [selLivingCityId, setSelLivingCityId] = useState("");
  const [selOfficeId, setSelOfficeId] = useState("");
  const [selRoleId, setSelRoleId] = useState("");
  const [userMessage, setUserMessage] = useState("");
  const [consultantFormRes, setConsultantFormRes] = useState(false);

  const [countyListOpt, setCountyListOpt] = useState([]);
  const [officeListOpt, setOfficeListOpt] = useState([]);
  const [roleListOpt, setRoleListOpt] = useState([]);
  const [appSourceTypes, setAppSourceTypes] = useState([]);

  useEffect(() => {
    generalService.getOfficeList().then((res) => {
      if (res.Result) {
        const returnOfficeRes = res.Result.Items.map((i, k) => ({
          label: i.Title,
          value: i.OfficeId,
        }));
        setOfficeListOpt(returnOfficeRes);
      }
    });
    generalService.userRoleList().then((res) => {
      if (res) {
        const returnRoleRes = res.map((i, k) => ({
          label: i.Id,
          value: i.Name,
        }));
        setRoleListOpt(returnRoleRes);
      }
    });
    generalService.bindDropdowns().then((res) => {
      if (res && res.ApplicationResourceTypes) {
        setAppSourceTypes(
          res.ApplicationResourceTypes.map((i) => ({
            label: i.Text,
            value: i.Value,
          }))
        );
        console.log("ne döndğ", res);
      }
    });
  }, []);

  useEffect(() => {
    console.log("GELİİİDDDİİİ", props.kvkk);
  }, [props]);

  useEffect(() => {
    setCountyListOpt([]);
    setSelOfficeCountyId("");
    selOfficeCityId &&
      generalService.countytList(selOfficeCityId.value).then((res) => {
        const returnRes = res.map((i, k) => ({
          label: i.Name,
          value: i.CityId,
        }));
        setCountyListOpt(returnRes);
      });
  }, [selOfficeCityId]);

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      margin: 5,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  const formik = useFormik({
    initialValues: {
      FirstName: "",
      LastName: "",
      Email: "",
      Phone: "",
      BirthDate: "",
      OpenOfficeCityId: "",
      ApplicationSourceTypeId: "",
      acceptTerms: false,
      recaptcha: "",
    },
    // We used Yup here.
    validationSchema: Yup.object().shape({
      FirstName: Yup.string().required(t("bualanidoldurmakzorunlu")),
      LastName: Yup.string().required(t("bualanidoldurmakzorunlu")),
      Email: Yup.string()
        .email(t('gecersizeposta'))
        .required(t("bualanidoldurmakzorunlu")),
      Phone: Yup.string().required(t("bualanidoldurmakzorunlu")),
      BirthDate: Yup.string().required(t("bualanidoldurmakzorunlu")),
      OpenOfficeCityId: Yup.object().required(t("bualanidoldurmakzorunlu")),
      ApplicationSourceTypeId: Yup.object().required(
        t("bualanidoldurmakzorunlu")
      ),
      acceptTerms: Yup.bool().oneOf([true], t('lutfenkvkkmetniniokuyunuz')),
      recaptcha: Yup.string().required(t("bualanidoldurmakzorunlu")),
    }),
    onSubmit(values) {
      const formData = {
        FirstName: values.FirstName,
        LastName: values.LastName,
        Phone: values.Phone,
        Email: values.Email,
        BirthDate: values.BirthDate,
        OpenOfficeCityId: values.OpenOfficeCityId.value,
        ApplicationSourceTypeId: values.ApplicationSourceTypeId.value,
        Message: values.message,
      };

      formServices.appealFranchise(formData).then((res) => {
        console.log("ne döNDÜÜÜÜÜ", res);
        res && res.IsSucceeded && setConsultantFormRes(true);
      });
    },
  });

  return (
    <div className={customClass}>
      <div className="container">
        <div className="row ltn__custom-gutter--- justify-content-center go-top">
          <div className="ltn__contact-message-area mb-120 mb--100">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="ltn__form-box contact-form-box box-shadow white-bg">
                    {consultantFormRes ? (
                      <h4 className="ltn__widget-title ltn__widget-title-border-2">
                        {t("mesajinizbasariylailetilmistir")}
                      </h4>
                    ) : (
                      <>
                        <h4 className="title-2">{t("franchiseol")}</h4>
                        <form id="contact-form" onSubmit={formik.handleSubmit}>
                          <div className="row">
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  type="text"
                                  name="FirstName"
                                  placeholder={t("isim")}
                                  style={{ height: "45px" }}
                                  onChange={formik.handleChange}
                                  value={formik.values.FirstName}
                                />
                              </div>
                              {formik.touched.FirstName &&
                              formik.errors.FirstName ? (
                                <span className="text-danger">
                                  {formik.errors.FirstName}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-name ltn__custom-icon">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  type="text"
                                  name="LastName"
                                  placeholder={t("soyisim")}
                                  style={{ height: "45px" }}
                                  onChange={formik.handleChange}
                                  value={formik.values.LastName}
                                />
                              </div>
                              {formik.touched.LastName &&
                              formik.errors.LastName ? (
                                <span className="text-danger">
                                  {formik.errors.LastName}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-email ltn__custom-icon">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  style={{ height: "45px" }}
                                  type="email"
                                  name="Email"
                                  placeholder={t("email")}
                                  onChange={formik.handleChange}
                                  value={formik.values.Email}
                                />
                              </div>
                              {formik.touched.Email && formik.errors.Email ? (
                                <span className="text-danger">
                                  {formik.errors.Email}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item input-item-phone ltn__custom-icon">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  style={{ height: "45px" }}
                                  type="text"
                                  name="Phone"
                                  placeholder={t("telefon")}
                                  onChange={formik.handleChange}
                                  value={formik.values.Phone}
                                />
                              </div>
                              {formik.touched.Phone && formik.errors.Phone ? (
                                <span className="text-danger">
                                  {formik.errors.Phone}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="input-item input-item-date ltn__custom-icon mb-0">
                                <input
                                  className="form-control d-flex align-items-center mb-0"
                                  style={{ height: "45px" }}
                                  type="date"
                                  name="BirthDate"
                                  placeholder={t("dogumtarihi")}
                                  onChange={formik.handleChange}
                                  value={formik.values.BirthDate}
                                />
                              </div>
                              {formik.touched.BirthDate &&
                              formik.errors.BirthDate ? (
                                <span className="text-danger">
                                  {formik.errors.BirthDate}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item mb-0">
                                <Select
                                  className=""
                                  classNamePrefix="select"
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={t("il")}
                                  name="OpenOfficeCityId"
                                  options={
                                    fetchProvinceList && fetchProvinceList
                                  }
                                  value={formik.values.OpenOfficeCityId}
                                  onChange={(e) => {
                                    setSelOfficeCityId(e);
                                    formik.setFieldValue("OpenOfficeCityId", e);
                                  }}
                                  styles={customStyles}
                                />
                              </div>
                              {formik.touched.OpenOfficeCityId &&
                              formik.errors.OpenOfficeCityId ? (
                                <span className="text-danger">
                                  {formik.errors.OpenOfficeCityId}
                                </span>
                              ) : null}
                            </div>
                            <div className="col-md-6 mb-2">
                              <div className="input-item mb-0">
                                <Select
                                  className=""
                                  classNamePrefix="select"
                                  isClearable={true}
                                  isSearchable={true}
                                  placeholder={t("bizineredenduydunuz")}
                                  name="ApplicationSourceTypeId"
                                  options={appSourceTypes}
                                  value={formik.values.ApplicationSourceTypeId}
                                  onChange={(e) => {
                                    setSelOfficeCityId(e);
                                    formik.setFieldValue(
                                      "ApplicationSourceTypeId",
                                      e
                                    );
                                  }}
                                  styles={customStyles}
                                />
                              </div>
                              {formik.touched.OpenOfficeCityId &&
                              formik.errors.OpenOfficeCityId ? (
                                <span className="text-danger">
                                  {formik.errors.OpenOfficeCityId}
                                </span>
                              ) : null}
                            </div>
                          </div>
                          <div className="input-item input-item-textarea ltn__custom-icon">
                            <textarea
                              name="message"
                              placeholder={t("mesaj")}
                              defaultValue={""}
                              value={formik.values.message}
                              onChange={(e) => {
                                formik.setFieldValue("message", e.target.value);
                              }}
                            />
                          </div>
                          <div className="input-item">
                            <p>
                              <label className="input-info-save mb-0">
                                <input
                                  type="checkbox"
                                  name="acceptTerms"
                                  onChange={(e) => {
                                    formik.setFieldValue(
                                      "acceptTerms",
                                      e.target.checked
                                    );
                                  }}
                                />{" "}
                                <Link
                                  className="ms-1"
                                  to="/kvkk-aciklama-metni"
                                >
                                  {t("kvkkokudumonayliyorum")}
                                </Link>
                              </label>
                              {formik.touched.acceptTerms &&
                              formik.errors.acceptTerms ? (
                                <span className="text-danger">
                                  {formik.errors.acceptTerms}
                                </span>
                              ) : null}
                            </p>
                          </div>
                          <div className="mb-20 captcha">
                            <ReCAPTCHA
                              sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                              render="explicit"
                              onChange={(response) => {
                                formik.setFieldValue("recaptcha", response);
                              }}
                              onloadCallback={() => {}}
                            />
                            {formik.touched.recaptcha &&
                            formik.errors.recaptcha ? (
                              <span className="text-danger">
                                {formik.errors.recaptcha}
                              </span>
                            ) : null}
                          </div>
                          <div className="btn-wrapper mt-0">
                            <button
                              className="btn2  theme-btn-1 btn-effect-1 text-uppercase"
                              type="submit"
                            >
                              {t("gonder")}
                            </button>
                          </div>
                        </form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactForm2;
