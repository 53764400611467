import { userConstants } from '../constants/user.constants';
import  userService  from '../../services/user.service';
import { history } from '../../helpers/history';

const userActions = {
    login,
};

function login(username, password) {
    return dispatch => {
        userService.login(username, password)
            .then(
                res => { 
                    dispatch(success(res.AccessToken));
                },
                error => {
                    dispatch(failure(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(token) { return { type: userConstants.LOGIN_SUCCESS, token } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

export default userActions;

