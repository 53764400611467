import axios from "axios";
// import { authHeader } from "../helpers/history";


const login = async (username, password) => {
  let res = await axios({
    method: "post",
    url: "http://microsite-api.emlaksistemi.com/api/account/token",
    data: {
      userName: username,
      Password: password,
    },
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
    return res.data;
    
}

const userService = {
  login
}

export default userService;



// function handleResponse(response) {
//   return response.text().then((text) => {
//     const data = text && JSON.parse(text);
//     if (!response.ok) {
//       if (response.status === 401) {
//         // auto logout if 401 response returned from api
//         logout();
//         location.reload(true);
//       }

//       const error = (data && data.message) || response.statusText;
//       return Promise.reject(error);
//     }

//     return data;
//   });
// }
