import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./auth"
import {generalListsReducer} from "./general";

const persistConfig = {
    key: 'root',
    storage,
    whitelist: '',
}

const rootReducer = combineReducers({
    authReducer,
    generalListsReducer,
    
});


export default persistReducer(persistConfig, rootReducer);