import axios from "./axiosInstance";

const advertList = async (filter) => {
  let res = await axios({
    method: "POST",
    url: `brand/properties`,
    data: filter
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const getAdvertDetails = async (id) => {
  let res = await axios({
    method: "POST",
    url: `property/detail/${id}`,
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const binddropdowns = async (formData) => {
  let res = await axios({
    method: "POST",
    url: `lookup/binddropdowns`,
    data: formData
  })
    .then((res) => res)
    .catch((error) => {
      console.log(error);
    });
  return res?.data;
};

const advertServices = {
  advertList,
  binddropdowns,
  getAdvertDetails
};

export default advertServices;
