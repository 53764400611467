import React, { Component, useEffect, useState } from "react";
import generalServices from "../../services/general.service";
import advertServices from "../../services/advert.service";
import generalActions from "../../redux/actions/general.actions";
import Select from "react-select";
import FilterType1 from "./FilterType1";
import FilterType2 from "./FilterType2";
import FilterType3 from "./FilterType3";
import FilterType4 from "./FilterType4";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const ShopSidebar = ({ totalAdvert }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation(["common"]);
  let anchor = "#";
  let imagealt = "image";
  let publicUrl = process.env.PUBLIC_URL + "/";
  const [adTypeOpt, setAdTypeOpt] = useState([]);
  const [selectedAdType, setSelectedAdType] = useState("");
  const [filterObj, setFilterObj] = useState({});
  const [adVertOptList, setAdVertOptList] = useState([]);
  const [selectedAdTypeVal, setSelectedAdTypeVal] = useState(1);
  const [advertNo, setAdvertNo] = useState('');

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      margin: 5,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    advertServices.binddropdowns(filterObj).then((res) => {
      if (res) {
        const adTypeTempObj = res.AdType.map((i) => ({
          label: i.Text,
          value: i.Value,
        }));
        setAdTypeOpt(adTypeTempObj);

        setAdVertOptList(res);
      }

    });
  }, [selectedAdType, filterObj]);

  useEffect(() => {
    const filterTempObj = {
      AdTypeId: selectedAdType ? parseInt(selectedAdType.value) : "",
    };
  
    setFilterObj(filterTempObj);
    selectedAdType
      ? setSelectedAdTypeVal(selectedAdType.value)
      : setSelectedAdTypeVal(1);
  }, [selectedAdType]);

  const dispatchFilterObj = (filterObj) => {
    dispatch(generalActions.setShopFilters(filterObj));
  }

  return (
    <div className="col-lg-3  mb-100">
      <aside className="sidebar ltn__shop-sidebar">
        <h3 className="mb-10">{t('ilanara')}</h3>
        <label className="mb-30">
          <small>{t('toplam')} {totalAdvert} {t('kayitliilan')} </small>
        </label>
        {/* Advance Information widget */}
        <div className="widget ltn__menu-widget">
          <input
            className="form-control"
            placeholder={t('ilanno')}
            onChange={(e) => setAdvertNo(e.target.value)}
            type='number'
            style={{ height: "45px" }}
          />
          <Select
            className="basic-single mt-4"
            classNamePrefix="select"
            isClearable={true}
            isSearchable={true}
            placeholder={t('ilantipiseciniz')}
            name="province"
            options={adTypeOpt}
            value={selectedAdType}
            styles={customStyles}
            onChange={(e) => {
              setSelectedAdType(e);
            }}
          />
          {selectedAdTypeVal == 1 && (
            <FilterType1 options={adVertOptList} AdBaseId={advertNo} AdTypeId={selectedAdType ? selectedAdType.value : ''} filterVal={dispatchFilterObj} />
          )}
          {selectedAdTypeVal == 2 && (
            <FilterType2 options={adVertOptList} AdBaseId={advertNo} AdTypeId={selectedAdType ? selectedAdType.value : ''} filterVal={dispatchFilterObj} />
          )}
          {selectedAdTypeVal == 4 && (
            <FilterType3 options={adVertOptList} AdBaseId={advertNo} AdTypeId={selectedAdType ? selectedAdType.value : ''} filterVal={dispatchFilterObj} />
          )}
          {selectedAdTypeVal == 6 && (
            <FilterType4 options={adVertOptList} AdBaseId={advertNo} AdTypeId={selectedAdType ? selectedAdType.value : ''} filterVal={dispatchFilterObj} />
          )}
        </div>
      </aside>
    </div>
  );
};

export default ShopSidebar;
