import React, { useEffect, useState } from "react";

import Select from "react-select";
import FilterTypeConst from "./FilterTypeConst";
import generalServices from "../../services/general.service";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const FilterType3 = ({ options, AdBaseId, AdTypeId, filterVal }) => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const fetchCountryList = useSelector(
    (state) => state.generalListsReducer.countryReducer.countryList
  );

  //Process Type Filter Values
  const [processType, setProcessType] = useState([]);
  const [selProcessType, setSelProcessType] = useState("");

  //Commercial Property Type Filter Values
  const [commercialPropertyType, setCommercialPropertyType] = useState([]);
  const [selCommercialPropertyType, setSelCommercialPropertyType] =
    useState("");

  //Commercial Property Status Filter Values
  const [commercialFullnessStatus, setCommercialFullnessStatus] = useState([]);
  const [selCommercialFullnessStatus, setSelCommercialFullnessStatus] =
    useState("");

  //Commercial Register Status Filter Values
  const [commercialRegisterStatus, setCommercialRegisterStatus] = useState([]);
  const [selCommercialRegisterStatus, setSelCommercialRegisterStatus] =
    useState("");

  //Commercial Heating Type Filter Values
  const [commercialHeatingType, setCommercialHeatingType] = useState([]);
  const [selCommercialHeatingType, setSelCommercialHeatingType] = useState("");

  //Commercial Heating Fuel Type Filter Values
  const [commercialFuelType, setCommercialFuelType] = useState([]);
  const [selCommercialFuelType, setSelCommercialFuelType] = useState("");

  //Commercial Heating Type Filter Values
  const [commercialBuildAge, setCommercialBuildAge] = useState([]);
  const [selCommercialBuildAge, setSelCommercialBuildAge] = useState("");

  //Commercial Bathroom Count Filter Values
  const [commercialBathroomCount, setCommercialBathroomCount] = useState([]);
  const [selCommercialBathroomCount, setSelCommercialBathroomCount] =
    useState("");

  //Commercial WC Count Filter Values
  const [commercialWcCount, setCommercialWcCount] = useState([]);
  const [selCommercialWcCount, setSelCommercialWcCount] = useState("");

  //Commercial Balcony Count Filter Values
  const [commercialBalconyCount, setCommercialBalconyCount] = useState([]);
  const [selCommercialBalconyCount, setSelCommercialBalconyCount] =
    useState("");

  //Commercial Terrrace Count Filter Values
  const [commercialTerraceCount, setCommercialTerraceCount] = useState([]);
  const [selCommercialTerraceCount, setSelCommercialTerraceCount] =
    useState("");

  const [provinceListOpt, setProvinceListOpt] = useState([]);
  const [countyListOpt, setCountyListOpt] = useState([]);
  const [districtListOpt, setDistrictListOpt] = useState([]);
  const [selProvince, setSelProvince] = useState("");
  const [selDistrict, setSelDistrict] = useState("");
  const [selCounty, setSelCounty] = useState("");

  useEffect(() => {
    setSelProvince("");
    setSelDistrict("");
    setSelCounty("");
      generalServices
        .provinceListWithCountry()
        .then((res) => {
          const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
          tempArr?.length > 0
            ? setProvinceListOpt(tempArr)
            : setProvinceListOpt([]);
        });
  }, []);

  useEffect(() => {
    setSelDistrict("");
    setSelCounty("");
    selProvince && generalServices.countytList(parseInt(selProvince?.value)).then((res) => {
      const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
      tempArr?.length > 0 ? setCountyListOpt(tempArr) : setCountyListOpt([]);
    });
  }, [selProvince]);

  useEffect(() => {
    setSelDistrict("");
    selCounty &&
      generalServices.districtstList(parseInt(selCounty?.value)).then((res) => {
        const tempArr = res?.map((i) => ({ label: i.Name, value: i.Id }));
        tempArr?.length > 0
          ? setDistrictListOpt(tempArr)
          : setDistrictListOpt([]);
      });
  }, [selCounty]);

  useEffect(() => {
    if (Object.keys(options).length > 0) {
      const adProcessTypeObj = options.ProcessType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setProcessType(adProcessTypeObj);

      const adCommercialPropertyTypeObj = options.CommercialPropertyType.map(
        (i) => ({
          label: i.Text,
          value: i.Value,
        })
      );
      setCommercialPropertyType(adCommercialPropertyTypeObj);

      const adCommercialFullnessStatusObj =
        options.CommercialFullnessStatus.map((i) => ({
          label: i.Text,
          value: i.Value,
        }));
      setCommercialFullnessStatus(adCommercialFullnessStatusObj);

      const adCommercialRegisterStatusObj =
        options.CommercialRegisterStatus.map((i) => ({
          label: i.Text,
          value: i.Value,
        }));
      setCommercialRegisterStatus(adCommercialRegisterStatusObj);

      const adCommercialHeatingTypeObj = options.CommercialHeatingType.map(
        (i) => ({
          label: i.Text,
          value: i.Value,
        })
      );
      setCommercialHeatingType(adCommercialHeatingTypeObj);

      const adCommercialFuelTypeObj = options.CommercialFuelType.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setCommercialFuelType(adCommercialFuelTypeObj);

      const adCommercialBuildAgeObj = options.CommercialBuildAge.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setCommercialBuildAge(adCommercialBuildAgeObj);

      const adCommercialBathroomCountObj = options.CommercialBathroomCount.map(
        (i) => ({
          label: i.Text,
          value: i.Value,
        })
      );
      setCommercialBathroomCount(adCommercialBathroomCountObj);

      const adCommercialWcCountObj = options.CommercialWcCount.map((i) => ({
        label: i.Text,
        value: i.Value,
      }));
      setCommercialWcCount(adCommercialWcCountObj);

      const adCommercialBalconyCountObj = options.CommercialBalconyCount.map(
        (i) => ({
          label: i.Text,
          value: i.Value,
        })
      );
      setCommercialBalconyCount(adCommercialBalconyCountObj);

      const adCommercialTerraceCountObj = options.CommercialTerraceCount.map(
        (i) => ({
          label: i.Text,
          value: i.Value,
        })
      );
      setCommercialTerraceCount(adCommercialTerraceCountObj);
    }
  }, [options]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "50px",
      height: 50,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 50,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  const setFilterValues = () => {
    const filterObj = {
      AdBaseId:AdBaseId,
      AdTypeId: AdTypeId,
      ProcessTypeId: selProcessType ? selProcessType.value : "",
      CityId: selProvince ? selProvince.value : '',
      CountyId: selCounty ? selCounty.value : '',
      DistrictId: selDistrict ? selDistrict.value : '',
      CommercialPropertyTypeId: selCommercialPropertyType ? selCommercialPropertyType.value : '',
      CommercialFullnessStatusId: selCommercialFullnessStatus ? selCommercialFullnessStatus.value : '',
      CommercialRegisterStatusId: selCommercialRegisterStatus ? selCommercialRegisterStatus.value : '',
      CommercialHeatingTypeId: selCommercialHeatingType ? selCommercialHeatingType.value : '',
      CommercialFuelTypeId: selCommercialFuelType ? selCommercialFuelType.value : '',
      CommercialBuildAgeId: selCommercialBuildAge ? selCommercialBuildAge.value : '',
      CommercialBathroomCountId: selCommercialBathroomCount ? selCommercialBathroomCount.value : '',
      CommercialWcCountId: selCommercialWcCount ? selCommercialWcCount.value : '',
      CommercialBalconyCountId: selCommercialBalconyCount ? selCommercialBalconyCount.value : '',
      CommercialTerraceCountId: selCommercialTerraceCount ? selCommercialTerraceCount.value : ''
    };
    filterVal(filterObj);
  };

  return (
    <>
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('islemtipiseciniz')}
        name="type"
        options={processType}
        value={selProcessType}
        styles={customStyles}
        onChange={(e) => {
          setSelProcessType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('il')}
        name="province"
        options={provinceListOpt && provinceListOpt}
        value={selProvince}
        onChange={(e) => {
          setSelProvince(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('ilçe')}
        name="county"
        options={countyListOpt && countyListOpt}
        value={selCounty}
        onChange={(e) => {
          setSelCounty(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('semt/mahalle')}
        name="district"
        options={districtListOpt && districtListOpt}
        value={selDistrict}
        onChange={(e) => {
          setSelDistrict(e);
        }}
        styles={customStyles}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('ticaritipseciniz')}
        name="type"
        options={commercialPropertyType}
        value={selCommercialPropertyType}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialPropertyType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('ticaritipkullanimdurumuseciniz')}
        name="type"
        options={commercialFullnessStatus}
        value={selCommercialFullnessStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialFullnessStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('tapudurumunuseciniz')}
        name="type"
        options={commercialRegisterStatus}
        value={selCommercialRegisterStatus}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialRegisterStatus(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('isinmatipiniseciniz')}
        name="type"
        options={commercialHeatingType}
        value={selCommercialHeatingType}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialHeatingType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('yakittipiniseciniz')}
        name="type"
        options={commercialFuelType}
        value={selCommercialFuelType}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialFuelType(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('binayasiniseciniz')}
        name="type"
        options={commercialBuildAge}
        value={selCommercialBuildAge}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialBuildAge(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('banyosayisiniseciniz')}
        name="type"
        options={commercialBathroomCount}
        value={selCommercialBathroomCount}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialBathroomCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('tuvaletsayisiniseciniz')}
        name="type"
        options={commercialWcCount}
        value={selCommercialWcCount}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialWcCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('balkonsayisiniseciniz')}
        name="type"
        options={commercialBalconyCount}
        value={selCommercialBalconyCount}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialBalconyCount(e);
        }}
      />
      <Select
        className="basic-single mt-4"
        classNamePrefix="select"
        isClearable={true}
        isSearchable={true}
        placeholder={t('terassayisiniseciniz')}
        name="type"
        options={commercialTerraceCount}
        value={selCommercialTerraceCount}
        styles={customStyles}
        onChange={(e) => {
          setSelCommercialTerraceCount(e);
        }}
      />
       <div className="btn-wrapper mt-4 row">
        <button
          className="btn theme-btn-1 btn-effect-1 text-uppercase"
          type="button"
          onClick={() => setFilterValues()}
        >
           {t('gonder')}
        </button>
      </div>

      {/* <FilterTypeConst options={options} /> */}
    </>
  );
};

export default FilterType3;
