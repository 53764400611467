import React, { Component, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Social from "../section-components/Social";
import Select, { StylesConfig } from "react-select";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import generalActions from "../../redux/actions/general.actions";

const NavbarV2 = (props) => {
  const dispatch = useDispatch();
  const fetchCompSettings = useSelector(
    (state) => state.generalListsReducer.companySettingsReducer.compSettings
  );
  let publicUrl = process.env.PUBLIC_URL + "/";
  let CustomClass = props.CustomClass ? props.CustomClass : "";
  const { t } = useTranslation(["common"]);
  const langOptions = [
    {
      value: "tr",
      label: (
        <div>
          <img
            style={{ marginRight: "3px" }}
            src={publicUrl + "assets/img/flags/tr.png"}
            height="11px"
            width="16px"
          />
          Türkçe{" "}
        </div>
      ),
    },
    {
      value: "en",
      label: (
        <div>
          <img
            style={{ marginRight: "3px" }}
            src={publicUrl + "assets/img/flags/en.png"}
            height="11px"
            width="16px"
          />
          İngilizce{" "}
        </div>
      ),
    },
  ];
  useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("tr");
      dispatch(generalActions.setLang("tr"));
    }
  }, []);

  const handleLanguageChange = (e) => {
    i18next.changeLanguage(e.value);
    dispatch(generalActions.setLang(e.value));
  };

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      margin: 0,
      width: 130,
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "black" : "black",
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "20px",
      height: 25,
      width: 130,

      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
      padding: 0,
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 23,
      
    }),
    input: (provided, state) => ({
      ...provided,
      height: 20,
      
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 23,
      paddingTop: 0,
      paddingBottom: 0
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };
  return (
    <div>
      <header
        className={
          "ltn__header-area ltn__header-5 ltn__header-logo-and-mobile-menu-in-mobile ltn__header-logo-and-mobile-menu ltn__header-transparent--- gradient-color-4--- " +
          CustomClass
        }
      >
        {/* ltn__header-top-area start */}
        <div className="ltn__header-top-area top-area-color-white items-center">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-7">
                <div className="ltn__top-bar-menu">
                  <ul className="outbound-header">
                    <span
                      className="d-none"
                      style={{ color: "#fff", margin: "0 8px" }}
                      aria-hidden="true"
                    >
                      •
                    </span>
                    <li
                      className="olb-link m-0"
                      style={{ fontWeight: "lighter" }}
                    >
                      <a
                        href="https://bhgrecollection.com"
                        target="_blank"
                        rel="noopener"
                      >
                        Distinctive Collection
                      </a>
                    </li>

                    <span
                      style={{ color: "#fff", margin: "0 8px" }}
                      aria-hidden="true"
                    >
                      •
                    </span>
                    <li
                      className="olb-link m-0"
                      style={{ fontWeight: "lighter" }}
                    >
                      <a
                        href="https://www.bhgre.com/"
                        target="_blank"
                        rel="noopener"
                      >
                        Commercial
                      </a>
                    </li>

                    <span
                      style={{ color: "#fff", margin: "0 8px" }}
                      aria-hidden="true"
                    >
                      •
                    </span>
                    <li
                      className="olb-link m-0"
                      style={{ fontWeight: "lighter" }}
                    >
                      <a
                        href="http://bhgreglobal.com/"
                        target="_blank"
                        rel="noopener"
                      >
                        Global Search
                      </a>
                    </li>
                    <span
                      style={{ color: "#fff", margin: "0 8px" }}
                      aria-hidden="true"
                    >
                      •
                    </span>
                    <li
                      className="olb-link m-0"
                      style={{ fontWeight: "lighter" }}
                    >
                      <a
                        href="http://bhgremedia.com/"
                        target="_blank"
                        rel="noopener"
                      >
                        Media Center
                      </a>
                    </li>

                    <span
                      style={{ color: "#fff", margin: "0 8px" }}
                      aria-hidden="true"
                    >
                      •
                    </span>
                    <li className="ol m-0" style={{ fontWeight: "lighter" }}>
                      <Link to="/bloglar">BHGRE Life Blog</Link>
                    </li>
                  </ul>

                  {/* <ul>
                    <li>
                      <span className="items-center">
                        DISTINCTIVE COLLECTION | COMMERCIAL
                      </span>
                    </li>
                  </ul> */}
                </div>
              </div>
              <div className="col-md-5 col-lg-5 navcol pe-1 ">
                <div className="me-4">
                  <Social settings={fetchCompSettings} />
                </div>
                <Select
                  className=""
                  classNamePrefix="select"
                  isClearable={false}
                  isSearchable={false}
                  name="OfficeCityId"
                  defaultValue={{
                    value: "tr",
                    label: (
                      <div>
                        <img
                          style={{ marginRight: "3px" }}
                          src={publicUrl + "assets/img/flags/tr.png"}
                          height="11px"
                          width="16px"
                        />
                        Türkçe{" "}
                      </div>
                    ),
                  }}
                  value={langOptions.find(
                    (i) => i.value == localStorage.getItem("i18nextLng")
                  )}
                  options={langOptions}
                  onChange={(e) => handleLanguageChange(e)}
                  styles={customStyles}
                />
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-top-area end */}
        {/* ltn__header-middle-area start */}
        <div
          className="ltn__header-middle-area ltn__header-sticky p-0 select__indicator-separator "
          style={{ color: "#fff" }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col">
                <div className="site-logo-wrap" style={{ marginLeft: "2%" }}>
                  <div className="site-logo go-top">
                    <Link to="/">
                      <img
                        src={
                          publicUrl +
                          "assets/img/BHGRE_Turkiye_Logolar_beyaz-01.png"
                        }
                        alt="Logo"
                      />
                    </Link>
                  </div>
                  <div className="get-support clearfix d-none">
                    <div className="get-support-icon">
                      <i className="icon-call" />
                    </div>
                    <div className="get-support-info">
                      <h6>Get Support</h6>
                      <h4>
                        <a href="tel:+123456789">123-456-789-10</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col header-menu-column">
                <div className="header-menu d-none d-xl-block go-top">
                  <nav>
                    <div className="ltn__main-menu ">
                      <ul>
                        <li>
                          <Link to="/kurumsal" className="dropdown-toggle">
                            <span className="active-currency">
                              {t("kurumsal-nav")}
                            </span>
                          </Link>

                          <ul>
                            <li>
                              <Link to="/vizyon-misyon">
                                {t("vizyonmisyon-nav")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/danisman-bul">
                                {t("genelmerkezekibimiz-nav")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/hakkimizda">
                                BHGRE {t("hakkinda-nav")}
                              </Link>
                            </li>
                            <li>
                              <Link to="/haberler">{t("haberler-nav")}</Link>
                            </li>
                          </ul>
                        </li>
                        <li>
                          <Link className="text-white" to="/ofis-bul">
                            {t("ofislerimiz-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/danisman-bul">
                            {t("danismanlarimiz-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/ailemize-katil">
                            {t("ofisac-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/ailemize-katil">
                            {t("danismanol-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/ev-satin-al">
                            {t("gayrimenkullerimiz-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/ev-sat">
                            {t("bizimlesatkirala-nav")}
                          </Link>
                        </li>
                        <li>
                          <Link className="text-white" to="/iletisim">
                            {t("iletisim-nav")}
                          </Link>
                        </li>
                        <li
                          className=""
                          // style={{ borderRadius: "10px" }}
                        >
                          <a
                            href="https://betternet.re-os.com"
                            style={{ color: "#fff" }}
                          >
                            {t("giris-nav")}
                          </a>
                        </li>
                        <li></li>
                      </ul>
                    </div>
                  </nav>
                </div>
              </div>
              <div className="col--- ltn__header-options ltn__header-options-2 ">
                {/* Mobile Menu Button */}
                <div className="mobile-menu-toggle d-xl-none">
                  <a
                    href="#ltn__utilize-mobile-menu"
                    className="ltn__utilize-toggle"
                  >
                    <svg viewBox="0 0 800 600">
                      <path
                        d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200"
                        id="top"
                      />
                      <path d="M300,320 L540,320" id="middle" />
                      <path
                        d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
                        id="bottom"
                        transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ltn__header-middle-area end */}
      </header>
      <div
        id="ltn__utilize-mobile-menu"
        className="ltn__utilize ltn__utilize-mobile-menu"
      >
        <div className="ltn__utilize-menu-inner ltn__scrollbar">
          <div className="ltn__utilize-menu-head">
            <div className="site-logo">
              <Link to="/">
                <img
                  src={publicUrl + "assets/img/BHGRE_Turkiye_Yatay_logo.png"}
                  alt="Logo"
                />
              </Link>
            </div>
            <button className="ltn__utilize-close">×</button>
          </div>
          <div className="ltn__utilize-menu">
            <ul>
              <li>
                <Link to="/kurumsal" className="dropdown-toggle">
                  <span className="active-currency">{t("kurumsal-nav")}</span>
                </Link>

                <ul>
                  <li>
                    <Link to="vizyon-misyon">{t("vizyonmisyon-nav")}</Link>
                  </li>
                  <li>
                    <Link to="/danisman-bul">
                      {t("genelmerkezekibimiz-nav")}
                    </Link>
                  </li>
                  <li>
                    <Link to="hakkimizda">BHGRE {t("hakkinda-nav")}</Link>
                  </li>
                  <li>
                    <Link to="/haberler">{t("haberler-nav")}</Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/ofis-bul">{t("ofislerimiz-nav")}</Link>
              </li>
              <li>
                <Link to="/danisman-bul">{t("danismanlarimiz-nav")}</Link>
              </li>
              <li>
                <Link to="/ev-satin-al">{t("gayrimenkullerimiz-nav")}</Link>
              </li>
              <li>
                <Link to="/ev-sat">{t("bizimlesatkirala-nav")}</Link>
              </li>
              <li>
                <Link to="/ailemize-katil">{t("ofisac-nav")}</Link>
              </li>
              <li>
                <Link to="/ailemize-katil">{t("danismanol-nav")}</Link>
              </li>
              <li>
                <Link className="text-white" to="/iletisim">
                  {t("iletisim-nav")}
                </Link>
              </li>
              <li style={{ borderRadius: "10px" }}>
                <a className="text-success" href="https://betternet.re-os.com">
                  {t("giris-nav")}
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarV2;
