import React, { useEffect } from "react";
import Navbar from "./global-components/Navbar-v2";
import PageHeader from "./global-components/Page_header";
import AboutV4 from "./section-components/AboutV4";
import Features from "./section-components/Features";
import Testimonial from "./section-components/Testimonial";
import BlogSlider from "./blog-components/blog-slider";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import ContactForm from "./section-components/ContactForm";
import ContactForm2 from "./section-components/ContactForm2";
import NewsSlider from "./news-components/news-slider";
import TeamSlider from "./section-components/TeamSlider";
import ContactForm3 from "./section-components/ContactForm3";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);
  return (
    <div>
      <Navbar />
      {/* <PageHeader headertitle="Ailemize Katıl" /> */}
      <AboutV4 />
      {/* <ContactForm customClass="ltn__feature-area section-bg-1 pt-80 pb-60 mb-80" /> */}
      {/* <TeamSlider /> */}
      {/* <Features customClass="ltn__feature-area section-bg-1 pt-120 pb-90 mb-120---" /> */}
      <Testimonial />
      {/* <BlogSlider customClass="section-subtitle-2 mt-2" />
      <NewsSlider customClass="section-subtitle-2 mt-4" /> */}
      {/* <ContactForm2 customClass="ltn__feature-area section-bg-1 pt-80 pb-80 mb-80" />
      <ContactForm3 customClass="ltn__feature-area section-bg-1 pt-80 pb-80 mb-80" /> */}
      <CallToAction />
      <Footer />
    </div>
  );
};

export default About;
