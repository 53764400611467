import React, { useEffect } from 'react';
import Navbar from './global-components/Navbar-v2';
import PageHeader from './global-components/Page_header';
import Location from './section-components/Location';
import CallToAction from './section-components/CallToAction';
import Footer from './global-components/Footer';
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const LocationPage = () => {
    
const dispatch = useDispatch();

  useEffect(() => {
    dispatch(generalActions.setShopFilters(''))
    dispatch(generalActions.setTeamFilters(''))
  }, [])
    return <div>
        <Navbar />
        {/* <PageHeader headertitle="Ofisler" customclass="mb-0" /> */}
        <Location  />
        <CallToAction />
        <Footer />
    </div>
}

export default LocationPage

