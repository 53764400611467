import React, { useEffect } from "react";
import Navbar from "./global-components/Navbar-v2";
import NewsDetails from "./news-components/news-details";
import CallToAction from "./section-components/CallToAction";
import Footer from "./global-components/Footer";
import Page_header from "./global-components/Page_header";
import { useDispatch } from "react-redux";
import generalActions from "../redux/actions/general.actions";

const New_Detail = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(generalActions.setShopFilters(""));
    dispatch(generalActions.setTeamFilters(""));
    dispatch(generalActions.setOfficeFilters(""));
  }, []);
  return (
    <div>
      <Navbar />
      {/* <Page_header headertitle="Haber Detay" /> */}
      <NewsDetails />
      <CallToAction />
      <Footer />
    </div>
  );
};

export default New_Detail;
