import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import Sidebar from "./ShopSidebar";
import Select from "react-select";
import generalActions from "../../redux/actions/general.actions";
import generalServices from "../../services/general.service";
import advertServices from "../../services/advert.service";
import Pagination from "../global-components/Pagination";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";
const containerStyle = {
  width: "100%",
  height: "100%",
};
const ShopRightSidebar = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  const fetchFilterValList = useSelector(
    (state) => state.generalListsReducer.setShopFiltersReducer.filters
  );
  const fetchPageContents = useSelector(
    (state) => state.generalListsReducer.pageContentsReducer.pageContents
  );

  const [totalAdvert, setTotalAdvert] = useState(0);
  const [advertResultList, setAdvertResultList] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  const [sortingVal, setSortingVal] = useState("");
  const [selectedSortingVal, setSelectedSortingVal] = useState(4);
  const [content, setContent] = useState("");
  const [pageView, setPageView] = useState(true);
  const [center, setCenter] = useState({});

  const [currentpage, setCurrentPage] = useState(1);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [pageNumVal, setPageNumVal] = useState("");
  const [pageNumLimit, setPageNumLimit] = useState(9);
  const [maxPageNumLimit, setMaxPageNumLimit] = useState(5);
  const [minPageNumLimit, setMinPageNumLimit] = useState(0);
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const increasePageNum = () => {
    if (currentpage < totalPageCount) {
      setCurrentPage(currentpage + 1);
    }

    if (currentpage + 1 > maxPageNumLimit) {
      setMaxPageNumLimit(maxPageNumLimit + pageNumLimit);
      setMinPageNumLimit(minPageNumLimit + pageNumLimit);
    }
  };

  const decreasePageNum = () => {
    if (currentpage > 1) {
      setCurrentPage(currentpage - 1);

      if ((currentpage - 1) % pageNumLimit == 0) {
        setMaxPageNumLimit(maxPageNumLimit - pageNumLimit);
        setMinPageNumLimit(minPageNumLimit - pageNumLimit);
      }
    }
  };

  useEffect(() => {
    if (fetchPageContents && fetchPageContents.length > 0) {
      const tempContent = fetchPageContents.find(
        (i) => i.WidgetKey === "444444444444"
      );
      tempContent && setContent(tempContent.WidgetDescription[0].Title);
    }
    const tempObj = {
      lat: 39.925533,
      lng: 32.866287,
    };
    setCenter(tempObj);
  }, [fetchPageContents]);

  const sortTypeOpt = [
    { label: t("artanfiyatagoresiralama"), value: 1 },
    { label: t("azalanfiyatagoresiralama"), value: 2 },
    { label: t("artantarihegoresiralama"), value: 3 },
    { label: t("azalantarihegoresiralama"), value: 4 },
    { label: t("m²artansıralama"), value: 5 },
    { label: t("m²azalansıralama"), value: 6 },
  ];
  const perPageOpt = [
    { label: `${t('hersayfada')}: 10`, value: 10 },
    { label: `${t('hersayfada')}: 20`, value: 20 },
    { label: `${t('hersayfada')}: 30`, value: 30 },
    { label: `${t('hersayfada')}: 50`, value: 50 },
  ];

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      margin: 5,
    }),
    option: (provided, state) => ({
      ...provided,
    }),
    control: (provided, state) => ({
      ...provided,
      background: "#fff",
      borderColor: "#9e9e9e",
      minHeight: "30px",
      height: 45,
      boxShadow: state.isFocused ? null : null,
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    input: (provided, state) => ({
      ...provided,
      height: 45,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: 45,
      paddingTop: 0,
    }),
    menu: (base) => ({ ...base, zIndex: 9999 }),
  };

  useEffect(() => {
    const filterVal = {
      PageIndex: currentpage,
      PageSize: pageNumLimit,
      Sorting: selectedSortingVal,
      ...fetchFilterValList,
    };
    advertServices.advertList(filterVal).then((res) => {
      setTotalAdvert(res?.Result.TotalItemCount);
      setAdvertResultList(res?.Result.Items);
      setTotalPageCount(res.Result.TotalPageCount);
      console.log(res)
    });
  }, [currentpage, pageNumLimit, fetchFilterValList, selectedSortingVal]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyAfacQ1tAOSlcKRE1bgg-WRobYGyvHrprI",
    libraries: ["places"],
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map);
      }
    },
    [center]
  );

  const onUnmount = React.useCallback(
    function callback(map) {
      if (Object.keys(center).length > 0) {
        setMap(null);
      }
      console.log("CENTER", center);
    },
    [center]
  );

  return (
    <div className="mt-20">
      <div className="ltn__product-area ltn__product-gutter">
        <div className="container-fluid">
          <div className="row">
            <Sidebar totalAdvert={totalAdvert} />
            <div className="col-lg-9  mb-100">
              <div className="ltn__shop-options">
                <ul className="justify-content-start">
                  <li>
                    <div className="ltn__grid-list-tab-menu ">
                      <div className="nav">
                        <a
                          className="active show"
                          data-bs-toggle="tab"
                          href="#liton_product_grid"
                          onClick={() => setPageView(true)}
                        >
                          <i className="fas fa-th-large" />
                        </a>
                        <a
                          data-bs-toggle="tab"
                          href="#liton_product_list"
                          onClick={() => setPageView(true)}
                        >
                          <i className="fas fa-list" />
                        </a>
                        <a
                          data-bs-toggle="tab"
                          href="#map_list"
                          onClick={() => setPageView(false)}
                        >
                          <i className="fas fa-map-marker-alt" />
                        </a>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div
                      className="input-item"
                      style={{ minWidth: "200px", fontSize: ".9rem" }}
                    >
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isClearable={true}
                        isSearchable={true}
                        placeholder={t("siralamatipiseciniz")}
                        name="province"
                        options={sortTypeOpt}
                        value={sortingVal}
                        styles={customStyles}
                        onChange={(e) => {
                          setSortingVal(e);
                          e
                            ? parseInt(setSelectedSortingVal(e.value))
                            : setSelectedSortingVal(4);
                        }}
                      />
                    </div>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div
                  className="tab-pane fade active show"
                  id="liton_product_grid"
                >
                  <div className="ltn__product-tab-content-inner ltn__product-grid-view">
                    <div className="row">
                      {advertResultList?.map((i, key) => {
                        return (
                          <div key={key} className="col-xl-4 col-sm-4 col-12">
                            <div className="ltn__product-item ltn__product-item-4 text-center---">
                              <div
                                className="product-img go-top"
                                style={{
                                  overflow: "hidden",
                                  height: 0,
                                  paddingTop: "56.25%",
                                  position: "relative",
                                }}
                              >
                                <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                  <img
                                    className=""
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={
                                      i.OriginalImage
                                        ? i.OriginalImage
                                        : publicUrl +
                                          "assets/img/product-3/1.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badge bg-green">
                                      {i.EstateStatusType}
                                    </li>
                                  </ul>
                                </div>
                                <div className="product-img-location-gallery">
                                  <div className="product-img-location">
                                    <ul>
                                      <li>
                                        <i className="flaticon-pin" />{" "}
                                        {i.County + "," + i.City}
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="product-img-gallery go-top">
                                    <ul>
                                      <li>
                                        <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                          <i className="fas fa-camera" />
                                        </Link>
                                      </li>
                                      <li>
                                        <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                          <i className="fas fa-film" />
                                        </Link>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="product-info">
                                <div className="product-price">
                                  <span>
                                    {i.Currency === "TL" ? "₺" : i.Currency}{" "}
                                    {i.FormattedPrice}
                                  </span>
                                </div>
                                <h2
                                  className="product-title go-top"
                                  style={{
                                    whiteSpace: "nowrap",
                                    width: "calc(100% - 20px + 5px)",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                    {i.Title ? i.Title : "-"}
                                  </Link>
                                </h2>
                                {i.AdType == 2 ? (
                                  <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                                    <li>
                                      <span>
                                        3 <i className="flaticon-bed" />
                                      </span>
                                      {t('yatakodasi')}
                                    </li>
                                    <li>
                                      <span>
                                        {i.HouseBathroomCount
                                          ? i.HouseBathroomCount
                                          : "-"}{" "}
                                        <i className="flaticon-clean" />
                                      </span>
                                      {t('banyo')}
                                    </li>
                                    <li>
                                      <span>
                                        {i.UsageAreaGross
                                          ? i.UsageAreaGross
                                          : "-"}{" "}
                                        <i className="flaticon-square-shape-design-interface-tool-symbol" />
                                      </span>
                                      m² ( {t('brut')})
                                    </li>
                                  </ul>
                                ) : (
                                  <ul className="ltn__list-item-2 ltn__list-item-2-before mb-0">
                                    {i.UsageAreaGross && (
                                      <li className="mt-0">
                                        <span>
                                          {i.UsageAreaGross} m² ( {t('brut')})
                                        </span>
                                      </li>
                                    )}
                                  </ul>
                                )}
                              </div>
                              <div className="product-info-bottom">
                                <div className="real-estate-agent">
                                  <div className="agent-img go-top">
                                    <Link
                                      to={`/danisman-detay/${i.ConsultantId}`}
                                    >
                                      <img
                                        src={
                                          i.ConsultantPhoto
                                            ? i.ConsultantPhoto
                                            : publicUrl +
                                              "assets/img/blog/author.jpg"
                                        }
                                        alt="#"
                                      />
                                    </Link>
                                  </div>
                                  <div className="agent-brief go-top">
                                    <h6>
                                      <Link
                                        to={`/danisman-detay/${i.ConsultantId}`}
                                      >
                                        {i.ConsultantFullName}
                                      </Link>
                                    </h6>
                                    <small>{i.ConsultantTitle}</small>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="liton_product_list">
                  <div className="ltn__product-tab-content-inner ltn__product-list-view">
                    <div className="row">
                      {advertResultList?.map((i, key) => {
                        return (
                          <div key={key} className="col-lg-12">
                            <div className="ltn__product-item ltn__product-item-4 ltn__product-item-5">
                              <div className="product-img go-top">
                                <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                  <img
                                    // style={{ height: "207px" }}
                                    src={
                                      i.OriginalImage
                                        ? i.OriginalImage
                                        : publicUrl +
                                          "assets/img/product-3/1.jpg"
                                    }
                                    alt="#"
                                  />
                                </Link>
                              </div>
                              <div className="product-info">
                                <div className="product-badge">
                                  <ul>
                                    <li className="sale-badg">
                                      {i.EstateStatusType
                                        ? i.EstateStatusType
                                        : "-"}
                                    </li>
                                  </ul>
                                </div>
                                <h2 className="product-title go-top">
                                  <Link to={`/ilan-detay/${i.AdBaseId}`}>
                                    {i.Title ? i.Title : "-"}
                                  </Link>
                                </h2>
                                <div className="product-img-location go-top">
                                  <ul>
                                    <li>
                                      <i className="flaticon-pin" />{" "}
                                      {i.District ? i.District : ""}{" "}
                                      {i.County ? i.County : ""},{" "}
                                      {i.City ? i.City : ""}
                                    </li>
                                  </ul>
                                </div>
                                {i.AdType === "Konut" && (
                                  <ul className="ltn__list-item-2--- ltn__list-item-2-before--- ltn__plot-brief">
                                    <li>
                                    {t('odasayisi')}
                                      <span>
                                        {" "}
                                        {i.RoomCount ? i.RoomCount : "-"}{" "}
                                      </span>
                                    </li>
                                    <li>
                                    {t('brut')}
                                      <span>
                                        {" "}
                                        {i.UsageAreaGross
                                          ? i.UsageAreaGross
                                          : "-"}{" "}
                                        m²{" "}
                                      </span>
                                    </li>
                                  </ul>
                                )}
                              </div>
                              <div className="product-info-bottom">
                                <div className="product-price">
                                  <span>
                                    {i.FormattedPrice ? i.FormattedPrice : "-"}₺
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="map_list">
                  {isLoaded && (
                    <div className="ltn__google-map-locations-area">
                      <div className="property-details-google-map mb-20">
                        {advertResultList.length > 0 && (
                          <GoogleMap
                            mapContainerStyle={containerStyle}
                            center={center}
                            zoom={5}
                            onLoad={onLoad}
                            onUnmount={onUnmount}
                          >
                            {advertResultList &&
                              advertResultList?.map((i, key) => (
                                <Marker
                                  position={{
                                    lat: Number(i.Latitude),
                                    lng: Number(i.Longitude),
                                  }}
                                  key={key}
                                  title={i.Title}
                                />
                              ))}

                            <></>
                          </GoogleMap>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {pageView && totalPageCount > 1 && (
                <Pagination
                  paginate={paginate}
                  increasePageNum={increasePageNum}
                  decreasePageNum={decreasePageNum}
                  activepage={currentpage}
                  pageCount={totalPageCount}
                  maxPageNum={maxPageNumLimit}
                  minPageNum={minPageNumLimit}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="property-detail-info-list  clearfix mb-60">
              {content && parse(content)}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopRightSidebar;
