import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import parse from "html-react-parser";

import homePageServices from "../../services/homepage.service";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  let publicUrl = process.env.PUBLIC_URL + "/";
  const { t } = useTranslation(["common"]);
  let imagealt = "image";

  const settings = {
    dots: false,
    arrows: true,
    infinite: false,
    centerPadding: "80px",
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: (
      <a className="slick-prev">
        <i className="fa fa-angle-left" alt="Arrow Icon"></i>
      </a>
    ),
    nextArrow: (
      <a className="slick-next">
        <i className="fa fa-angle-right" alt="Arrow Icon"></i>
      </a>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          arrows: false,
          dots: true,
          centerMode: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const [commentsList, setCommentsList] = useState([]);

  useEffect(() => {
    homePageServices.getCustomerCommentsList().then((res) => {
      setCommentsList(res?.Result.Items);
    });
  }, []);

  return (
    <>
      {commentsList?.length > 0 && (
        <div
          className="ltn__testimonial-area section-bg-1--- bg-image-top pt-115 pb-70"
          data-bs-bg={publicUrl + "assets/img/bg/20.jpg"}
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-area ltn__section-title-2--- text-center">
                  <h6 className="section-subtitle section-subtitle-2">
                    {t("gorusleriniz")}
                  </h6>
                  <h1 className="section-title">{t("musterigeribildirimleri")} </h1>
                </div>
              </div>
            </div>
            <div className="row slick-arrow-1">
              <Slider>
                {commentsList?.length > 0 &&
                  commentsList?.map((i, key) => {
                    return (
                      <div className="col-lg-4">
                        <div className="ltn__testimonial-item ltn__testimonial-item-7">
                          <div className="ltn__testimoni-info">
                            <p>
                              <i className="flaticon-left-quote-1" />
                              {i.Comment && i.Comment}
                            </p>
                            <div className="ltn__testimoni-info-inner">
                              <div className="ltn__testimoni-name-designation">
                                <h5>{i.Firstname + " " + i.Lastname}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </Slider>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonial;
